// ############################ Require packages here ############################
import React from 'react';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################


export default function PrivacyPolicy() {

    return (
        <div className='seq-cookie-policy'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Privacy Policy</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='cookie-policy-header'>
                <h3>Privacy Policy</h3>
            </div>
            {/* section 2 DESCRIPTION */}
            <div className='cookie-description'>
                <div className='container'>
                    <div className='mt-4 mb-4'>
                        <h4>Introduction</h4>
                        <p>Sequretek IT Solutions Private Limited (Sequretek) and its wholly owned subsidiary are
                            dedicated to protecting the privacy and security of clients&#39; information. This Legal Policy
                            outlines the terms and conditions under which the company’s cybersecurity products
                            and services are provided, ensuring compliance with applicable laws and regulations as
                            per the respective governmental authorities.
                        </p>
                        </div>
                        <div className='mt-4 mb-4'>
                        <h4>Scope</h4>
                        <p>
                            This policy applies to all employees, vendors, and clients of Sequretek. It covers all
                            aspects of operations, including data collection, storage, processing, and security
                            measures. This policy does not apply to the practices of third parties that we do not own
                            or control.
                        </p>
                        </div>
                        <div className='mt-4 mb-4'>
                        <h4>Data Protection and Privacy</h4>
                            <ol>
                                <h5><li>Data Collection</li></h5>
                                <p>Personal and business information necessary for providing Sequretek products and
                                    services is collected. This may include, but is not limited to, name, title, company name,
                                    address, contact details and IP addresses.
                                </p>
                                <h5><li>Data Usage</li></h5>
                                <p>
                                    The collected data is used solely for the purposes of delivering, maintaining, and
                                    improving the services provided. The personal information thus collected is not sold,
                                    leased, or otherwise disclosed to third parties without explicit consent, except as
                                    required by law.
                                </p>
                                <h5><li>Data Security</li></h5>
                                <p>
                                    Robust security measures, including encryption, access controls, and regular security
                                    audits are implemented to protect client data from unauthorized access, disclosure,
                                    alteration, and destruction.
                                </p>
                                <h5><li>Data Retention</li></h5>
                                <p>
                                    Personal data is retained only as long as necessary to fulfill the purposes for which it
                                    was collected, or as required by law. The compliances, responsibilities and obligations
                                    relating to the storage and destruction of personal data are as per the principles set out
                                    in applicable relevant legislation.
                                </p>
                            </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Compliance with Laws and Regulations
                        </h4>
                            <ol>
                                <h5><li>General Compliance</li></h5>
                                <p>
                                    Sequretek follows best practices to comply with applicable laws and regulations
                                    regarding data protection and cybersecurity.
                                </p>
                                <h5><li>Breach Notification</li></h5>
                                <p>
                                    In the event of a data breach, Sequretek will notify affected clients, and the relevant
                                    authorities as required by law. Details about the nature of the breach, the data affected,
                                    and the measures taken to mitigate the impact will be shared.
                                </p>
                            </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Intellectual Property
                        </h4>
                            <ol>
                                <h5><li>Ownership</li></h5>
                                <p>
                                    All intellectual property rights in the services provided, including but not limited to
                                    software, documentation, and methodologies, are owned by Sequretek.
                                </p>
                                <h5><li>License</li></h5>
                                <p>
                                    Clients are granted a limited, non-exclusive, non-transferable license to use Sequretek’s
                                    products and services for their internal business purposes. This license does not include
                                    any rights to modify, distribute, or create derivative works based on Sequretek’s
                                    intellectual property.
                                </p>
                            </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Confidentiality
                        </h4>
                            <ol>
                                <h5><li>Client Information</li></h5>
                                <p>
                                    All client information is treated as confidential. Employees and contractors are required
                                    to sign confidentiality agreements and are trained on the importance of maintaining
                                    client confidentiality.
                                </p>
                                <h5><li>Non-Disclosure</li></h5>
                                <p>
                                    Client information will not be disclosed to third parties except as necessary to provide
                                    any services needed, or as required by law.
                                </p>
                            </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Dispute Resolution
                        </h4>
                            <ol>
                                <h5><li>Governing Law</li></h5>
                                <p>
                                    This policy and any disputes arising from it are governed by the laws of India.
                                </p>
                                <h5><li>Arbitration</li></h5>
                                <p>
                                    Any disputes arising under this policy will be resolved through binding arbitration in
                                    accordance with the rules of India.
                                </p>
                            </ol>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Amendments</h4>
                        <p>
                            We reserve the right to amend this Legal Policy from time to time. Clients will be notified
                            of any significant changes via email or on our website.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>Contact Information</h4>
                        <p>
                            For any grievances regarding this Legal Policy, please contact the Grievance office.
                        </p>
                        <p>Swati Pande, SVP &amp; Head Operations – India</p>
                        <p>Email id: privacy.website@sequretek.com</p>
                        <p>
                            By engaging Sequretek’s services, clients acknowledge and agree to the terms outlined
                            in this Privacy Policy.
                        </p>
                        <h5>LAST UPDATED: AUGUST 2024</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
