// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import './resources.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';

export default function Blogs24() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Cryptominers Aren't Fool's Gold</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Explore cyptomining threats, blockchain's role, and malware in the digital ecosystem." />
                <meta name="keywords" content="crypto mining, cryptocurrency, bitcoin, blockchain, proof-of-work, cryptojacking, malware" />
                <meta property="og:title" content="Cryptominers Aren't Fool's Gold" />
                <meta property="og:description" content="Explore cyptomining threats, blockchain's role, and malware in the digital ecosystem." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '}Cryptominers Aren't Fool's Gold</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Cryptominers Aren't Fool's Gold</h3>
                        <p>15-OCTOBER-2024 | Sequretek labs</p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/Cryptominer1.png' width={'100%'} height={420} alt='' />
                            <h4>Overview</h4>
                            <p>Cryptomining, at its core, is a collaborative process that helps secure the blockchain network, originally appealing to those willing to invest time and resources. However, the skyrocketing value of cryptocurrencies, like Bitcoin's surge from $1,000 to $17,000 in 2017, transformed cryptomining into a lucrative opportunity. This shift attracted not only legitimate miners but also cybercriminals, who saw the financial potential and exploited it.</p>
                            <p>Much like fool's gold, cryptomining malware can seem harmless at first glance, operating quietly in the background. However, instead of being worthless, it is highly valuable to cybercriminals. They hijack unsuspecting users' computing power, a practice known as cryptojacking, profiting without the victims' knowledge or consent. This malware does not require elaborate data theft or system breaches—it simply consumes system resources, reducing performance while remaining undetected.</p>
                            <p>The appeal for criminals lies in cryptocurrency’s inherent anonymity and traceability issues, which make it harder for them to get caught. As a result, cryptomining has escalated from a legitimate activity into a serious cybersecurity threat, illustrating that cryptominers, unlike fool’s gold, are far from worthless—they can cause significant harm in the wrong hands.</p><br/>
                            <img src='/images/blog-images/Cryptominer2.png' width={'100%'} height={420} alt='' /><br/>
                            <h4>Understanding Cryptominers and Related Terms</h4>
                            <p><strong>Cryptocurrency</strong></p>
                            <p>It is a digital currency that has become a popular medium for online transactions, enabling users to purchase a wide range of goods and services, from electronics and luxury items to digital products and professional services like web development and graphic design. The first cryptocurrency was Bitcoin, which was released as open-source software in 2009. As of June 2024, there were more than 25,000 other cryptocurrencies in the marketplace. This number is constantly fluctuating as new cryptocurrencies are created and older ones become inactive or abandoned.</p>
                            <p><strong>Blockchain</strong></p>
                            <p>When a cryptocurrency is created and controlled by a single entity, it is called centralized. But when everyone controls it, it is decentralized. Decentralized cryptocurrencies use a technology called blockchain. Imagine a blockchain as a digital ledger that keeps track of transactions. Each entry in the ledger is a block, and these blocks are linked together like a chain. Once a block is added to the chain, it cannot be changed, making transactions irreversible. Its built-in privacy technologies make it more difficult to trace transactions, making it a preferred choice for illicit activities. Imagine a bank statement. It clearly shows who sent money to whom and for what reason. Blockchains are not like that. They use a code system instead of names. While anyone can see these transactions happening, it is like looking at a coded message. It is difficult (but not impossible) to figure out who is sending and receiving money with this code. This secrecy makes it appealing for people who want to hide their financial activities, like criminals. However, just like a detective might crack a coded message, police and investigators can still use special tools to track suspicious transactions on blockchains.</p>
                            <p><strong>Immutable Ledger</strong></p>
                            <p>Once added to the blockchain, transactions are nearly impossible to alter. This immutability ensures that once a transaction is recorded, it is permanent, providing a transparent and incorruptible record.</p>
                            <p><strong>Proof-of-Work (PoW) Blockchain Mining</strong></p><br/>
                            <img src='/images/blog-images/Cryptominer3.png' width={'100%'} height={530} alt='' /><br/>
                            <p>Transaction Pooling</p>
                            <p>New, unverified transactions are collected in a pool. Transaction pooling is required in blockchain networks to improve efficiency and scalability.</p>
                            <p>Here is why:</p>
                            <ul>
                                <li><p><strong>Reduced network congestion:</strong> By grouping multiple transactions into a single block, the number of individual transactions that need to be broadcast across the network is reduced, which helps to alleviate network congestion and improve transaction speed.</p></li>
                                <li><p><strong>Increased block size:</strong> Larger blocks can accommodate more transactions, allowing for a higher transaction throughput.</p></li>
                                <li><p><strong>Reduced orphan transactions:</strong> Orphan transactions are transactions that are not included in a block due to network delays or other factors. Transaction pooling can help to reduce the number of orphan transactions by ensuring that transactions are bundled together and broadcast in a timely manner. While it is possible to process transactions individually, transaction pooling is a more efficient and scalable approach for blockchain networks.</p></li>
                            </ul>
                            <p><strong>Block Creation</strong></p>
                            <p>A miner creates a block by bundling these transactions. Transaction pooling helps to reduce network congestion by reducing the size of individual transactions that need to be broadcast across the network.</p>
                            <p>Here is a breakdown of how it works:</p>
                            <ul>
                                <li><p><strong>Individual transactions:</strong> Each transaction is a separate piece of data that needs to be transmitted across the network.</p></li>
                                <li><p><strong>Transaction pooling:</strong> Multiple transactions are grouped together into a single block. This block is then broadcast across the network.</p></li>
                                <li><p><strong>Reduced network traffic:</strong> Since the block contains multiple transactions, the total amount of data that needs to be transmitted is reduced, which helps to alleviate network congestion.</p></li>
                            </ul>
                            <p>Transaction pooling is like bundling multiple small packages into a larger package to reduce shipping costs. By grouping transactions, the overall network traffic is reduced, which improves efficiency and scalability.</p>

                            <p><strong>Mining Competition</strong></p>
                            <p>Miners compete to solve a difficult cryptographic puzzle. In the context of blockchain mining, the "difficult math problem" miners race to solve is designed to be hard to compute but easy to verify. This means:</p>
                            <ul>
                                <li><p><strong>Hard to solve:</strong> It requires significant computational power to find the solution. This is intentional to prevent malicious actors from easily manipulating the network.</p></li>
                                <li><p><strong>Easy to verify:</strong> Once a solution is found, it is relatively simple for other miners to verify its correctness. This ensures that the blockchain remains secure and that only valid transactions are added to the network.</p></li>
                            </ul>

                            <p><strong>Puzzle Generation</strong></p>
                            <p>The blockchain network generates a new puzzle based on the previous block. The miners are solving a cryptographic puzzle that is designed to be difficult to compute but easy to verify. This puzzle is not directly related to the specific transactions being verified.</p>
                            <p>The cryptographic puzzle in blockchain mining is generated by the blockchain network itself. It is a complex mathematical problem that changes with each new block. The goal of miners is to be the first to solve this puzzle. Here is a breakdown of the process:</p>
                            <ul>
                                <li><p><strong>Puzzle generation:</strong> The blockchain network generates a new puzzle based on the previous block's hash and other data. This puzzle is typically a SHA256 hash function.</p></li>
                                <li><p><strong>Mining competition:</strong> Miners around the world race to solve this puzzle using their specialized hardware. The first miner to find a valid solution wins.</p></li>
                                <li><p><strong>Verification:</strong> Other miners verify the solution's accuracy. If it is correct, the new block is added to the blockchain.</p></li>
                            </ul>
                            <p>Why is this puzzle so difficult to solve?</p>
                            <ul>
                                <li><p><strong>Hash Function Complexity:</strong> SHA256 is a cryptographic hash function designed to be computationally intensive. This makes it difficult to find the correct input (nonce) that produces a specific hash output.</p></li>
                                <li><p><strong>Nonce Variation:</strong> The puzzle involves finding a nonce, a random number that is included in the block header. Miners must try different nonces until they find one that produces a hash value below a certain target.</p></li>
                            </ul>
                            <p>The puzzle is a way to ensure that creating new blocks requires significant computational effort. This helps to secure the network and prevent malicious actors from easily manipulating the blockchain.</p>
                            <p>Here is a breakdown of why this puzzle is used:</p>
                            <ul>
                                <li><p><strong>Proof-of-Work:</strong> The puzzle is a part of the proof-of-work (PoW) consensus mechanism used by many blockchains.</p></li>
                                <li><p><strong>Security:</strong> Solving the puzzle requires significant computational power, which makes it difficult for malicious actors to manipulate the network.</p></li>
                                <li><p><strong>Fairness:</strong> The puzzle ensures that the mining process is fair and that no single miner has an unfair advantage.</p></li>
                                <li><p><strong>Incentive:</strong> The reward for solving the puzzle incentivizes miners to contribute their computing power to the network and maintain its security.</p></li>
                            </ul>
                            <p>The puzzle is a mechanism to ensure the integrity and security of the blockchain network. It is not directly related to the verification of individual transactions but rather serves as a broader security measure.</p>

                            <p><strong>Verification</strong></p>
                            <p>Miners verify the solution by recomputing the hash function themselves. Here is how it works:</p>
                            <ul>
                                <li><p><strong>Solution Broadcast:</strong> When a miner finds a valid solution, they broadcast it to the network. This includes the block header, which contains the nonce (the random number used in the puzzle).</p></li>
                                <li><p><strong>Verification:</strong> Other miners receive the solution and recompute the hash function using the same algorithm and parameters.</p></li>
                                <li><p><strong>Comparison:</strong> If the recomputed hash matches the target hash specified in the blockchain's rules, the solution is considered valid.</p></li>
                            </ul>
                            <p>This verification process ensures that:</p>
                            <ul>
                                <li><p><strong>The solution is correct:</strong> The miner has indeed found a valid solution to the puzzle.</p></li>
                                <li><p><strong>The solution is unique:</strong> No other miner has found the same solution.</p></li>
                                <li><p><strong>The solution is consistent with the blockchain's rules:</strong> The solution adheres to the specific rules and algorithms defined for the particular blockchain.</p></li>
                            </ul>
                            <p>By verifying the solution independently, miners can be confident that the new block is valid and can be added to the blockchain. This helps to maintain the security and integrity of the network.</p>

                            <p><strong>Cryptomining Rewards</strong></p>
                            <p>Cryptomining rewards are the incentives given to miners for their contribution to securing and validating the blockchain network. These rewards typically consist of newly minted cryptocurrency and transaction fees.</p>
                            <p>Key points:</p>
                            <ul>
                                <li><p><strong>Block rewards:</strong> Cryptocurrencies are mined in blocks. Each time a block is successfully added to the blockchain, the miner who created it receives a reward.</p></li>
                                <li><p><strong>Halving:</strong> The reward for mining a block is halved at regular intervals. In Bitcoin, this happens approximately every four years. This mechanism helps to control the supply of the cryptocurrency and prevent inflation.</p></li>
                                <li><p><strong>Mining difficulty adjustment:</strong> As more miners join the network, the difficulty of solving the cryptographic puzzle required to create a block increases. This adjustment ensures that blocks are created at a consistent rate, typically around 10 minutes for Bitcoin.</p></li>
                                <li><p><strong>Payout reduction:</strong> Over time, as the supply of cryptocurrency increases and the difficulty of mining rises, the individual rewards for each block decrease.</p></li>
                                <li><p><strong>Mining pools:</strong> To improve their chances of winning the block reward, miners often join mining pools. These pools combine the computing power of multiple miners, sharing the rewards based on each miner's contribution.</p></li>
                            </ul>
                            <p>Cryptomining rewards are a way to incentivize miners to contribute their resources to the network and maintain its security. However, the rewards are not guaranteed and can be quite competitive, especially as the network grows and the difficulty of mining increases.</p>
                            <p>Example: In Bitcoin, the initial block reward was 50 BTC. After approximately 210,000 blocks (about four years), the reward was halved to 25 BTC.</p>

                            <p><strong>Computational Power</strong></p>
                            <p>The effectiveness of mining relies heavily on computational power, which has evolved from using CPUs to GPUs, FPGAs, and ASICs specifically designed for mining. The rise of ASICs has significantly increased efficiency but also raised environmental concerns due to high electricity consumption.</p>

                            <p><strong>Purpose of the Process</strong></p>
                            <ul>
                                <li><p><strong>Security:</strong> Validates transactions and ensures they are immutable.</p></li>
                                <li><p><strong>Decentralization:</strong> Promotes a network where no single entity has control.</p></li>
                            </ul>
                            <p><strong>Cryptojacking and Cryptomalware</strong></p>
                            <p>Cryptojacking involves cybercriminals using a victim's computing resources to mine cryptocurrency without consent, causing performance issues and increased energy costs. Cryptomalware is the malicious software that facilitates this unauthorized mining, allowing attackers to benefit from mining rewards while victims suffer the consequences. Being aware of these threats is crucial for safeguarding systems against cryptomalware attacks.</p>

                            <h4>The Evolution of Cryptomining Malware</h4>
                            <p>The evolution of cryptomining malware is closely tied to the development of cryptocurrency and advancements in cryptographic technologies, which have laid the foundation for both innovation and exploitation in the digital landscape.</p>
                            <p><strong>20th Century Foundations</strong></p>
                            <ul>
                                <li><p><strong>Public-Key Cryptography (1970s):</strong> Whitfield Diffie and Martin Hellman pioneered the shift to asymmetric cryptography, which made secure digital signatures possible, an essential component of Bitcoin's functionality.</p></li>
                                <li><p><strong>Cryptographic Hash Functions:</strong> These became vital for Bitcoin's proof-of-work mechanism, creating a secure framework for transactions.</p></li>
                                <li><p><strong>RSA Algorithm (1977):</strong> Pioneered secure data transfer and laid groundwork for encryption methods used in cryptocurrency.</p></li>
                                <li><p><strong>Zero-Knowledge Proofs (1980s):</strong> Enabled the verification of statements without revealing information, forming the basis for privacy-focused cryptocurrencies.</p></li>
                            </ul>
                            <p><strong>Bitcoin: The Culmination of Ideas</strong></p>
                            <p>Bitcoin's creation in 2008 by Satoshi Nakamoto synthesized decades of cryptographic advances and digital currency experiments. Its key innovation was solving the double-spending problem through the blockchain, a decentralized ledger verified by participants using proof-of-work.</p>

                            <p><strong>The Rise of Cryptomining Malware</strong></p>
                            <ul>
                                <li><p><strong>Initial malware (2011):</strong> Early cryptomining malware targeted personal computers, allowing hackers to exploit user resources without consent as cryptocurrency values began to rise.</p></li>
                                <li><p><strong>High-Profile Breaches (2014):</strong> Notable events included the hacking of Harvard’s supercomputer cluster to mine Dogecoin and similar attacks on U.S. National Science Foundation supercomputers, marking a shift towards targeting more powerful systems.</p></li>
                                <li><p><strong>Federal Infiltrations (2017):</strong> Even government systems were not immune; a U.S. Federal Reserve server was hijacked for Bitcoin mining, highlighting the increasing sophistication of attacks.</p></li>
                            </ul>
                            <p><strong>Exploitation of Vulnerabilities</strong></p>
                            <ul>
                                <li><p><strong>Exploitation of Software Flaws:</strong> Hackers adapted by targeting system vulnerabilities, with Adylkuzz utilizing the EternalBlue exploit, previously leveraged by WannaCry ransomware, to mine Monero.</p></li>
                                <li><p><strong>Diverse Targeting (2014 Onward):</strong> Malware spread beyond personal computers to mobile and IoT devices. Kagecoin infected Android devices, while Raspberry Pi systems became new targets for cryptojacking.</p></li>
                            </ul>
                            <p><strong>Notable Malware Variants</strong></p>
                            <p>Emergence of Notable Threats:</p>
                            <ul>
                                <li><p><strong>XMRig:</strong> A prevalent tool for mining Monero, often embedded in other malware.</p></li>
                                <li><p><strong>Rubyminer:</strong> Targeted Windows and Linux servers to deploy cryptomining operations.</p></li>
                                <li><p><strong>LemonDuck:</strong> Combined cryptomining with credential theft and malware delivery through vulnerabilities.</p></li>
                                <li><p><strong>WannaMine:</strong> Spread via the EternalBlue exploit, specifically mining Monero.</p></li>
                            </ul>
                            <p><strong>Advanced Tactics and Methods</strong></p>
                            <ul>
                                <li><p><strong>Botnet Utilization:</strong> Hackers began using botnets—networks of compromised devices—to enhance their mining capabilities, significantly increasing their efficiency.</p></li>
                                <li><p><strong>Stealthy Infections:</strong> Malware evolved to disguise itself as legitimate software, using phishing emails and malicious websites to infect systems.</p></li>
                                <li><p><strong>Web-Based Mining:</strong> New strategies emerged, with cryptojacking scripts injected into websites to secretly mine cryptocurrency using visitors' processing power, leading to slowed performance and battery drain.</p></li>
                                <li><p><strong>AI Integration:</strong> Emerging malware now leverages artificial intelligence to optimize mining processes and evade detection, complicating defense strategies.</p></li>
                            </ul>
                            <p><strong>Ongoing Threats</strong></p>
                            <ul>
                                <li><p><strong>Continued Evolution:</strong> As cryptocurrency and technology advance, cryptomining malware adapts, posing a significant and growing threat across various digital environments.</p></li>
                            </ul>
                            <p>Each stage in this evolution reflects a response to the burgeoning value of cryptocurrencies, underscoring the persistent cat-and-mouse game between cybercriminals and cybersecurity efforts.</p>

                            <h4>Present state and Security Concerns</h4>
                            <p><strong>Cryptomining Malware Present State</strong></p>
                            <p>The rise in cryptocurrency prices, especially Bitcoin, has made cryptomining more profitable, attracting both legitimate miners and malware creators. The Financial Times (FT) notes that the higher profitability leads to more cryptomining, including the malicious variety, where hackers use malware to hijack systems for mining.</p>

                            <p><strong>Efficiency in Cryptomining</strong></p>
                            <p>More efficient cryptomining operations invest in specialized hardware (ASICs), optimized software, and energy saving methods. As miners improve their systems, they also enhance security measures such as better system monitoring, faster response times to suspicious activity, and proactive defenses like firewalls and security audits.</p>
                            <p>However, while individual miners may gain from increased efficiency, the overall profitability of cryptomining remains stable because as efficiency increases, mining difficulty adjusts to keep the block generation rate consistent. This ensures that competition stays balanced across the industry.</p>

                            <p><strong>Environmental and Regulatory Pressures</strong></p>
                            <p>Governments are implementing stricter environmental regulations on cryptomining, particularly due to high energy consumption. These regulations, such as energy usage limits or carbon taxes, make it harder for malware to mine undetected. Public awareness of the environmental impact is also reducing support for cryptomining, affecting both legitimate operations and malicious actors.</p>
                            <p>The Russian Finance Ministry aims to impose an energy usage tax on cryptocurrency miners to address environmental concerns. This move responds to the increasing energy consumption associated with crypto mining, which has drawn criticism for its ecological impact. The government seeks to regulate this sector more strictly, reflecting growing global scrutiny over energy-intensive industries. By targeting miners, Russia aims to align with environmental goals while managing the economic aspects of cryptocurrency operations.</p>

                            <p><strong>Increasing Competition</strong></p>
                            <p>The growing number of miners and mining pools has increased competition, leading to lower profit margins for everyone involved. This could make cryptomining less attractive for malware creators as profits decline. However, this competition drives innovation in mining technologies, which may further restrict malware activity.</p>

                            <p><strong>AI’s Role in Cryptomining Malware</strong></p>
                            <p>Artificial intelligence (AI) is becoming a double-edged sword in cryptomining. On one hand, AI allows malware to evade detection by identifying security weaknesses and adapting its behavior to bypass defenses. On the other hand, AI is also being used to strengthen antimalware defenses, such as identifying suspicious network traffic and recognizing malware patterns. </p>
                            <p>In short, while cryptomining remains profitable and attractive to both miners and hackers, advances in security, regulations, and AI are shaping the battlefield, making it harder for malware to thrive.</p>

                            <p><strong>Cryptominers in the Cloud</strong></p>
                            <p>Cryptominers are increasingly targeting cloud environments, exploiting misconfigurations for unauthorized access to mine cryptocurrencies. Attacks often stem from poorly secured cloud settings, enabling the deployment of mining software that profits at users' expense.</p>
                            <p>A notable attack involved the Kinsing malware, which exploited CVE-2023-46604 to target misconfigured Kubernetes clusters, deploying malicious containers. Cybercriminals actively scan for vulnerable systems, leveraging common vulnerabilities to identify easy targets.</p>
                            <p>Additionally, attackers utilize malware like XMRig for Monero mining, which can significantly degrade cloud performance, lead to unexpected charges, and compromise sensitive data. Other reported techniques include the use of Docker containers for mining, with signs such as increased CPU usage and unusual outbound traffic serving as indicators of potential cryptomining activity.</p>

                            <h4>Conclusion</h4>
                            <p>Cryptomining malware has transformed from a legitimate process into a significant cybersecurity threat, driven by the lucrative nature of cryptocurrency. As malicious actors exploit unsuspecting systems for mining, the risks continue to grow, particularly with advancements in technology and AI. While increased competition, regulatory pressures, and improved security measures may hinder the profitability of cryptomining for cybercriminals, the allure of cryptocurrency ensures that this threat will persist. Continuous vigilance and proactive security strategies will be essential to mitigate the evolving landscape of cryptomining malware and protect against its detrimental effects.</p>
                            
                            <h4>References</h4>
                            <p>[i] <a target='_blank' href='https://blog.sucuri.net/2022/07/cryptominers-webassembly-in-website-malware.html'>https://blog.sucuri.net/2022/07/cryptominers-webassembly-in-website-malware.html</a></p>
                            <p>[ii] <a target='_blank' href='https://news.sophos.com/en-us/2017/12/19/web-based-cryptominers-are-malware/'>https://news.sophos.com/en-us/2017/12/19/web-based-cryptominers-are-malware/</a></p>
                            <p>[iii] <a target='_blank' href='https://sysdig.com/blog/detecting-cryptomining-attacks-in-the-wild/'>https://sysdig.com/blog/detecting-cryptomining-attacks-in-the-wild/</a></p>
                            <p>[iv] <a target='_blank' href='https://www.checkpoint.com/cyber-hub/threat-prevention/what-is-malware/what-is-crypto-malware/'>https://www.checkpoint.com/cyber-hub/threat-prevention/what-is-malware/what-is-crypto-malware/</a></p>
                            <p>[v] <a target='_blank' href='https://www.investopedia.com/tech/how-does-bitcoin-mining-work/'>https://www.investopedia.com/tech/how-does-bitcoin-mining-work/</a></p>
                            <p>[vi] <a target='_blank' href='https://www.trendmicro.com/vinfo/us/security/news/cybercrime-and-digital-threats/security-101-the-impact-of-cryptocurrency-mining-malware'>https://www.trendmicro.com/vinfo/us/security/news/cybercrime-and-digital-threats/security-101-the-impact-of-cryptocurrency-mining-malware</a></p>
                            <p>[vii] <a target='_blank' href='https://meta-luban.com/blog/the-history-of-crypto-mining-a-retrospective-on-the-evolution-of-the-industry/'>https://meta-luban.com/blog/the-history-of-crypto-mining-a-retrospective-on-the-evolution-of-the-industry/</a></p>
                            <p>[viii] <a target='_blank' href='https://www.britannica.com/money/what-is-crypto-mining'>https://www.britannica.com/money/what-is-crypto-mining</a></p>
                            <p>[ix] <a target='_blank' href='https://en.wikipedia.org/wiki/Cryptocurrency'>https://en.wikipedia.org/wiki/Cryptocurrency</a></p>
                            <p>[x] <a target='_blank' href='https://en.wikipedia.org/wiki/Proof_of_work'>https://en.wikipedia.org/wiki/Proof_of_work</a></p>
                            <p>[xi] <a target='_blank' href='https://www.trendmicro.com/en_ae/research/23/k/cve-2023-46604-exploited-by-kinsing.html'>https://www.trendmicro.com/en_ae/research/23/k/cve-2023-46604-exploited-by-kinsing.html</a></p>
                            <p>[xii] <a target='_blank' href='https://ambcrypto.com/russian-finance-ministry-targets-crypto-miners-with-energy-usage-tax/'>https://ambcrypto.com/russian-finance-ministry-targets-crypto-miners-with-energy-usage-tax/</a></p>
                            <p>[xiii] <a target='_blank' href='https://thehackernews.com/2024/01/cryptominers-targeting-misconfigured.html'>https://thehackernews.com/2024/01/cryptominers-targeting-misconfigured.html</a></p>
                            <p>[xiv] <a target='_blank' href='https://www.trendmicro.com/en_ie/research/24/f/water-sigbin-xmrig.html'>https://www.trendmicro.com/en_ie/research/24/f/water-sigbin-xmrig.html</a></p>
                            <p>[xv] <a target='_blank' href='https://securityboulevard.com/2024/09/cryptominers-in-the-cloud/'>https://securityboulevard.com/2024/09/cryptominers-in-the-cloud/</a></p>
                            <p>[xvi] <a target='_blank' href='https://www.ft.com/content/62319fbf-8c74-4650-84d8-4485e683adad'>https://www.ft.com/content/62319fbf-8c74-4650-84d8-4485e683adad</a></p>
                            <p>[xvii] <a target='_blank' href='https://qz.com/ai-bitcoin-mining-1851623128'>https://qz.com/ai-bitcoin-mining-1851623128</a></p>
                            <p>[xviii] <a target='_blank' href='https://darktrace.com/blog/how-ai-uncovered-outlaws-secret-crypto-mining-operation'>https://darktrace.com/blog/how-ai-uncovered-outlaws-secret-crypto-mining-operation</a></p>
                            <p>[xix] <a target='_blank' href='https://en.wikipedia.org/wiki/Privacy_and_blockchain'>https://en.wikipedia.org/wiki/Privacy_and_blockchain</a></p>
                        </div>
                    </div>
                </div>
                <ShareUrl
                    details={{ title: 'Cryptominers Arent Fools Gold', url: 'https://www.sequretek.com/resources/blog/Cryptominers-Arent-Fools-Gold' }}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title='Cryptominers Arent Fools Gold'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
