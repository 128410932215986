// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
// import Footer from './footer';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import CookieConsent from "react-cookie-consent";

// ############################ Require files here ###############################
import './header.scss'
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
const Footer = React.lazy(() => import('./footer'));


/* ######################################### Website Header ########################### */

export default function WebHeader() {
    const [active, setActive] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [activeDropdowns, setActiveDropdowns] = useState('');
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const [isOpen, setIsOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isScrolled, setIsScrolled] = useState(false);


    useEffect(() => {

        const handleClickOutside = (e) => {
            if (activeMenu && !activeMenu.contains(e.target)) {
                setIsOpen(false);
                setActiveMenu(null);
            }
        };

        document.body.addEventListener("click", handleClickOutside);

        return () => {
            document.body.removeEventListener("click", handleClickOutside);
        };
    }, [activeMenu]);

    //Header scroll menu
    useEffect(() => {

        if (window.innerWidth > 767) {
            window.addEventListener('scroll', headerScroll);
        }
        return () => {
            window.removeEventListener('scroll', headerScroll);
        }
    }, [])

    const headerScroll = () => {
        if ((window.location.pathname !== '/partner-portal-login') && (window.location.pathname !== '/partner-registration')) {
            const header = document.getElementById('seq-head');
            if (window.scrollY > 0) {
                header?.classList.add('head-visible');
            } else {
                header?.classList.remove('head-visible');
            }
        }
    }

    //bottom scroll 
    useEffect(() => {
        if (window.innerWidth < 767) {
            window.addEventListener('scroll', bottomScroll);

            // Scroll to the top when the button is clicked
            document.getElementById('goToTopBtn').addEventListener('click', function () {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            });
        }
        return () => {
            if (window.innerWidth < 767) {
                window.removeEventListener('scroll', bottomScroll)

                // Scroll to the top when the button is clicked
                document.getElementById('goToTopBtn').removeEventListener('click', function () {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                });
            }
        }
    }, [])

    const bottomScroll = () => {
        const goToTopBtn = document.getElementById('goToTopBtn');

        if (window.scrollY > 800) {
            goToTopBtn.classList.add('show');
        } else {
            goToTopBtn.classList.remove('show');
        }
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const handleSubMenuClick = () => {
        setIsOpen(false);
        setActiveMenu(null);
    };

    const handleMouseEnter = (val, type) => {
        setActive(type);
        setIsOpen(false)
        setTimeout(() => {
            setActiveMenu(document.getElementById(val));
            setIsOpen(true);
        }, 0); // Adjust
    };
    const handleMouseLeave = (val) => {
        setActive('');
        setActiveMenu(null);
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleDropdown = (dropdown) => {
        setActiveDropdowns((prevDropdown) =>
            prevDropdown === dropdown ? '' : dropdown
        );
    };

    const navigate = useNavigate();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        window.innerWidth < 1024 && setMenuOpen(false);
        handleSubMenuClick();
        setActive('');
        // const select = document.querySelector('seq-dropdown');
        if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'banking') {
            navigate('/industries/cybersecurity-for-banks')
        } else if (val === 'finance') {
            navigate('/industries/financial-institution-cybersecurity')
        } else if (val === 'insurance') {
            navigate('/industries/insurance-cybersecurity')
        } else if (val === 'manufacturing') {
            navigate('/industries/manufacturing-cybersecurity')
        } else if (val === 'healthcare') {
            navigate('/industries/healthcare-cybersecurity')
        } else if (val === 'pharmaceuticals') {
            navigate('/industries/pharma-data-protection')
        } else if (val === 'retail') {
            navigate('/industries/retail-cybersecurity')
        } else if (val === 'government') {
            navigate('/industries/cybersecurity-for-government')
        } else if (val === 'smb') {
            navigate('/industries/smb-cybersecurity-solutions')
        } else if (val === 'technology') {
            navigate('/industries/technology-cybersecurity')
        } else if (val === 'demo') {
            navigate('/schedule-a-demo')
        } else if (val === 'contact') {
            navigate('/contactus')
        } else if (val === 'breach') {
            navigate('/experiencing-breach')
        } else if (val === 'resources') {
            navigate('/resources-hub')
        } else if (val === 'Reports') {
            navigate('/resources-hub', { state: { type: 'report' } })
        } else if (val === 'advisory') {
            navigate('/resources-hub', { state: { type: 'advisory' } })
        } else if (val === 'case-study') {
            navigate('/resources-hub', { state: { type: 'case-study' } })
        } else if (val === 'blog') {
            navigate('/resources-hub', { state: { type: 'blog' } })
        } else if (val === 'event') {
            navigate('/resources/upcoming-events-webinars')
        } else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        } else if (val === 'podcast') {
            navigate('/resources-hub', { state: { type: 'podcast' } })
        } else if (val === 'whitepaper') {
            navigate('/resources-hub', { state: { type: 'whitepaper' } })
        } else if (val === 'video') {
            navigate('/resources-hub', { state: { type: 'video' } })
        } else if (val === 'demos') {
            navigate('/resources-hub', { state: { type: 'demo' } })
        } else if (val === 'infographics') {
            navigate('/resources-hub', { state: { type: 'infographic' } })
        } else if (val === 'service') {
            navigate('/services', { state: { active: 'governancerisk' } })
        }
        else if (val === 'securitymanage') {
            navigate('/services', { state: { active: 'securitymanage' } })
        } else if (val === 'governancerisk') {
            navigate('/services', { state: { active: 'governancerisk' } })
        } else if (val === 'incidentmanage') {
            navigate('/services', { state: { active: 'incidentmanage' } })
        } else if (val === 'pathmanage') {
            navigate('/services', { state: { active: 'pathmanage' } })
        } else if (val === 'Why Sequretek') {
            navigate('/sequretek-benefits')
        } else if (val === 'Partner') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'overview' } })
        } else if (val === 'program') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'benifits' } })
        }
        else if (val === 'mssp') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'mssp' } })
        }
        else if (val === 'msp') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'msp' } })
        }
        else if (val === 'other') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'other' } })
        }
        else if (val === 'current') {
            navigate('/cybersecurity-solution-provider-partner', { state: { active: 'current' } })
        }
        else if (val === 'partner-portal') {
            navigate('/partner-portal-login', { state: { type: 'register' } })
        }
        else if (val === 'careers') {
            navigate('/join-our-team')
        } else if (val === 'job') {
            navigate('/join-our-team', { state: { scroll: 'job' } })
        } else if (val === 'values') {
            navigate('/join-our-team', { state: { scroll: 'values' } })
        } else if (val === 'seq-story') {
            navigate('/sequretek-story')
        } else if (val === 'leadership') {
            navigate('/sequretek-story', { state: { scroll: 'leadership' } })
        } else if (val === 'investor') {
            navigate('/sequretek-story', { state: { scroll: 'investor' } })
        } else if (val === 'diversity') {
            navigate('/sequretek-story', { state: { scroll: 'diversity' } })
        } else if (val === 'award') {
            navigate('/sequretek-benefits', { state: { scrollTo: 'awards' } })
        }
        if (val === 'press') {
            navigate('latest-news')
        }
        else if (val === 'media') {
            navigate('/newsroom/article')
        } else if (val === 'home') {
            navigate('/')
        } else if (val === 'portal-login') {
            navigate('/partner-portal-login')
        }

    }

    const mainHeadPage = (val) => {
        if (window.innerWidth > 1025) {
            window.scrollTo(0, 0);
        }
        const isTabletOrSmaller = window.matchMedia("(max-width: 1300px)").matches;
        handleSubMenuClick();
        if (!isTabletOrSmaller) {
            if (val === 'platform') {
                navigate('/sequretek-percept-platform')
            } else if (val === 'service') {
                navigate('/services')
            } else if (val === 'sequretek') {
                navigate('/sequretek-benefits')
            } else if (val === 'partner') {
                navigate('/cybersecurity-solution-provider-partner')
            } else if (val === 'resources') {
                navigate('/resources-hub')
            } else if (val === 'company') {
                navigate('/sequretek-story')
            }
        }
    }

    const handleNavigation = (val) => {
        window.scrollTo(0, 0);
        window.innerWidth < 1024 && setMenuOpen(false);
        if (val === 'collapseOne') {
            navigate('/services', { state: { openAccordion: 'collapseOne' } })
        } else if (val === 'collapseTwo') {
            navigate('/services', { state: { openAccordion: 'collapseTwo' } })
        } else if (val === 'collapseThree') {
            navigate('/services', { state: { openAccordion: 'collapseThree' } })
        } else if (val === 'collapseFour') {
            navigate('/services', { state: { openAccordion: 'collapseFour' } })
        }
    }

    const handleAccordion = (accordionId) => {
        navigate('/sequretek-benefits', { state: { accordionId } });
    };

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    const customStyle = {
        backgroundImage: window.innerWidth > 767 && (window.location.pathname === '/sequretek-benefits' ? `url('/images/bg-img37.svg')` : window.location.pathname === '/sequretek-percept-platform' ? `url('/images/bg-img18.svg')` : window.location.pathname === '/products/extended-detection-and-response-and-ngsiem' ? `linear-gradient(41.44deg, rgba(4, 12, 48, 0.93) 37.24%, rgba(6, 6, 93, 0.93) 63.08%),url('/images/bg-img25.png')` : window.location.pathname === '/products/identity-and-access-governance' ? `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%),url('/images/bg-img20.png')` :
            window.location.pathname === '/products/endpoint-detection-and-response' ? `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%), url(/images/bg-img26.png)` : window.location.pathname === '/products/compliance-manager' ? `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%), url("/images/bg-img24.png")` :
                window.location.pathname === '/services' ? `linear-gradient(274.85deg, rgba(4, 12, 48, 0.75) 23.83%, rgba(6, 6, 93, 0.75) 61.65%), url("/images/bg-img27.png")` : window.location.pathname === '/join-our-team' ? `url("/images/bg-img84.svg")` : window.location.pathname === '/sequretek-story' ? `url(/images/bg-img38.svg)` : window.location.pathname === '/cybersecurity-solution-provider-partner' ? `url('/images/bg-img42.svg')` :
                    window.location.pathname === '/' ? `linear-gradient(180deg, rgba(6, 6, 93, 0.5) 0%, #040C30 100%),url('/images/sqtk-banner.gif')`
                        : window.location.pathname === '/partner-portal-login' ? `url('/images/bg-img41.svg')` : window.location.pathname === '/partner-registration' ? `url('/images/bg-img41.svg')`
                            : null)

    }

    const customStyle1 = {
        background: window.innerWidth > 767 &&
            (((window.location.pathname === '/sequretek-benefits') || (window.location.pathname === '/sequretek-percept-platform') || (window.location.pathname === '/products/extended-detection-and-response-and-ngsiem') || (window.location.pathname === '/products/identity-and-access-governance') || (window.location.pathname === '/products/endpoint-detection-and-response')
                || (window.location.pathname === '/products/compliance-manager') || (window.location.pathname === '/services') || (window.location.pathname === '/join-our-team') || (window.location.pathname === '/sequretek-story') || (window.location.pathname === '/cybersecurity-solution-provider-partner') || (window.location.pathname === '/')
                || (window.location.pathname === '/partner-portal-login') || (window.location.pathname === '/partner-registration') || (window.location.pathname === '/schedule-a-demo') || (window.location.pathname === '/contactus') || (window.location.pathname === '/experiencing-breach') || (window.location.pathname === '/resources-hub') ||
                (window.location.pathname === '/cookie-policy') || (window.location.pathname === '/privacy-policy')
                || (window.location.pathname === '/vulnerability-disclosure-policy') || (window.location.pathname === '/legal-policy')) ? `unset` : '')
    }

    return (
        <div className={
            ((window.location.pathname === '/resources-hub') || (window.location.pathname === '/schedule-a-demo') || (window.location.pathname === '/contactus') || (window.location.pathname === '/experiencing-breach')
                || (window.location.pathname === '/cookie-policy') || (window.location.pathname === '/privacy-policy') || (window.location.pathname === '/vulnerability-disclosure-policy') || (window.location.pathname === '/legal-policy')) ? 'gredient-wrapper' : ''} >
            <div className='seq-header' style={customStyle}>
                {window.location.pathname === '/' ? <div className={window.innerWidth > 1023 ? 'gredient' : null}></div> : null}

                <div>
                    <div className='d-flex seq-header-top d-none d-lg-flex'>
                        <p className='seq-header-top-text cursor-pointer' onClick={() => setPage('advisory')} >Download Threat Advisory</p>
                        <p className='seq-header-top-text cursor-pointer' onClick={() => setPage('breach')}>Experiencing a Breach?</p>
                        <p className='seq-header-top-text cursor-pointer' onClick={() => setPage('contact')}>Contact Us</p>
                    </div>
                    <div className={(window.location.pathname === '/partner-portal-login' || window.location.pathname === '/partner-registration') ? 'd-flex seq-header-bottom seq-head1' : 'd-flex seq-header-bottom sticky-head'} style={customStyle1} id={"seq-head"}>
                        <div className='seq-mainlogo'>
                            <img className='seq-logo cursor-pointer' src='/images/Hero-Logo.svg' alt='' onClick={() => navigate('/')} />
                        </div>
                        <div className='d-flex seq-header-bottom2'>
                            <Navbar className='seq-header-nav' expand="lg">
                                <Navbar.Toggle aria-controls="seq-navbar-nav " style={{ border: "transparent" }}>
                                    <img className='justify-content-end' src='/images/hambeger-menu.svg' alt="menu" />
                                </Navbar.Toggle>
                                <Navbar.Collapse id="seq-basic-navbar-nav" className='seq-nav-collaps'>
                                    <Nav className="mr-auto seq-navbar-nav-items">
                                        <div id="basic-nav-dropdown1" >
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown1', 'platform')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('platform')}>
                                                Platform&nbsp;
                                                <img src={active === 'platform' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('platform')} onMouseLeave={() => setActive('')} >
                                                    <div className='container  d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block'>
                                                                <div className='d-flex' style={{ gap: '200px' }}>
                                                                    <div style={{ width: '581px' }}>
                                                                        <h6>Percept Continuous Threat Exposure Management (CTEM)</h6>
                                                                        <p>Simplify security, gain AI-powered continuous threat exposure management with complete attack surface visibility, threat detection, response, and remediation</p>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('perceptplatform')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' style={{ marginBottom: '20px' }} />
                                                        </div>
                                                        <div className='seq-nav-info'>
                                                            <div className='d-flex align-self-stretch' style={{ gap: '75px' }} >
                                                                <div className='seq-nav-info-block-body'>
                                                                    <h6>Percept XDR & NG SIEM</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Enhance your security posture with AI-powered extended detection & response and next-generation security information & event management SaaS offering. </p>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('perceptXdr')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block-body'>
                                                                    <h6>Percept Identity</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Take control of your identity and access risks now with a simplified, enterprise-scale, federated, zero trust identity and governance product.</p>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('PerceptIdentity')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block-body'>
                                                                    <h6>Percept EDR</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>On-agent AI for real-time endpoint detection, response, and protection against next-gen threats to maintain continuity of your business operations.</p>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('perceptEdr')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block-body'>
                                                                    <h6>Percept Compliance Manager</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>AI-powered analyzer for real-time monitoring of GRC through vulnerability, application, and configuration scans and audits within a single console.</p>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('perceptManager')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="basic-nav-dropdown2">
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown2', 'service')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('service')}>
                                                Services&nbsp;
                                                <img src={active === 'service' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('service')} onMouseLeave={() => setActive('')}>
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block'>
                                                                <div className='d-flex' style={{ gap: '300px' }}>
                                                                    <div style={{ width: '481px' }}>
                                                                        <h6>Services</h6>
                                                                        <p>Ensure your enterprise is protected from potential threats while adhering to the latest industry regulations.</p>
                                                                    </div>
                                                                    <div className='d-flex align-items-end '>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('service')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' />
                                                            <div className='d-flex' style={{ gap: '75px' }}>
                                                                <div className='seq-nav-info-block' style={{ width: '175px' }}>
                                                                    <h6 >Governance Risk and Compliance</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Become compliant with industry policies and standards through expert audits, gap analyses, and vulnerability management programs. </p>
                                                                    <div className='d-flex justify-content-start'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('governancerisk')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block' style={{ width: '175px' }}>
                                                                    <h6>Security Operations and Management</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Deployment, configuration, operations, and management to secure your endpoints, data centers, cloud assets, and other IT infrastructure. </p>
                                                                    <div className='d-flex justify-content-start'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('securitymanage')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block' style={{ width: '175px' }}>
                                                                    <h6>Incident Response and Management</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Management of cyberthreat detection, response, and remediation time with analyses and evidence-based actionable threat intelligence.</p>
                                                                    <div className='d-flex justify-content-start'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('incidentmanage')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                                <div className='seq-nav-info-block' style={{ width: '175px' }}>
                                                                    <h6>Patch Management as a service</h6>
                                                                    <p className='mt-2' style={{ height: '145px' }}>Efficient patch deployment for all OS, databases and endpoints, with agreed-upon SOWs and SLAs to ensure compliance and risk management.</p>
                                                                    <div className='d-flex justify-content-start'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('pathmanage')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <img src='/images/Line.svg' alt='' /> */}
                                                        </div>
                                                        {/* <div className='seq-nav-info-block seq-ind-block'>
                                                            <h6>Industries</h6>
                                                            <div className='seq-ind-block-items'>
                                                                <div className='d-flex justify-content-evenly'>
                                                                    <p className='seq-click' onClick={() => setPage('banking')}>Banking</p>
                                                                    <p className='seq-click' onClick={() => setPage('finance')}>Finance</p>
                                                                    <p className='seq-click' onClick={() => setPage('insurance')}>Insurance</p>
                                                                    <p className='seq-click' onClick={() => setPage('manufacturing')}>Manufacturing</p>
                                                                    <p className='seq-click' onClick={() => setPage('healthcare')}>Healthcare</p>
                                                                </div>
                                                                <div className='d-flex justify-content-evenly'>
                                                                    <p className='seq-click' onClick={() => setPage('pharmaceuticals')}>Pharmaceuticals</p>
                                                                    <p className='seq-click' onClick={() => setPage('retail')}>Retail</p>
                                                                    <p className='seq-click' onClick={() => setPage('technology')}>Technology</p>
                                                                    <p className='seq-click' onClick={() => setPage('government')}>Government</p>
                                                                    <p className='seq-click' onClick={() => setPage('smb')}>Small & Medium Businesses</p>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                        {/* <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block seq-ind-block-small'>
                                                                <h6>Industries</h6>
                                                                <div className='d-flex' style={{ gap: '20px' }}>
                                                                    <div>
                                                                        <p onClick={() => setPage('banking')}>Banking</p>
                                                                        <p onClick={() => setPage('finance')}>Finance</p>
                                                                        <p onClick={() => setPage('insurance')}>Insurance</p>
                                                                        <p onClick={() => setPage('manufacturing')}>Manufacturing</p>
                                                                        <p onClick={() => setPage('healthcare')}>Healthcare</p>
                                                                    </div>
                                                                    <div>
                                                                        <p onClick={() => setPage('pharmaceuticals')}>Pharmaceuticals</p>
                                                                        <p onClick={() => setPage('retail')}>Retail</p>
                                                                        <p onClick={() => setPage('technology')}>Technology</p>
                                                                        <p onClick={() => setPage('government')}>Government</p>
                                                                        <p onClick={() => setPage('smb')}>Small & Medium Businesses</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="basic-nav-dropdown3">
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown3', 'why-sequretek')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('sequretek')}>
                                                Why Sequretek&nbsp;
                                                <img src={active === 'why-sequretek' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('why-sequretek')} onMouseLeave={() => setActive('')}>
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info d-flex'>
                                                            <div style={{ width: '481px' }}>
                                                                <div className='seq-nav-info-block'>
                                                                    <div className=''>
                                                                        <div style={{ width: '481px' }}>
                                                                            <h6>Why Sequretek</h6>
                                                                            <p>Simplify security and arm your organization with expert insights, processes, and leading-edge technologies. </p>
                                                                            <div className='d-flex align-items-end '>
                                                                                <button className='seq-nav-learn' onClick={() => setPage('Why Sequretek')} style={{ margin: '16px 0' }}><strong>Learn more</strong></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex align-items-center nav-option-body-protection'>
                                                                        <div className='seq-box d-flex' onClick={() => handleAccordion(1)}>
                                                                            <img src='/images/shield-trust.svg' alt='' />
                                                                            <p className='seq-click'>Effortless Security</p>
                                                                        </div>
                                                                        <div className='seq-box d-flex' onClick={() => handleAccordion(2)}>
                                                                            <img src='/images/shieldcheck.svg' alt='' />
                                                                            <p className='seq-click'>Empowering Protection</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex align-items-center nav-option-body-protection'>
                                                                        <div className='seq-box d-flex' onClick={() => handleAccordion(3)}>
                                                                            <img src='/images/handshake.svg' alt='' />
                                                                            <p className='seq-click'>Unwavering Committment</p>
                                                                        </div>
                                                                        <div className='seq-box d-flex' onClick={() => setPage('award')}>
                                                                            <img src='/images/trophy-star.svg' alt='' />
                                                                            <p className='seq-click'>Awards & Recognitions</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line vertical.svg' alt='' />
                                                            <div className='seq-nav-info-block seq-ind-block'>
                                                                <h6>Solutions by industries</h6>
                                                                <div className='seq-ind-block-items d-flex'>
                                                                    <div className=''>
                                                                        <p className='seq-click' onClick={() => setPage('banking')}>Banking</p>
                                                                        <p className='seq-click' onClick={() => setPage('finance')}>Finance</p>
                                                                        <p className='seq-click' onClick={() => setPage('insurance')}>Insurance</p>
                                                                        <p className='seq-click' onClick={() => setPage('manufacturing')}>Manufacturing</p>
                                                                        <p className='seq-click' onClick={() => setPage('healthcare')}>Healthcare</p>
                                                                    </div>
                                                                    <div className=''>
                                                                        <p className='seq-click' onClick={() => setPage('pharmaceuticals')}>Pharmaceuticals</p>
                                                                        <p className='seq-click' onClick={() => setPage('retail')}>Retail</p>
                                                                        <p className='seq-click' onClick={() => setPage('technology')}>Technology</p>
                                                                        <p className='seq-click' onClick={() => setPage('government')}>Government</p>
                                                                        <p className='seq-click' onClick={() => setPage('smb')}>SMBs</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="basic-nav-dropdown4">
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown4', 'partner')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('partner')}>
                                                Partners&nbsp;
                                                <img src={active === 'partner' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('partner')} onMouseLeave={() => setActive('')} >
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block'>
                                                                <div className='d-flex' style={{ gap: '300px' }}>
                                                                    <div style={{ width: '481px' }}>
                                                                        <h6>Sequretek Partnerships</h6>
                                                                        <p>Partner with us to address your customers’ security needs and grow profitably with award-winning cybersecurity platform and products.</p>
                                                                    </div>
                                                                    <div className='d-flex align-items-end '>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('Partner')}><strong>Learn more</strong></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' />
                                                        </div>
                                                    </div>
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='d-flex align-item-start'>
                                                                <div className='d-flex seq-nav-info-block-partner'>
                                                                    <p className='mt-0 seq-click' onClick={() => setPage('program')}>Sequretek Partnership Program</p>
                                                                    <p className='seq-click' onClick={() => setPage('mssp')}>MSSP</p>
                                                                    <p className='seq-click' onClick={() => setPage('msp')}>MSP/ Reseller</p>
                                                                    <p className='seq-click' onClick={() => setPage('other')}>Other Partners</p>
                                                                    {/* <p className='seq-click' onClick={() => setPage('partner-portal')}>Become a Channel Partner </p> */}
                                                                    <p className='seq-click' onClick={() => setPage('current')}>Current Partners</p>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' />
                                                        </div>
                                                        {/* <div className='me-auto' style={{ marginLeft: 75 }}>
                                                            <div className='d-flex justify-content-start align-items-center seq-nav-info-block'>
                                                                <div>
                                                                    <h6 style={{ margin: '20px 0px 10px 0px' }} >Already a Partner?</h6>
                                                                </div>
                                                                <div>
                                                                    <button className='seq-nav-learn' onClick={() => setPage('portal-login')} style={{ padding: '8px 24px' }}><b>Log in to the Partner Portal</b>&nbsp;&nbsp;&nbsp;<img src='/images/arrow-red.svg' alt='' /></button>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="basic-nav-dropdown5">
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown5', 'resource')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('resources')}>
                                                Resources&nbsp;
                                                <img src={active === 'resource' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('resource')} onMouseLeave={() => setActive('')}>
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block '>
                                                                <div className='d-flex justify-content-sm-between'>
                                                                    <div style={{ width: '481px' }}>
                                                                        <h6>Resources</h6>
                                                                        <p>Explore resources that are contemporary, relevant, and contextual to help you stay cybersecure.</p>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <button className='seq-nav-learn' onClick={() => setPage('resources')}>Visit Resource Center</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' />
                                                        </div>
                                                        <div className='d-flex align-items-center seq-nav-info'>
                                                            <div className='seq-nav-resources'>
                                                                <div className='d-flex seq-nav-resources-block'>
                                                                    <div className='d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/report-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('Reports')}>Reports</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }} >
                                                                        <img src='/images/advisory-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('advisory')}>Threat Advisories</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/blog-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('blog')}>Blog</p>
                                                                    </div>
                                                                    <div className='col d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/case-study-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('case-study')}>Case Studies</p>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex seq-nav-resources-block'>
                                                                    <div className='d-flex' style={{ width: '245px' }} >
                                                                        <img src='/images/datasheet-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('datasheet')}>Datasheets</p>
                                                                    </div>
                                                                    <div className='col d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/event-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('event')}>Events & Webinars</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/podcast-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('podcast')}>Podcasts</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }} >
                                                                        <img src='/images/whitepaper-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('whitepaper')}>White Papers</p>
                                                                    </div>
                                                                </div>
                                                                <div className='d-flex seq-nav-resources-block'>
                                                                    <div className='d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/video-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('video')}>Videos</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }}>
                                                                        <img src='/images/demo-icon-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('demos')}>Demos</p>
                                                                    </div>
                                                                    <div className='d-flex' style={{ width: '245px' }} >
                                                                        <img src='/images/infographics-color.svg' alt='' />
                                                                        <p className='seq-click' onClick={() => setPage('infographics')}>Infographics</p>
                                                                    </div>
                                                                    {/* <div className='col d-flex' style={{ width: '245px' }}>
                                                                            <img src='/images/video-icon-color.svg' alt='' />
                                                                            <p className='seq-click' onClick={() => setPage('Reports')}>Testimonials/Glossary</p>
                                                                        </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div id="basic-nav-dropdown6">
                                            <div className="heading-text d-flex align-items-center" onMouseEnter={() => handleMouseEnter('basic-nav-dropdown6', 'company')} onMouseLeave={() => handleMouseLeave()} onClick={() => mainHeadPage('company')}>
                                                Company&nbsp;
                                                <img src={active === 'company' ? "/images/down-arr-red.svg" : "/images/down-arr-grey.svg"} alt="dropdown icon" className="dropdown-icon" />
                                            </div>
                                            {isOpen &&
                                                <div className={`seq-dropdown ${isScrolled ? 'add-to-between-scroll' : ''}`} onMouseEnter={() => setActive('company')} onMouseLeave={() => setActive('')}>
                                                    <div className='container d-flex  flex-column align-items-center justify-content-center'>
                                                        <div className='seq-nav-info'>
                                                            <div className='seq-nav-info-block-security '>
                                                                <h6>Simplifying Security</h6>
                                                                <p>Sequretek simplifies cybersecurity for enterprises. Learn about Sequretek's story, leadership, media mentions and career opportunities. </p>
                                                            </div>
                                                            <img src='/images/Line.svg' alt='' />
                                                        </div>
                                                        <div className='d-flex seq-nav-info' style={{ gap: 32 }}>
                                                            <div className='seq-nav-info-block'>
                                                                <h6>Our Story</h6>
                                                                <h5 className='seq-click' onClick={() => setPage('seq-story')}>About Us</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('leadership')}>Leadership</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('investor')}>Investors</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('diversity')}>Diversity & Inclusion</h5>
                                                            </div>
                                                            <div className='seq-nav-info-block'>
                                                                <h6>Newsroom</h6>
                                                                <h5 className='seq-click' onClick={() => setPage('press')}>Press Releases</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('media')}>Media Coverage</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('advisory')}>Threat Advisories</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('media')}>Media Kit</h5>
                                                            </div>
                                                            <div className='seq-nav-info-block'>
                                                                <h6>Careers</h6>
                                                                <h5 className='seq-click' onClick={() => setPage('careers')}>Why Join Us</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('job')}>Jobs</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('values')}>Values and Culture</h5>
                                                            </div>
                                                            <div className='seq-nav-info-block'>
                                                                <h6>Contact Us</h6>
                                                                <h5 className='seq-click' onClick={() => setPage('contact')}>Sales</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('contact')}>Partners / Resellers</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('contact')}>Support</h5>
                                                                <h5 className='seq-click' onClick={() => setPage('demo')}>Schedule a demo</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Nav>
                                </Navbar.Collapse >
                            </Navbar >
                            <Button className='seq-header-btn' variant="outline-light" style={{ width: window.innerWidth > 767 ? 224 : '', height: 44 }} onClick={() => setPage('demo')}>Schedule a demo</Button>
                        </div >
                    </div >
                </div>

                <div className='seq-header-mobile'>
                    <div className="seq-mainlogo">
                        <img className="seq-logo" src="/images/Hero-Logo.svg" alt="Hero Logo" onClick={() => navigate('/')} />
                    </div>
                    <div className="hamburger" onClick={toggleMenu}>
                        <img src={menuOpen ? "/images/X-menu-icon.svg" : "/images/hambeger-menu.svg"} alt="Menu" />
                    </div>
                </div>

                <div  >
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={error.successMessage}
                        autoHideDuration={3000}
                        onClose={snackClosed}
                    >
                        <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                            {error.messageText}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={error.successEnable}
                        onClose={() => snackClosed()}
                        message={error.messageText}
                        autoHideDuration={4000}
                    >
                        <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                            {error.messageText}
                        </Alert>
                    </Snackbar>

                    {/* <React.Suspense fallback={''}> */}
                    <Outlet />
                    {/* </React.Suspense> */}
                </div>

                {window.innerWidth > 767 ? null : <button className="go-to-top" id="goToTopBtn"> <img src='/images/top-arrow.svg' alt='' /></button>}
                {/* ######################################### Website Footer ########################### */}
                <Footer />
                <CookieConsent
                    location="bottom"
                    buttonText="Accept All Cookies"
                    onAccept={(e) => console.log(e)}
                    flipButtons
                    // cookieName="myAwesomeCookieName2"
                    containerClasses={'cookie-container'}
                    contentClasses={'content-class'}
                    buttonWrapperClasses={'button-wrapper'}
                    buttonClasses={'confirm-btn'}
                    // style={{ background: "#2B373B" }}
                    // buttonStyle={{ height: 27, background: "#1976d2", color: "#fff", fontSize: "14px" }}
                    enableDeclineButton
                    declineButtonText={'Decline Cookies'}
                >
                    <img src='/images/cookie.svg' alt='' />
                    {window.innerWidth < 767 && <br />}
                    We use cookies on our website to give you the most relevant experience by remembering your preferences and repeat visits. By clicking “Accept All”, you consent to the use of ALL the cookies.{" "}

                </CookieConsent>

                {/* ######################################### Website Mobile Menu ########################### */}

                <Drawer
                    anchor={'top'}
                    open={menuOpen}
                    onClose={toggleMenu}
                    className='seq-mobile-drawer'
                >
                    <div className='seq-header-mobile'>
                        <div className="seq-mainlogo">
                            <img className="seq-logo" onClick={() => setPage('home')} src="/images/Hero-Logo.svg" alt="Hero Logo" />
                        </div>
                        <div className="hamburger" onClick={toggleMenu}>
                            <img src={menuOpen ? "/images/X-menu-icon.svg" : "/images/hambeger-menu.svg"} alt="Menu" />
                        </div>
                    </div>
                    <nav className={`menu ${menuOpen ? 'open' : ''}`}>
                        <ul className='seq-drop-items'>
                            <li onClick={() => toggleDropdown('Platform')} className={activeDropdowns === 'Platform' ? 'active' : ''}>Platform<img className={`arrow ${activeDropdowns === 'Platform' ? 'rotate' : ''}`} src={activeDropdowns === 'Platform' ? '/images/up-arrow-red.svg' : '/images/down-arrow.svg'} alt='' />
                                {activeDropdowns === 'Platform' ? (
                                    <ul className="dropdown">
                                        <li onClick={() => setPage('perceptplatform')}>Percept Continuous Threat Exposure Management Platform (PCTEM)</li>
                                        <li onClick={() => setPage('perceptXdr')}>Percept XDR & NG SIEM</li>
                                        <li onClick={() => setPage('PerceptIdentity')}>Percept Identity</li>
                                        <li onClick={() => setPage('perceptEdr')}>Percept EDR</li>
                                        <li onClick={() => setPage('perceptManager')}>Percept Compliance Manager</li>
                                    </ul>
                                ) : null
                                }
                            </li>
                            <li onClick={() => toggleDropdown('Services')} className={activeDropdowns === 'Services' ? 'active' : ''}>Services <img className={`arrow ${activeDropdowns === 'Services' ? 'rotate' : ''}`} src={activeDropdowns === 'Services' ? '/images/up-arrow-red.svg' : '/images/down-arrow.svg'} alt='' />
                                {activeDropdowns === 'Services' ?
                                    <ul className='dropdown'>
                                        <li onClick={() => handleNavigation('collapseOne')}>Governance Risk and Compliance</li>
                                        <li onClick={() => handleNavigation('collapseTwo')}>Security Operations and Management</li>
                                        <li onClick={() => handleNavigation('collapseThree')}>Incident Response and Management</li>
                                        <li onClick={() => handleNavigation('collapseFour')}>Patch Management as a service</li>
                                    </ul> :
                                    null
                                }
                            </li>
                            <li onClick={(e) => toggleDropdown('Industries')} className={activeDropdowns === 'Industries' ? 'active' : ''}>Industries <img className={`arrow ${activeDropdowns === 'Industries' ? 'rotate' : ''}`} src={activeDropdowns === 'Industries' ? '/images/up-arrow-red.svg' : '/images/down-arrow.svg'} alt='' />
                                {activeDropdowns === 'Industries' && (
                                    <ul className='nested-dropdown'>
                                        <li onClick={() => setPage('banking')}>Banking</li>
                                        <li onClick={() => setPage('finance')}>Finance</li>
                                        <li onClick={() => setPage('insurance')}>Insurance</li>
                                        <li onClick={() => setPage('manufacturing')}>Manufacturing</li>
                                        <li onClick={() => setPage('healthcare')}>Healthcare</li>
                                        <li onClick={() => setPage('pharmaceuticals')}>Pharmaceuticals</li>
                                        <li onClick={() => setPage('retail')}>Retail</li>
                                        <li onClick={() => setPage('technology')}>Technology</li>
                                        <li onClick={() => setPage('government')}>Government</li>
                                        <li onClick={() => setPage('smb')}>Small & Medium Businesses</li>
                                    </ul>
                                )
                                }</li>
                            <li onClick={() => setPage('Why Sequretek')}>Why Sequretek</li>
                            <li onClick={() => setPage('Partner')}>Partners</li>
                            <li onClick={() => setPage('resources')}>Resources</li>
                            <li onClick={() => toggleDropdown('Company')} className={activeDropdowns === 'Company' ? 'active' : ''}>Company<img className={`arrow ${activeDropdowns === 'Company' ? 'rotate' : ''}`} src={activeDropdowns === 'Company' ? '/images/up-arrow-red.svg' : '/images/down-arrow.svg'} alt='' />
                                {activeDropdowns === 'Company' ? (
                                    <ul className='dropdown'>
                                        <li onClick={() => setPage('seq-story')}>Our Story</li>
                                        <li onClick={() => setPage('press')}>Newsroom</li>
                                        <li onClick={() => setPage('careers')}>Careers</li>
                                        <li onClick={() => setPage('contact')}>Contact Us</li>
                                    </ul>
                                ) : null
                                }
                            </li>
                        </ul>
                        <div className="seq-header-mobile-buttons">
                            <button className="seq-schedule-demo" onClick={() => setPage('demo')}>Schedule a demo</button>
                            <h6 style={{ color: '#ED0101' }} onClick={() => setPage('breach')}>Experiencing a Breach?</h6>
                            <h6>Download Threat Advisory</h6>
                            <h6 onClick={() => setPage('contact')}>Contact Us</h6>
                        </div>
                    </nav>
                </Drawer>
            </div>

        </div>
    )
}