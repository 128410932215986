import React from 'react';

//App Routes
import Dashboard from '../Components/App/Dashboard/dashboard';
import Leads from '../Components/App/Leads/leads';
import Deals from '../Components/App/Deals/deals';
import Users from '../Components/App/Users/users';
import Supports from '../Components/App/Support/support';
import Resources from '../Components/App/Resources/resources';
import ManageResources from '../Components/App/Resources/Manage-Resources/manageResource';
import ConsumeResources from '../Components/App/Dashboard/consumeResources';

//Website Routes
import Insurance from '../Components/Website/Industries/insurance';
import Banking from '../Components/Website/Industries/banking';
import Business from '../Components/Website/Industries/business';
import FinancialServices from '../Components/Website/Industries/financialServices';
import Government from '../Components/Website/Industries/government';
import HealthCare from '../Components/Website/Industries/healthCare';
import Manufacturing from '../Components/Website/Industries/manufacturing';
import Pharmaceuticals from '../Components/Website/Industries/pharmaceuticals';
import Retails from '../Components/Website/Industries/retails';
import IndustriesTechnology from '../Components/Website/Industries/Technology';
import Newsroom from '../Components/Website/newsroom';
import NewsroomArticle from '../Components/Website/newsroomArticle';
import Contactus from '../Components/Website/contactus';
import Breach from '../Components/Website/breach';
import ScheduleDemo from '../Components/Website/scheduleDemo';
import PartnerPortal from '../Components/Website/partnerPortal';
import PartnerRegistration from '../Components/Website/partnerRegistration';
import LegalPolicy from '../Components/Website/legalPolicy';
import CookiePolicy from '../Components/Website/cookiePolicy';
import PrivacyPolicy from '../Components/Website/privacyPolicy';
import VDP from '../Components/Website/vdp';
import PerceptPlatform from '../Components/Website/perceptPlatform';
import PerceptXdr from '../Components/Website/Products/perceptXdr';
import PerceptIdentity from '../Components/Website/Products/perceptIdentity';
import PerceptManager from '../Components/Website/Products/perceptManager';
import PerceptEdr from '../Components/Website/Products/perceptEdr';
import Services from '../Components/Website/services';
import Careers from '../Components/Website/careers';
import Whysequretek from '../Components/Website/why-sequretek';
import Story from '../Components/Website/our-story';
import Partners from '../Components/Website/partners';
import ResourcesCenter from '../Components/Website/Resources/resourcesCenter';
import EventWebinars from '../Components/Website/Resources/eventWebinars';
import EventWebinars1 from '../Components/Website/Resources/eventWebinars1';
import CaseStudies from '../Components/Website/Resources/caseStudies';
import Videos from '../Components/Website/Resources/videos';
import Reports from '../Components/Website/Resources/reports';
import Podcasts from '../Components/Website/Resources/podcast';
import Demos from '../Components/Website/Resources/demos';
import Factsheet from '../Components/Website/Resources/factsheet';
import DataSheets from '../Components/Website/Resources/datasheets';
import Infographics from '../Components/Website/Resources/infographics';
import ThreatAdvisory from '../Components/Website/Resources/threatAdvisory';
import Whitepapers from '../Components/Website/Resources/whitepapers';
import Blogs from '../Components/Website/Resources/blogs';
import Blogs1 from '../Components/Website/Resources/blogs1';
import Blogs2 from '../Components/Website/Resources/blogs2';
import Blogs3 from '../Components/Website/Resources/blogs3';
import Blogs4 from '../Components/Website/Resources/blogs4';
import Blogs5 from '../Components/Website/Resources/blogs5';
import Blogs6 from '../Components/Website/Resources/blogs6';
import Blogs7 from '../Components/Website/Resources/blogs7';
import Blogs8 from '../Components/Website/Resources/blogs8';
import Blogs9 from '../Components/Website/Resources/blogs9';
import Blogs10 from '../Components/Website/Resources/blogs10';
import Blogs11 from '../Components/Website/Resources/blogs11';
import Blogs12 from '../Components/Website/Resources/blogs12';
import Blogs13 from '../Components/Website/Resources/blogs13';
import Blogs14 from '../Components/Website/Resources/blogs14';
import Blogs15 from '../Components/Website/Resources/blogs15';
import Blogs16 from '../Components/Website/Resources/blogs16';
import Blogs17 from '../Components/Website/Resources/blogs17';
import Blogs18 from '../Components/Website/Resources/blogs18';
import Blogs19 from '../Components/Website/Resources/blogs19';
import Blogs20 from '../Components/Website/Resources/blogs20';
import Blogs21 from '../Components/Website/Resources/blogs21';
import Blogs22 from '../Components/Website/Resources/blogs22';
import Blogs23 from '../Components/Website/Resources/blogs23';
import Blogs24 from '../Components/Website/Resources/blogs24';
import Blogs25 from '../Components/Website/Resources/blogs25';

const Home = React.lazy(() => import('../Components/Website/home'));

const routeItems = [
    { path: '/app/dashboard', component: <Dashboard />, layout: 'mainLayout' },
    { path: '/app/leads', component: <Leads />, layout: 'mainLayout' },
    { path: '/app/deals', component: <Deals />, layout: 'mainLayout' },
    { path: '/app/supports', component: <Supports />, layout: 'mainLayout' },
    { path: '/app/resources', component: <Resources />, layout: 'mainLayout' },
    { path: '/app/resources/topics', component: <ManageResources />, layout: 'mainLayout' },
    { path: '/app/users', component: <Users />, layout: 'mainLayout' },
    { path: '/app/consumed-resources', component: <ConsumeResources />, layout: 'mainLayout' },

    //Website page
    { path: '/', component: <Home />, layout: 'webLayout' },
    { path: '/partner-portal-login', component: <PartnerPortal />, layout: 'webLayout' },
    { path: '/partner-registration', component: <PartnerRegistration />, layout: 'webLayout' },
    { path: '/industries/insurance-cybersecurity', component: <Insurance />, layout: 'webLayout' },
    { path: '/industries/cybersecurity-for-banks', component: <Banking />, layout: 'webLayout' },
    { path: '/industries/smb-cybersecurity-solutions', component: <Business />, layout: 'webLayout' },
    { path: '/industries/financial-institution-cybersecurity', component: <FinancialServices />, layout: 'webLayout' },
    { path: '/industries/cybersecurity-for-government', component: <Government />, layout: 'webLayout' },
    { path: '/industries/healthcare-cybersecurity', component: <HealthCare />, layout: 'webLayout' },
    { path: '/industries/manufacturing-cybersecurity', component: <Manufacturing />, layout: 'webLayout' },
    { path: '/industries/pharma-data-protection', component: <Pharmaceuticals />, layout: 'webLayout' },
    { path: '/industries/retail-cybersecurity', component: <Retails />, layout: 'webLayout' },
    { path: '/industries/technology-cybersecurity', component: <IndustriesTechnology />, layout: 'webLayout' },
    { path: '/latest-news', component: <Newsroom />, layout: 'webLayout' },
    { path: '/newsroom/article', component: <NewsroomArticle />, layout: 'webLayout' },
    { path: '/contactus', component: <Contactus />, layout: 'webLayout' },
    { path: '/experiencing-breach', component: <Breach />, layout: 'webLayout' },
    { path: '/schedule-a-demo', component: <ScheduleDemo />, layout: 'webLayout' },
    { path: '/sequretek-percept-platform', component: <PerceptPlatform />, layout: 'webLayout' },
    { path: '/products/extended-detection-and-response-and-ngsiem', component: <PerceptXdr />, layout: 'webLayout' },
    { path: '/products/identity-and-access-governance', component: <PerceptIdentity />, layout: 'webLayout' },
    { path: '/products/compliance-manager', component: <PerceptManager />, layout: 'webLayout' },
    { path: '/products/endpoint-detection-and-response', component: <PerceptEdr />, layout: 'webLayout' },
    { path: '/services', component: <Services />, layout: 'webLayout' },
    { path: '/join-our-team', component: <Careers />, layout: 'webLayout' },
    { path: '/sequretek-benefits', component: <Whysequretek />, layout: 'webLayout' },
    { path: '/sequretek-story', component: <Story />, layout: 'webLayout' },
    { path: '/resources-hub', component: <ResourcesCenter />, layout: 'webLayout' },
    { path: '/resources/events-webinars1', component: <EventWebinars1 />, layout: 'webLayout' },
    { path: '/resources/upcoming-events-webinars', component: <EventWebinars />, layout: 'webLayout' },
    { path: '/resources/video-library/:title', component: <Videos />, layout: 'webLayout' },
    { path: '/resources/report/:title', component: <Reports />, layout: 'webLayout' },
    { path: '/resources/podcasts/:title', component: <Podcasts />, layout: 'webLayout' },
    { path: '/resources/live-product-demos/:title', component: <Demos />, layout: 'webLayout' },
    { path: '/resources/blog', component: <Blogs />, layout: 'webLayout' },
    { path: '/resources/case-study/:title', component: <CaseStudies />, layout: 'webLayout' },
    { path: '/resources/datasheet/:title', component: <DataSheets />, layout: 'webLayout' },
    { path: '/resources/infographics/:title', component: <Infographics />, layout: 'webLayout' },
    { path: '/resources/threat-advisories/:title', component: <ThreatAdvisory />, layout: 'webLayout' },
    { path: '/resources/sequretek-white-paper/:title', component: <Whitepapers />, layout: 'webLayout' },
    { path: '/resources/factsheet/:title', component: <Factsheet />, layout: 'webLayout' },
    { path: '/cookie-policy', component: <CookiePolicy />, layout: 'webLayout' },
    { path: '/cybersecurity-solution-provider-partner', component: <Partners />, layout: 'webLayout' },
    { path: '/vulnerability-disclosure-policy', component: <VDP />, layout: 'webLayout' },
    { path: '/legal-policy', component: <LegalPolicy />, layout: 'webLayout' },
    { path: '/privacy-policy', component: <PrivacyPolicy />, layout: 'webLayout' },


    { path: `/resources/blog/${encodeURIComponent('secure-online-shopping')}`, component: <Blogs1 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('living-off-the-land-attacks')}`, component: <Blogs2 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('data-security-solutions')}`, component: <Blogs3 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('adaptive-strategies-of-cybercrime-groups')}`, component: <Blogs4 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('spyloan-may-not-be-so-forgiving')}`, component: <Blogs5 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('prevent-vpn-vulnerabilities-secure-business')}`, component: <Blogs6 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('sandworm-cyberattacks')}`, component: <Blogs7 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('hidden-threat-javascript-malware')}`, component: <Blogs8 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('akira-ransomware-tactics')}`, component: <Blogs9 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('ssd-for-faster-loading-speeds')}`, component: <Blogs10 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('technical-analysis-of-a-malicious-android-app')}`, component: <Blogs11 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('analysis-of-malicious-android-app-targeting-financial-information')}`, component: <Blogs12 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('trojan-msil-lovgate-analysis')}`, component: <Blogs13 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('lockbit-3-0-analysis')}`, component: <Blogs14 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('analysis-of-a-phishing-campaign')}`, component: <Blogs15 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('analysis-of-chaos-ransomware')}`, component: <Blogs16 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('kutaki-stealer-analysis')}`, component: <Blogs17 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('technical-analysis-of-darkbit-ransomware')}`, component: <Blogs18 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('fake-tax-filing-app-analysis')}`, component: <Blogs19 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('cyber-security-needs-a-paradigm-shift')}`, component: <Blogs20 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('cybersecurity-faces-new-era-artificial-xnuwf')}`, component: <Blogs21 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('Ransomware-as-a-Service-Groups-Are-Serving-Quick-Hits')}`, component: <Blogs22 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('Protecting-Your-Code-Learning-from-Software-Supply-Chain-Breaches')}`, component: <Blogs23 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('Cryptominers-Arent-Fools-Gold')}`, component: <Blogs24 />, layout: 'webLayout' },
    { path: `/resources/blog/${encodeURIComponent('The-Growing-Threat-of-Fileless-Malware')}`, component: <Blogs25 />, layout: 'webLayout' },






]

export default routeItems