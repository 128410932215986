// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import Select, { components } from 'react-select';


// ############################ Require files here ###############################

import '../Leads/leads.scss';
import Paginations from '../../../utils/pagination';
import AddTicket from './addTicket';
import { convertDate } from '../../../utils/miscellneous';
import { TopicLoader } from '../../../Loader/loader';
import TicketDetails from './ticketDetails';
import { getTicketList, addTicket, deleteTicket, getReplies, replySent, ticketClosed, } from '../../../Redux/ActionCreators/supportAction';
import ConfirmModal from '../../../utils/confirmModal';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' width={18} alt='' />
        </components.DropdownIndicator>
    );
};

export default function Supports() {
    const [isAdd, setIsAdd] = useState(false);
    const [query, setQuery] = useState('');
    const [isView, setIsView] = useState(false);
    const [page, setPage] = useState(1);
    const [category, setCategory] = useState(null);
    const [status, setStatus] = useState(null);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [supportDetails, setSupportDetails] = useState({
        category: '',
        question: '',
        attachment: '',
        doc_shared: false
    })
    const { isLoading, ticketList, totalPage, categories, Statuses, replies } = useSelector(state => state.supports);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getTicketList(1, '', '', ''))
    }, [])

    const userAction = () => {
        setSupportDetails({
            category: '',
            question: '',
            attachment: '',
            doc_shared: false
        })
        setIsAdd(true);
    }

    // Define the form submission handler
    const handleSubmit = async (values) => {
        const data = new FormData();
        data.append('type', values?.category);
        data.append('question', values?.question);
        values?.attachment && data.append('payload', values?.attachment);
        data.append('doc_shared', values?.doc_shared)
        const res = await dispatch(addTicket(data));
        if (res?.type === 'ADD_TICKET') {
            setIsAdd(false);
            setIsView(false)
            dispatch(getTicketList(1, '', '', ''))
        }
    };

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
        dispatch(getTicketList(pageNumber, '', category?.value, status?.value));
    };

    const selectCategory = async (e) => {
        setCategory(e);
        setQuery('');
        setPage(1);
        await dispatch(getTicketList(1, '', e?.value, status?.value));
    }

    const selectStatus = async (e) => {
        setStatus(e);
        setQuery('');
        setPage(1);
        await dispatch(getTicketList(1, '', category?.value, e?.value));
    }

    const openView = async (el) => {
        setIsView(true);
        setIsAdd(false);
        setSupportDetails(el);
        await dispatch(getReplies(el._id));
    }


    const openDelete = (el) => {
        setDeleteId(el._id);
        setIsConfirm(true);
    }


    const removeConfirm = async () => {
        const res = await dispatch(deleteTicket(deleteId));
        if (res?.type === 'DELETE_TICKET') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }

    return (
        <div className='leads-holder py-2 py-md-2 me-3'>
            {/* SUTTORT FORM */}
            {(!isAdd && !isView) ?
                <Fragment>
                    <div>
                        <h2 className="d-flex align-items-center justify-content-between">
                            Support
                            <div className='leads-btn'>
                                <button style={{ visibility: 'hidden' }}>Export </button>
                                <button onClick={userAction}><img src='/images/plus-white.svg' alt='' /> &nbsp;&nbsp; Add ticket </button>
                            </div>
                        </h2>
                    </div>
                    <div className='users-table-holder'>
                        <div className='users-header flex-wrap'>
                            <div className='user-input'>
                                <div className="has-search"  >
                                    <span className="form-control-feedback">
                                        <img src="/images/search-icon.svg" alt='' width={15} />
                                    </span>
                                    <input type='search' placeholder='Search By Name' className='form-control' value={query} onChange={(e) => setQuery(e.target.value)} />
                                </div>
                            </div>
                            <div className='select-group mt-2 w-100'>

                                <div>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={categories}
                                        isClearable
                                        onChange={selectCategory}
                                        placeholder='By Type'
                                        value={category}
                                    />
                                </div>
                                <div className='ms-3'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={Statuses}
                                        isClearable
                                        onChange={selectStatus}
                                        placeholder='By Status'
                                        value={status}
                                    />
                                </div>
                            </div>

                        </div>
                        {/* USER TABLE */}
                        <div className='users-table'>
                            <Table responsive >
                                <thead>
                                    <tr className='table-header'>
                                        <th className='text-center'>Ticket Id</th>
                                        <th className='text-center'>Ticket   </th>
                                        <th className='text-center'>From</th>
                                        <th className='text-center'>Category</th>
                                        <th className='text-center'>Status</th>
                                        <th className='text-center'>Added Date</th>
                                        <th className='text-center'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <tr>
                                            <td colSpan={6}> <TopicLoader /> </td>
                                        </tr>
                                        :
                                        ticketList?.length > 0 ?
                                            ticketList?.map((el, i) => {
                                                return (
                                                    <tr>
                                                        <td className='text-center'>
                                                            {el.ticket_id}
                                                        </td>

                                                        <td className='text-center ' style={{ width: '35%' }}>
                                                            <h6 className='elipsis text-left'>
                                                                {el.question}
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            {el.from?.fname}  {el.from?.lname}
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-center align-items-center'>
                                                                <div className='product text-capitalize'>
                                                                    {el.type}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{el.closed ? 'Closed' : 'In Progress'}</td>
                                                        <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                        <td className='cursor-pointer text-center'>
                                                            <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openView(el)} />&nbsp;&nbsp;
                                                            {localStorage.getItem('role').includes('admin') && <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => openDelete(el)} />}
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan={6}>
                                                    <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                            {totalPage > 1 ?
                                <div className='d-flex justify-content-end me-3'>
                                    <Paginations className={'custom-pagination'} totalPages={totalPage} onPageChange={handlePageChange} />
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </Fragment> :
                isView ?
                    <Fragment>
                        <TicketDetails
                            setIsView={() => {
                                setIsView(false)
                                setIsAdd(false)
                            }}
                            supportDetails={supportDetails}
                            categories={categories}
                            handleSubmit={handleSubmit}
                            replies={replies}
                            replySent={async (obj) => {
                                await dispatch(replySent(obj));
                                await dispatch(getReplies(supportDetails?._id))
                            }}
                            ticketClosed={async (obj) => {
                                await dispatch(ticketClosed(obj));
                                await setSupportDetails({ ...supportDetails, closed: true })
                            }}

                        />
                    </Fragment> :
                    <Fragment>
                        <AddTicket
                            setIsAdd={() => setIsAdd(false)}
                            supportDetails={supportDetails}
                            categories={categories}
                            handleSubmit={handleSubmit}
                        />
                    </Fragment>
            }


            {/* ################################## Confirmation Modal ################################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                type='ticket'
                removeConfirm={removeConfirm}
            />
        </div>
    )
}
