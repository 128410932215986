//errors
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_ERRORS = 'GET_ERRORS';

//Login
export const GENERATE_OTP = 'GENERATE_OTP';
export const USER_LOGIN = 'USER_LOGIN';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

//Sidebar
export const GET_MENUS = 'GET_MENUS';

//Dashboard
export const RESET_DASHBOARD_DATA = 'RESET_DASHBOARD_DATA';
export const GET_TICKET_METRICS = 'GET_TICKET_METRICS';
export const GET_LEADS_DETAILS = 'GET_LEADS_DETAILS';
export const GET_LOGIN_TREND = 'GET_LOGIN_TREND';
export const GET_RESOURCE_TREND = 'GET_RESOURCE_TREND';
export const GET_RECENT_LEAD = 'GET_RECENT_LEAD';
export const GET_RECENT_DEAL = 'GET_RECENT_DEAL';
export const GET_RECENT_TICKET = 'GET_RECENT_TICKET';
export const GET_RECENT_CONTENT = 'GET_RECENT_CONTENT';
export const GET_CONSUMED_CONTENT = 'GET_CONSUMED_CONTENT';
export const GET_NEXT_CONSUMED_CONTENT = 'GET_NEXT_CONSUMED_CONTENT';
//Users
export const RESET_LISTS = 'RESET_LISTS';
export const GET_ROLES = 'GET_ROLES';
export const GET_SEQUERTEK_USERS = 'GET_SEQUERTEK_USERS';
export const ADD_USER = 'ADD_USER';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const GET_PARTNERS = 'GET_PARTNERS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const ADD_PARTNER = 'ADD_PARTNER';
export const GET_PARTNER_USERS = 'GET_PARTNER_USERS';


//Leads
export const GET_ALL_PARTNERS = 'GET_ALL_PARTNERS';
export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_COMPANIES = 'GET_ALL_COMPANIES';
export const GET_ALL_LEADS = 'GET_ALL_LEADS';
export const ADD_LEAD = 'ADD_LEAD';
export const EDIT_LEAD = 'EDIT_LEAD';
export const DELETE_LEAD = 'DELETE_LEAD';


//DEALS
export const GET_ALL_DEALS = 'GET_ALL_DEALS';
export const ADD_DEAL = 'ADD_DEAL';
export const EDIT_DEAL = 'EDIT_DEAL';
export const DELETE_DEAL = 'DELETE_DEAL';

//Resources
export const RESET_LIST = 'RESET_LIST';
export const GET_ALL_CATEGORY = 'GET_ALL_CATEGORY';
export const SEARCH_CATEGORY = 'SEARCH_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const CHANGE_CATEGORY_STATUS = 'CHANGE_CATEGORY_STATUS';
export const DELETE_CONTENT = 'DELETE_CONTENT';
export const GET_TYPE_COUNT = 'GET_TYPE_COUNT';
export const EDIT_TYPE = 'EDIT_TYPE';
export const GET_RESOURCES = 'GET_RESOURCES';
export const GET_NEXT_RESOURCES = 'GET_NEXT_RESOURCES';
export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_TOPICS = 'GET_TOPICS';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_MIXED_RESOURCE = 'GET_MIXED_RESOURCE';
export const GET_CATEGORY_RESOURCES = 'GET_CATEGORY_RESOURCES';
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const ADD_CATEGORY_RESOURCE = 'ADD_CATEGORY_RESOURCE';
export const REMOVE_CATEGORY_RESOURCE = 'REMOVE_CATEGORY_RESOURCE';
export const ANALYTIC_CAPTURE = 'ANALYTIC_CAPTURE';

//website resources
export const RESET_RESOURCE_LOADER = 'RESET_RESOURCE_LOADER';
export const GET_EXTERNAL_RESOURCES = 'GET_EXTERNAL_RESOURCES';
export const GET_EXTERNAL_INDUSTRIES = 'GET_EXTERNAL_INDUSTRIES';
export const GET_EXTERNAL_TOPICS = 'GET_EXTERNAL_TOPICS';
export const GET_EXTERNAL_TYPES = 'GET_EXTERNAL_TYPES';
export const GET_RELATED_RESOURCES = 'GET_RELATED_RESOURCES';
export const GET_RESOURCE_DATA = 'GET_RESOURCE_DATA';
//tickets
export const GET_ALL_TICKETS = 'GET_ALL_TICKETS';
export const ADD_TICKET = 'ADD_TICKET';
export const DELETE_TICKET = 'DELETE_TICKET';
export const GET_REPLIES = 'GET_REPLIES';
export const ADD_REPLY = 'ADD_REPLY';
export const CLOSE_TICKET = 'CLOSE_TICKET';