import { deleteApiCall, getApiCall, postApiCall, putApiCall } from "../../utils/requests";
import { returnErrors } from '../ActionCreators/errorAction';
import {
    GET_ALL_DEALS, ADD_DEAL, EDIT_DEAL, DELETE_DEAL
} from "../Types/types";

// This function was an api call for the Deals Export Data in Deals page on partner portal 

export const getDealsExportData = () => async (dispatch) => {
    try {
        const response = await getApiCall(`deals/export`);
        if (response.status === 200) {
            return dispatch({ type: 'DEAL_EXPORT_DATA', payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Deals Data in Deals page on partner portal 

export const getDeals = (page, size, query, partnerId, productId, companyId, status) => async (dispatch) => {
    try {
        const response = await getApiCall(`deals?size=${size}&page=${page}&search=${query}&partner_id=${partnerId ? partnerId : ''}&product_id=${productId ? productId : ''}&company_id=${companyId ? companyId : ''}&status=${status ? status : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_ALL_DEALS, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an post api call for the Add Deals in Deals page on partner portal 

export const addDeal = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`deals`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Deal has been added', response.status);
            return dispatch({ type: ADD_DEAL, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Update Deals Data in Deals page on partner portal 

export const updateDeal = (obj) => async (dispatch) => {

    try {
        const response = await putApiCall(`deals`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Deal has been updated', response.status);
            return dispatch({ type: EDIT_DEAL, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an delete api call for the Delete Deals Data in Deals page on partner portal 

export const deletedDeal = (id) => async (dispatch) => {
    let obj = {
        _id: id
    }
    try {
        const response = await deleteApiCall(`deals`, obj);
        if (response.status === 200) {
            returnErrors(dispatch, 'Deal has been deleted', response.status)
            return dispatch({ type: DELETE_DEAL, payload: id });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}