// ############################ Require packages here ############################
import React, { lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';
import Aos from 'aos';
import { useDispatch } from 'react-redux';
// ############################ Require files here ###############################

import './product.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const PerceptIdentitycards = [
    { image: '/images/shield-bgcross.svg', title: 'Enhanced Security', description: 'Zero Trust Identity reduces the attack surface against many threat types, rogue users & devices, unknown networks.' },
    { image: '/images/journey.svg', title: 'User Life Cycle Management', description: 'Life cycle management for all types of users to ensure appropriate access rights, meet compliance, and access certifications.' },
    { image: '/images/process.svg', title: 'Operational Efficiency', description: 'Streamlines joiner-mover-leaver management processes with granular and scalable workflows, reducing administrative burdens and TCO.' },
    { image: '/images/team-check.svg', title: 'Vendor Agnostic', description: 'No-code connector studio allows coexistence with and complements existing technology, both cloud and on-premises.' },
    { image: '/images/user-key-blue.svg', title: 'Automated Access Management', description: 'Increase productivity and efficiency by automation, retain audit trails, and reduce risk by eliminating manual errors.' },
    { image: '/images/plug-connection.svg', title: 'Application and Services Integration', description: 'Seamlessly integrate services, industry-standard applications and identity stores through a rich connector studio framework.' },
    { image: '/images/umbrella.svg', title: 'Risk Mitigation', description: 'AI-powered big data analytics helps in access modeling, user behavioral analytics, reports and dashboards for a reduced-risk environment.' },
    { image: '/images/compliance.svg', title: 'Out-of-the-Box Compliance Reporting', description: 'Meets regulatory mandates through automated reports, access reviews, audit trails, reducing non-compliance costs and audit risks.' },
    { image: '/images/user-gear.svg', title: 'Improved User Experience', description: `Provides a self-service console for employees' access requests, offering faster access to necessary resources and reducing downtime.` },
    { image: '/images/shield-trust.svg', title: 'Governance and Compliance', description: 'Ensures consistent enforcement of identity and access governance policies, enhancing compliance and control over manual processes.' },
    { image: '/images/ai-chip.svg', title: 'AI-Powered Identity Intel', description: 'Obtains up-to-date actionable identity and account-related threat information, intelligence, and transformational insights.' },
    { image: '/images/shield-security-risk.svg', title: 'Risk Profiling', description: 'Proactively identifies, alerts and mitigates insider risks arising from unentitled active users, third-party and admin accounts.' },
]


const Graphitems = [
    { image: '/images/shieldplus.svg', title: 'Identity & Access Orchestration' },
    { image: '/images/shieldplus.svg', title: 'Identity & Access Management and Governance' },
    { image: '/images/shieldplus.svg', title: 'Identity & Access Compliance and Risk Management ' },
    { image: '/images/shieldplus.svg', title: 'Identity & Access Attack Surface Management' },
    { image: '/images/shieldplus.svg', title: 'Zero Trust Identity and Access' },
    { image: '/images/shieldplus.svg', title: 'User Access Recertification' },
    { image: '/images/shieldplus.svg', title: 'Dormant Account Management' },
    { image: '/images/shieldplus.svg', title: 'Password Management' },
    { image: '/images/shieldplus.svg', title: 'AI-Powered Percept Identity' },
    { image: '/images/shieldplus.svg', title: 'Adaptive MFA & SSO' },
    { image: '/images/shieldplus.svg', title: 'Access Data Lake with User Behavior Analytics' },
    { image: '/images/shieldplus.svg', title: 'Role-Based Access Control' },
    { image: '/images/shieldplus.svg', title: 'Segregation of Duties' },
    { image: '/images/shieldplus.svg', title: 'Least Privilege Access' },
    { image: '/images/shieldplus.svg', title: 'Privilege Identity Management' },
    { image: '/images/shieldplus.svg', title: 'Privilege Access Management' },
    { image: '/images/shieldplus.svg', title: 'Customizable Workflows and Approvals' },
    { image: '/images/shieldplus.svg', title: 'Cross-Platform Integration' },
    { image: '/images/shieldplus.svg', title: 'Streamlined Delegation' },
    { image: '/images/shieldplus.svg', title: 'Compliance Reports' },
    { image: '/images/shieldplus.svg', title: 'Enterprise Identity Stores Integration' }


]

const PerceptIdentity = () => {
    const active = 'PerceptIdentity';
    const [secNav, setSecNav] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Percept-about')?.offsetTop;

        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }


    const toggleNav = () => {
        setSecNav(!secNav)
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const setActive = (val) => {
        window.scrollTo(0, 0);
        if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptManager') {
            navigate('/products/compliance-manager')
        } else if (val === 'products') {
            window.innerWidth > 767 ? window.scrollTo(0, 5800) : window.scrollTo(0, 4200);
        } else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadDatasheet = async () => {
        const res = await dispatch(getOneResource(['66e2ed93ab9b5744e2b16d92']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/datasheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }
    return (
        <div className='seq-Percept-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Federated Identity Governance & Zero Trust Identity and Access</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Ensure secure access with Federated Identity Governance and Zero Trust. Protect identities, enhance governance, and maintain compliance with robust access controls." />
                <meta name="keywords" content="Identity governance and administration, Access management, Zero trust, Cloud Identity Security" />
                <meta property="og:title" content="Sequretek | Federated Identity Governance & Zero Trust Identity and Access" />
                <meta property="og:description" content="Ensure secure access with Federated Identity Governance and Zero Trust. Protect identities, enhance governance, and maintain compliance with robust access controls." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%),url('/images/bg-img20.png')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero d-flex justify-content-center'>
                        <div className='seq-Percept-hero-identity' data-aos="fade-up">
                            <h3 className='seq-Percept-hero-identity-head d-flex justify-content-center pb-0'>Overcome Your Identity & Access Governance Issues</h3>
                            <p className='seq-Percept-hero-identity-text'>Federated, Simplified, and Enterprise-Scale Zero Trust Identity & Governance Solution</p>
                            {window.innerWidth > 767 ?
                                <div className='d-flex  justify-content-center'>
                                    <button onClick={() => setActive('products')}><strong>Get Percept Identity</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div> :
                                <div className='text-align-center'>
                                    <button onClick={() => setActive('products')}><strong>Get Percept Identity</strong></button>
                                    <button className='seq-Percept-hero-identity-btn' onClick={() => downloadDatasheet()}><strong>Download Datasheet</strong></button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            {/* section 2 */}
            <div className='seq-Percept-tab-pages'>
                {/* section 2-1 SECOND NAVBAR*/}
                {window.innerWidth > 767 ?
                    <div>
                        <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                            <div onClick={() => setActive('perceptplatform')} className={active === 'perceptplatform' ? 'seq-tab active' : 'seq-tab'}>
                                About Percept Platform
                            </div>
                            <div onClick={() => setActive('perceptXdr')} className={active === 'perceptXdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept XDR & NG SIEM
                            </div>
                            <div onClick={() => setActive('PerceptIdentity')} className={active === 'PerceptIdentity' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Identity
                            </div>
                            <div onClick={() => setActive('perceptEdr')} className={active === 'perceptEdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept EDR
                            </div>
                            <div onClick={() => setActive('perceptManager')} className={active === 'perceptManager' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Compliance Manager
                            </div>
                        </div>
                    </div>
                    :
                    <div className='secondary-header'>
                        <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                            <div className="seq-active d-flex align-items-center ">
                                <h4>Percept Identity</h4>
                            </div>
                            <div className="dropdown d-flex" onClick={toggleNav} >
                                <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" loading="lazy" />
                            </div>
                        </div>
                        <nav className={`menu ${secNav ? 'open' : ''}`}>
                            <ul className='seq-drop-items'>
                                <li onClick={() => setActive('perceptXdr')}>Percept XDR and NGSIEM</li>
                                <li onClick={() => setActive('perceptplatform')}>About Percept Platform</li>
                                <li onClick={() => setActive('perceptEdr')}>Percept EDR</li>
                                <li onClick={() => setActive('perceptManager')}>Percept Compliance Manager</li>
                            </ul>
                        </nav>
                    </div>
                }
                {/* section 3 PLAYER */}
                <div className='seq-Percept-about'>
                    <div className='container pe-0 ps-0' data-aos="fade-up">
                        <div className='d-flex justify-content-center seq-Percept-player' >
                            <ReactPlayer url='https://youtu.be/eiWLaQCjugI ' className='seq-Percept-about-player' width='1128px' height='595px' />
                        </div>
                    </div>
                    {/* Img Session */}
                    <div className='seq-benifits-img'>
                        <div className='container pe-0 ps-0'>
                            <div className='d-flex justify-content-center'><h4>PERCEPT IDENTITY</h4></div>
                            {window.innerWidth > 767 ?
                                <img className='seq-percept-main-img' src='/images/PID Diag.svg' alt='' loading="lazy" />
                                :
                                <div data-aos="fade-up">
                                    {/* accordion one */}
                                    <div className='seq-identity-accordionone'>
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Identity and Access Orchestration</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>No code Connector Studio (vast library of existing connecters)</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Granular & scalable workflows</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Seamless services integration</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Streamlined delegation</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Cross-platform integration</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Identity and Access Management & Governance</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>User access life cycle management</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Granular/Enterprise-wide user access review</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Centralized access management </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Access relationship management (RBAC Matrix)</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Service accounts protection </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Dormant account management</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Identity and Access Compliance & Risk Management</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>User access certification </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Role-based access certification </p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Multi-cloud entitlement management</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Password management</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Compliance reporting</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Zero Trust Identity and Access</div>
                                                    </button>
                                                </h2>
                                                <div id="collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Single sign-on</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Adaptive MFA</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Privileged identity management</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Privileged access management</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Segregation of duties</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Least privilege access</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* TABLE */}
                                    <div className='seq-identity-table'>
                                        <div className='seq-identity-table-head d-flex align-items-center justify-content-center'><h3 className=''>AI-Powered Percept Identity</h3>
                                        </div>
                                        <Table responsive>
                                            <tr>
                                                <th>
                                                    <div className='d-flex align-items-center justify-content-center'><img src='/images/system-cloud-color.svg' alt='' loading="lazy" /></div>
                                                    <p>Federated,  simplified, enterprise-scale</p>
                                                </th>
                                                <th>
                                                    <div className='d-flex align-items-center justify-content-center'><img src='/images/lightbulb-on-color.svg' alt='' loading="lazy" /></div>
                                                    <p>Transformational insights </p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <div className='d-flex align-items-center justify-content-center'><img src='/images/objects-column.svg' alt='' loading="lazy" /></div>
                                                    <p>Access data lake with user behavior analytics</p>
                                                </th>
                                                <th>
                                                    <div className='d-flex align-items-center justify-content-center'><img src='/images/windows.svg' alt='' loading="lazy" /></div>
                                                    <p>Coexist and complement</p>
                                                </th>
                                            </tr>

                                        </Table>
                                    </div>
                                    {/* GIF */}
                                    <div className='seq-identity-gif d-flex'>
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                    </div>
                                    {/* accordion two */}
                                    <div className='seq-identity-accordiontwo'>
                                        <div class="accordion" id="accordionExampletwo">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="true" aria-controls="collapsefive">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Identity and Access Attack Surface</div>
                                                    </button>
                                                </h2>
                                                <div id="collapsefive" class="accordion-collapse collapse" data-bs-parent="#accordionExampletwo">
                                                    <div class="accordion-body">
                                                        <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '30px' }}>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/employees.svg' style={{ width: '24px', height: '24px', marginLeft: '5px' }} alt='' loading="lazy" /></div>
                                                                <div style={{ width: '180px', marginLeft: '15px' }}>
                                                                    <h6>Users</h6>
                                                                    <p>Full-time, contract, part-time, vendor employees</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/house-lock-color.svg' style={{ width: '24px', height: '24px' }} alt='' loading="lazy" /></div>
                                                                <div style={{ width: '180px', marginLeft: '15px' }}>
                                                                    <h6>Enterprise Identity Stores</h6>
                                                                    <p>Enterprise ID/AAD/AD, cloud/SaaS, legacy, and app-specific ID Stores</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/cloud-gear-automation.svg' alt='' style={{ width: '24px', height: '24px' }} loading="lazy" /></div>
                                                                <div style={{ width: '280px', marginLeft: '20px' }}>
                                                                    <h6>Resources</h6>
                                                                    <p>HR/HR SaaS systems, cloud/SaaS/on-prem apps, case management systems, services, privilege environments</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ border: '1px solid #0E49B5' }} />
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesix" aria-expanded="false" aria-controls="collapsesix">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Identity and Access Risks</div>
                                                    </button>
                                                </h2>
                                                <div id="collapsesix" class="accordion-collapse collapse" data-bs-parent="#accordionExampletwo">
                                                    <div class="accordion-body">
                                                        <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '20px' }}>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/process.svg' style={{ width: '24px', height: '24px', marginLeft: '5px' }} alt='' loading="lazy" /></div>
                                                                <div style={{ width: '150px', marginLeft: '15px' }}>
                                                                    <p>Error-prone/ Manual user life cycle management process</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/low-vision.svg' style={{ width: '24px', height: '24px' }} alt='' loading="lazy" /></div>
                                                                <div style={{ width: '150px', marginLeft: '15px' }}>
                                                                    <p>Lack of compliance visibility</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/user-key-blue.svg' alt='' style={{ width: '24px', height: '24px' }} loading="lazy" /></div>
                                                                <div style={{ width: '150px', marginLeft: '15px' }}>
                                                                    <p>Lack of consistent access to on-prem and cloud apps</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/rotate-square.svg' alt='' style={{ width: '24px', height: '24px' }} loading="lazy" /></div>
                                                                <div style={{ width: '150px', marginLeft: '15px' }}>
                                                                    <p>Keeping app integrations up to date</p>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='d-flex align-items-center'><img src='/images/admin.svg' alt='' style={{ width: '24px', height: '24px' }} loading="lazy" /></div>
                                                                <div style={{ width: '150px', marginLeft: '15px' }}>
                                                                    <p>Access management challenges for remote and privileged users </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            }
                        </div>
                    </div >
                    {/* section 4-1 BENEFITS */}
                    < div className='container pe-0 ps-0' >
                        <div className='seq-Percept-benefits'>
                            <div style={{ marginTop: '50px' }}>
                                <p style={{ color: '#2076CD', fontSize: '16px' }}>BENEFITS</p>
                                <div className='row' style={{ marginBottom: '18px', width: '100%' }}>
                                    <div className='col-12 col-md-6 col-sm-12'>
                                        <h3>Identity is the New Security Perimeter. Have You Got This Attack Surface Covered Yet?</h3>
                                    </div>
                                    <div className='col-12 col-md-6 col-sm-12'>
                                        <p>Manual processes and poor compliance visibility can be troublesome, especially in hybrid setups. Percept Identity manages user life cycles, access requests from various sources, keeps app integrations updated, and administers access smoothly for remote and privileged users. Take control of your identity and access risks now!</p>
                                    </div>
                                </div>
                                <div>
                                    {
                                        window.innerWidth > 767 ?
                                            <div className='row seq-benefits-cards'>
                                                {
                                                    PerceptIdentitycards?.map(el => {
                                                        return (
                                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-Percept-card seq-percept-card-4'>
                                                                <div className='seq-card' style={{ height: 375 }}>
                                                                    <img src={el.image} alt='' loading="lazy" />
                                                                    <h4>{el.title}</h4>
                                                                    <p>{el.description}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className='d-flex overflow-auto flex-nowrap'>
                                                {
                                                    PerceptIdentitycards?.map(el => {
                                                        return (
                                                            <div data-aos="fade-up" className='col-md-3'>
                                                                <div className='seq-card'>
                                                                    <img src={el.image} alt='' style={{ width: '48px', height: '48px', marginBottom: '12px' }} loading="lazy" />
                                                                    <h4>{el.title}</h4>
                                                                    <p>{el.description}</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* section 4-2 FEATURES */}
                            <div className='container pe-0 ps-0'>
                                <div className='seq-Percept-graph'>
                                    <p className='d-flex justify-content-center align-items-center' data-aos="fade-up">FEATURES</p>
                                    <h3 className='d-flex justify-content-center align-items-center' data-aos="fade-up">End-To-End User Access Management, With AI-Governed Dynamic, Contextual Access Controls</h3>
                                    <img className='seq-Percept-graph-img1' src='/images/graph-identity.png' alt='' data-aos="fade-up" loading="lazy" />
                                    <div>
                                    </div>
                                    {
                                        window.innerWidth > 767 ?
                                            <div className='row d-flex justify-content-center align-items-center'>
                                                {
                                                    Graphitems?.map(el => {
                                                        return (
                                                            <div data-aos="fade-up" className='seq-Percept-graph-extra col-md-2' >
                                                                <div>
                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                        <img src={el.image} alt='' className='' loading="lazy" />
                                                                    </div>
                                                                    <h4>{el.title}</h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            :
                                            <div className='d-flex overflow-auto flex-nowrap '>
                                                {
                                                    Graphitems?.map(el => {
                                                        return (
                                                            <div data-aos="fade-up" className='d-flex justify-content-center align-items-center'>
                                                                <div className='seq-Percept-graph-extra' style={{ gap: '10px' }}>
                                                                    <div className='d-flex justify-content-center align-items-center'>
                                                                        <img src={el.image} alt='' className='' style={{ width: '24px', height: '24px', marginBottom: '12px' }} loading="lazy" />
                                                                    </div>
                                                                    <h4>{el.title}</h4>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* section 5 TESTIMONIAL SLIDER */}
                < div className="seq-quotes" >
                    <div className='gredient'> </div>
                    <TestimonialSlider
                        page={'product-identity'}
                    />
                </div >
                {/* section 6 SCHEDULE DEMO FORM */}
                < div className='seq-forms' >
                    <div className='container'>
                        <div className="insurance-partseven" data-aos="fade-up">
                            {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading'>Take the first step to simplify your identity security today. Schedule a demo.</h2> : null}
                            <ScheduleForm
                                bg={'/images/bg-img22.svg'}
                                mobilebg={'/images/light-umbrella.svg'}
                                page='percept-identity'
                                text={'Businesses often struggle to reduce identity risks across multi-cloud IT environments. Safeguard your devices and assets with cutting-edge security solutions that prevent unauthorized access and malware infections.'}
                            />
                        </div>
                    </div>
                </div >
                {/* section 7 RELATED RESOURCES */}
                < div className='resources' >
                    <div className='container ps-0'>
                        <div className='row'>
                            <h4>Related resources</h4>
                            <div className='col-md-12'>
                                <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                    <RelatedResources
                                        page='perceptidentity'
                                        type=''
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Resource Center</button></div>}
            </div >
        </div >
    )
}

export default PerceptIdentity
