import { GET_MENUS } from "../Types/types";

let intialState = {
    isLoading: true,
    sideMenuList: []

}

// This function was to initially store Sidebar details

export default function sidebarReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case GET_MENUS:
            stateCopy.sideMenuList = action.payload;
            return stateCopy;
        default:
            return stateCopy;
    }
}
