import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const isLogged = !!localStorage.getItem('adminAuthToken')

    return (
        isLogged ? <Outlet /> : <Navigate to='/partner-portal-login' />
    )
}


export default PrivateRoute;