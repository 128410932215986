import { GET_ALL_PARTNERS, GET_ALL_PRODUCTS, GET_ALL_COMPANIES, GET_ALL_LEADS, ADD_LEAD, DELETE_LEAD } from "../Types/types";

let intialState = {
    leads: [],
    isLoading: true,
    leadStatuses: [{ label: 'Pending', value: 'pending' }, { label: 'Approved', value: 'approved' }, { label: 'Rejected', value: 'rejected' }]
}

// This function was to initially store leads details

export default function leadsReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ALL_PARTNERS:
            stateCopy.allPartners = action.payload.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_ALL_PRODUCTS:
            stateCopy.products = action.payload.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_ALL_COMPANIES:
            stateCopy.companies = action.payload.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_ALL_LEADS:
            stateCopy.leads = action.payload.data;
            stateCopy.totalPages = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case ADD_LEAD:
            stateCopy.leads = [...stateCopy.leads, action.payload];
            return stateCopy;
        case DELETE_LEAD:
            stateCopy.leads = stateCopy.leads.filter(el => el._id !== action.payload);
            return stateCopy;
        default:
            return state;
    }
}