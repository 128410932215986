import { CLEAR_ERRORS, GET_ERRORS } from "../Types/types";

const intialState = {
    messageText: '',
    status: null,
    id: null,
    successMessage: false,
    successEnable: false
};

// This function was to initially store error status details based on the response status code from api

export default function errorReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_ERRORS:
            if (action.payload.status === 401) {
                localStorage.clear();
                stateCopy.status = action.payload.status;
                window.location.pathname !== '/partner-portal-login' && window.location.replace('/partner-portal-login')
            }
            else if (action.payload.status === 404 || action.payload.status === 500) {
                stateCopy.successMessage = true;
                stateCopy.messageText = action.payload.msg || 'Something went wrong';
                stateCopy.status = action.payload.status;
            }
            else if (action.payload.status === 200) {
                stateCopy.successEnable = true;
                stateCopy.messageText = action.payload.msg;
                stateCopy.status = action.payload.status;
            }
            else if (action.payload.status === 202 || action.payload.status === 400 || action.payload.status === 429) {
                stateCopy.successMessage = true;
                stateCopy.messageText = action.payload.status === 429 ? 'Too many Requests' : action.payload.msg;
                stateCopy.status = action.payload.status;
            }
            else if (action.payload.status === 500) {
                stateCopy.successEnable = true;
                stateCopy.messageText = action.payload.msg;
                stateCopy.status = action.payload.status;
            }
            return stateCopy;
        case CLEAR_ERRORS:
            stateCopy.successMessage = false;
            stateCopy.successEnable = false;
            stateCopy.messageText = '';
            // stateCopy.status = '';
            return stateCopy;
        default:
            return stateCopy;
    }
}