// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsroom, resetResourceLoader } from '../../Redux/ActionCreators/resourcesAction';
import Paginations from '../../utils/pagination';


export default function NewsroomArticle() {
    const [page, setPage] = useState(1)
    const active = 'media';
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoadingResource, externalResources, total, } = useSelector(state => state.resources);
    const size = 12;

    useEffect(() => {
        const firstRender = async () => {
            await dispatch(resetResourceLoader());
            await dispatch(getNewsroom(1, '', 'media', '', '', size));
        }
        firstRender();
    }, [])


    const handlePageChange = (pageNum) => {
        setPage(pageNum);
        dispatch(getNewsroom(pageNum, '', 'media', '', '', size));
    }

    const setActive = (val) => {
        if (val === 'press') {
            navigate('/latest-news', { state: { active: 'press' } })
        }
    }


    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = 'https://sequertek-upriselabs-storage.s3.us-east-2.amazonaws.com/resource/mediakit/Media+Kit_Sequretek.zip';
        link.download = 'Sequretek Media Kit.zip';  // This will suggest the filename for download
        link.click();
    };


    return (
        <div className='seq-newsroom'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Newsroom Article</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-newsroom-heading' style={{ backgroundImage: window.innerWidth > 767 ? `linear-gradient(41.44deg, #040C30 22.6%, rgba(6, 6, 93, 0.8) 47.69%, #06065D 110.56%),url('/images/bg-img15.png')` : `linear-gradient(41.44deg, rgb(4, 12, 48) 0.6%, rgba(6, 6, 93, 0.8) 11.69%, rgb(6, 6, 93) 110.56%),url('/images/bg-img15.png')` }}>
                <div className='seq-description'>
                    <h2>Newsroom
                    </h2>
                    <p>Catch up with the latest press releases and cybersecurity news at Sequretek.</p>
                    <div className='text-center'>
                        <button onClick={handleDownload}>Download Media Kit</button>
                    </div>
                </div>
            </div>
            {/* section 2 PRESS RELEASE & MEDIA COVERAGE */}
            <div className='container'>
                <div className='newsroom-tabs'>
                    <div className={active === 'media' ? 'active news-tab' : 'news-tab'} onClick={() => setActive('media')}>Media Coverage</div>
                    <div className={active === 'press' ? 'active news-tab' : 'news-tab'} onClick={() => setActive('press')}>Press Releases</div>
                </div>

                <div className='container'>
                    <div className='row seq-news-cards'>
                        {isLoadingResource ?
                            <div className='text-center'>
                                <img src='/images/loader.svg' alt='' height={400} />
                            </div>
                            :
                            externalResources?.length > 0 ?
                                externalResources?.map(el => {
                                    return (
                                        <div className='col d-flex align-self-stretch' onClick={() => window.open(el.url, '_blank')} style={{ flexGrow: 'unset' }}>
                                            <div className='seq-news-card card' >
                                                <div className="card__img">
                                                    <img src={el.image ? el.image : '/images/news3.svg'} alt="" loading="lazy" />
                                                </div>
                                                <div className="card__body">
                                                    <h6 className='d-flex align-items-center'> News Article &nbsp;<img src='/images/arrow.png' alt='' /></h6>
                                                    <h5 className='body-text elipsis' title={el.title}>{el.title}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                null
                        }
                    </div>
                </div>

                {total > 1 ?
                    <div className='d-flex mt-4 ms-3  me-3'>
                        <Paginations className={'custom-pagination1'} totalPages={total} onPageChange={handlePageChange} />
                    </div> :
                    null
                }
            </div>
        </div>
    )
}