
function parseMonthYear(dateString) {
    // Handle both 'YYYY-MM' and 'YYYY-MM-DD' formats
    const [year, month] = dateString.split('-').map(Number);
    return new Date(year, month - 1); // month is zero-indexed in JS Date
}

function formatMonthYear(date) {
    // Format the date back into 'YYYY-MM'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-indexed
    return `${year}-${month}`;
}

export function fillMissingMonths(dataArray, range = 6) {
    // Parse the month-year strings into Date objects
    const dates = dataArray.map(item => ({
        ...item,
        date: parseMonthYear(item._id)
    }));

    const maxDate = dates.reduce((max, item) => item.date > max ? item.date : max, dates[0].date);
    const minDate = new Date(maxDate);
    minDate.setMonth(minDate.getMonth() - range + 1);

    const fullRange = [];

    let currentDate = minDate;

    while (currentDate <= maxDate) {
        const currentMonthYear = formatMonthYear(currentDate);
        const existingMonth = dates.find(item => item._id.startsWith(currentMonthYear)); // Check if it starts with YYYY-MM

        if (existingMonth) {
            fullRange.push({
                _id: currentMonthYear,
                added: existingMonth.added,
                approved: existingMonth.approved,
                resource: existingMonth?.resource
            });
        } else {
            fullRange.push({
                _id: currentMonthYear,
                added: 0,
                approved: 0,
                resource: 0,
            });
        }

        // Move to the next month
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return fullRange;
}



function formatDate(date) {
    // Format the date back into 'YYYY-MM-DD'
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function fillMissingDays(dataArray, range = 6) {
    // Parse the date strings into Date objects
    const dates = dataArray.map(item => ({
        ...item,
        date: new Date(item._id)
    }));

    const maxDate = dates.reduce((max, item) => item.date > max ? item.date : max, dates[0].date);
    const minDate = new Date(maxDate);
    minDate.setDate(minDate.getDate() - range + 1);

    const fullRange = [];

    let currentDate = minDate;

    while (currentDate <= maxDate) {
        const currentDay = formatDate(currentDate);
        const existingDay = dates.find(item => item._id === currentDay);

        if (existingDay) {
            fullRange.push({
                _id: currentDay,
                count: existingDay.count
            });
        } else {
            fullRange.push({
                _id: currentDay,
                count: 0
            });
        }

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return fullRange;
}


export function getUniqueObjects(arr, property) {
    return arr.filter((obj, index, array) => {
        return array.findIndex((o) => o[property] === obj[property]) === index;
    });
}



export function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/Orange-right.svg" width='35px' alt='' />
        </div>
    );
}

export function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}
        >
            <img src="/images/Orange-left.svg" width='35px' alt='' />
        </div>
    );
}





export const secondsToTime = (e) => {
    // var h = Math.floor(e / 3600).toString().padStart(2,'0'),
    let m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
        s = Math.floor(e % 60).toString().padStart(2, '0');

    return m + ':' + s;
    //return `${h}:${m}:${s}`;
}

export const convertDate = (d1) => {
    let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(d1)
    function pad(s) { return (s < 10) ? '0' + s : s; }
    let d = new Date(date)
    return [pad(d.getDate()), pad(monthNames[d.getMonth()]), pad(d.getFullYear())].join('-')
}

export const formatAMPM = (d) => {
    let date = new Date(d)
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours || 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

export const getDateObj = (time) => {
    let startTime = new Date();
    let parts = time.match(/(\d+):(\d+) (AM|PM)/);
    if (parts) {
        let hours = parseInt(parts[1]),
            minutes = parseInt(parts[2]),
            tt = parts[3];
        if (tt === 'PM' && hours < 12) hours += 12;
        startTime.setHours(hours, minutes, 0, 0);
    }
    return startTime
}


export function convertToDate(str) {
    if (str === null) {
        return null
    } else {
        let date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [mnth, day, date.getFullYear()].join("/");
    }
}

export function convertToDate1(str) {
    let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear()].join("/");
}





export function generate_year_range(start, end) {
    let years = [];
    for (let year = start; year <= end; year++) {
        years.push(year);
    }
    return years;
}








