// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';

export default function Blogs14() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Lockbit 3.0 – Analysis</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Lockbit 3.0 – Analysis</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Lockbit 3.0 – Analysis</h3>
                        <p>30-JUNE-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>The LockBit 3.0 ransomware functions as a Ransomware-as-a-Service (RaaS) model. LockBit 3.0 attempts to spread across a victim network and is a continuation of previous versions of the ransomware, LockBit 2.0, and LockBit. Since January 2020, LockBit has functioned as an affiliate-based ransomware variant.</p>
                            <p>Upon execution, activity of the malware is as follows: –</p>
                            <ul>
                                <li><p>Encrypt files and create ransom note.</p></li>
                                <li><p>Set LockBit icon in every encrypted file.</p></li>
                                <li><p>Terminate original file by executing a temporary file.</p></li>
                                <li><p>Uploading the data of victim machine.</p></li>
                                <li><p>Sets a wallpaper as a ransom note.</p></li>
                            </ul>
                            <h4>File Details</h4>
                            <Table responsive>
                                <tr>
                                    <th><h4>MD5</h4></th>
                                    <th><p>FDD9F9AE1D24DCC709CD0ABCEA638ED0</p></th>
                                </tr>
                                <tr>
                                    <th><h4>SHA-1</h4></th>
                                    <th><p>2fe29b620b51d2258373b12d926a91d0a3720a60</p></th>
                                </tr>
                                <tr>
                                    <th><h4>SHA-256</h4></th>
                                    <th><p>4134d5d8f7b038e23e7887db56bb3ad295341a1aaf0bebe6be21d901d06dd662</p></th>
                                </tr>
                                <tr>
                                    <th><h4>File type</h4></th>
                                    <th><p>PE32 executable</p></th>
                                </tr>
                                <tr>
                                    <th><h4>Target Machine</h4></th>
                                    <th><p>Intel 386 or later processors and compatible processors</p></th>
                                </tr>
                                <tr>
                                    <th><h4>File size</h4></th>
                                    <th><p>160.00 KB (163840 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><h4>Compiler</h4></th>
                                    <th><p>Microsoft Visual C++</p></th>
                                </tr>
                                <tr>
                                    <th><h4>Signature</h4></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                            </Table>
                            <h4>Static Analysis</h4>
                            <p><u>Sequretek</u> Malware Analysis Team analyzed a customer escalated sample of the malware to determine the infection methodology.</p>
                            <p>We discovered that the malware uses two runtime executables that are hard coded as shown in Figure-1.</p>
                            <img src='/images/blog-images/sample117.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure: 1 Executable section property of lockbit3.0</p>
                            <br />
                            <p>It has been observed that address of entry point is outside the first section of the file as shown</p>
                            <p>in Figure-2.</p>
                            <img src='/images/blog-images/sample118.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure: 2 Entry Point of the Ransomware</p>
                            <br />
                            <h4>Dynamic Analysis:</h4>
                            <p>When running the sample (LBB.exe), it encrypts the full system and then it start child process</p>
                            <p>with randomly named tmp file</p>
                            <img src='/images/blog-images/sample119.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure: 3 Lbb.exe and its child process creation</p>
                            <br />
                            <p>LockBit 3.0 writes a copy of itself to the %program data% directory, and subsequently launches from this process as shown in Figure-4 below.</p>
                            <p>Ransomware “.icon” file, will also be there in in the %program data% directory and its registry will also get created as shown in Figure-5.</p>
                            <p>The wallpaper “. Bmp file” are also present in the %program data% directory, whose registry entries are shown at the Figure-6</p>
                            <img src='/images/blog-images/sample120.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure: 4 Ransomware Dropping tmp file</p>
                            <br />
                            <img src='/images/blog-images/sample121.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure-5 Ransomware creating its entry in the registry key to append extension to the end of all the files encrypted by it</p>
                            <br />
                            <img src='/images/blog-images/sample122.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure-6 Registry key of the icon file</p>
                            <br />
                            <p>After encrypting infected system, it will display ransom note as shown in Figure-7.</p>
                            <img src='/images/blog-images/sample123.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 7 Ransom note display on the Desktop of the infected system</p>
                            <br />
                            <p>After encryption, the created child process will terminate LBB.exe as shown in Figure-8 and makes itself the parent process. It has also been observed that it starts gathering information about the drives in the system and creates random folder in “C” drive. This is used for collecting information of the victim system and uploading. However, in the sample analysed, there was no connection observed to the C2 server and data transfer was not observed.</p>
                            <img src='/images/blog-images/sample124.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure-8: Displaying tmp process</p>
                            <br />
                            <img src='/images/blog-images/sample125.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure-9 Data gathering done by the temp process</p>
                            <br />
                            <h4>Indicators of Compromise:</h4>
                            <ul>
                                <li>
                                    <p>File Hashes:</p>
                                    <ul>
                                        <li><p>fdd9f9ae1d24dcc709cd0abcea638ed0</p></li>
                                        <li><p>939b44c121ac02bb4f2263aa98d435b7</p></li>
                                        <li><p>24a648a48741b1ac809e47b9543c6f12</p></li>
                                        <li><p>ce2b6578c828da326075c307f558bfd8</p></li>
                                        <li><p>2afc1b6b4a45411d3ac070d993a7913d</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>IP Addresses:</p>
                                    <ul>
                                        <li><p>46[.]3[.]223[.]187</p></li>
                                        <li><p>81[.]19[.]135[.]140</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>MITRE ATT&CK Tactics and Techniques</h4>
                            <p><strong>Privilege Escalation TA0004</strong></p>
                            <ul>
                                <li><p> Process Injection T1055</p></li>
                                <li><p>Writes to foreign memory regions</p></li>
                            </ul>
                            <p><strong>Defense Evasion TA0005</strong></p>
                            <ul>
                                <li><p>Obfuscated Files or Information T1027</p></li>
                                <li><p>Encode data using XOR</p></li>
                                <li><p>Encode data using Base64</p></li>
                                <li><p>Encrypt data using AES</p></li>
                                <li><p>Reference AES constants</p></li>
                                <li><p>Encrypt data using RC4 KSA</p></li>
                                <li><p>Binary may include packed or crypted data</p></li>
                            </ul>
                            <p><strong>Software Packing T1027.002</strong></p>
                            <ul>
                                <li><p>PE file has an executable .text section which is very likely to contain packed code (zlib compression ratio &lt; 0.3)</p></li>
                                <li><p>Binary may include packed or crypted data</p></li>
                            </ul>
                            <p><strong>Masquerading T1036</strong></p>
                            <ul>
                                <li><p>Creates files inside the user directory Process Injection T1055</p></li>
                                <li><p>Writes to foreign memory regions</p></li>
                            </ul>
                            <p><strong>Virtualization/Sandbox Evasion T1497</strong></p>
                            <ul>
                                <li><p>Hides threads from debuggers</p></li>
                                <li><p>May sleep (evasive loops) to hinder dynamic analysis</p></li>
                            </ul>
                            <p><strong>Credential Access TA0006</strong></p>
                            <ul>
                                <li><p>Input Capture T1056</p></li>
                                <li><p>Creates a DirectInput object (often for capturing keystrokes)</p></li>
                            </ul>
                            <p><strong>Keylogging T1056.001</strong></p>
                            <ul>
                                <li><p>Log keystrokes via polling</p></li>
                            </ul>
                            <p><strong>Discovery TA0007</strong></p>
                            <ul>
                                <li><p>Process Discovery T1057</p></li>
                                <li><p>Queries a list of all running processes</p></li>
                            </ul>
                            <p><strong>System Information Discovery T1082</strong></p>
                            <ul>
                                <li><p>Queries the cryptographic machine GUID</p></li>
                                <li><p>Reads software policies</p></li>
                                <li><p>Checks the free space of hard-drives</p></li>
                            </ul>
                            <p><strong>File and Directory Discovery T1083</strong></p>
                            <ul>
                                <li><p>Writes ini files</p></li>
                            </ul>
                            <p><strong>Virtualization/Sandbox Evasion T1497</strong></p>
                            <ul>
                                <li><p>Hides threads from debuggers</p></li>
                                <li><p>May sleep (evasive loops) to hinder dynamic analysis</p></li>
                            </ul>
                            <p><strong>Security Software Discovery T1518.001</strong></p>
                            <ul>
                                <li><p>Hides threads from debuggers</p></li>
                                <li><p>May try to detect the virtual machine to hinder analysis (VM artifact strings found in memory)</p></li>
                            </ul>
                            <p><strong>Collection TA0009</strong></p>
                            <ul>
                                <li>
                                    <p>Input Capture T1056</p>
                                    <ul>
                                        <li><p>Creates a DirectInput object (often for capturing keystrokes)</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Keylogging T1056.001</p>
                                    <ul>
                                        <li><p>Log keystrokes via polling</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h3>Command and Control TA0011</h3>
                            <ul>
                                <li>
                                    <p>Application Layer Protocol T1071</p>
                                    <ul>
                                        <li><p>Performs DNS lookups</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Proxy T1090</p>
                                    <ul>
                                        <li><p>Found Tor onion address</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Non-Application Layer Protocol T1095</p>
                                    <ul>
                                        <li><p>Performs DNS lookups</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>Impact TA0034</h4>
                            <ul>
                                <li>
                                    <p>Data Encrypted for Impact T1486</p>
                                    <ul>
                                        <li><p>Writes a notice file (html or txt) to demand a ransom</p></li>
                                        <li><p>Modifies user documents (likely ransomware behavior)</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>Impact TA0040</h4>
                            <ul>
                                <li>
                                    <p>Data Encrypted for Impact T1486</p>
                                    <ul>
                                        <li><p>Writes a notice file (html or txt) to demand a ransom</p></li>
                                        <li><p>Modifies user documents (likely ransomware behavior)</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>Conclusion:</h4>
                            <p>LockBit 3.0 ransomware first encrypts the system, after encryption scan all drives and collects relevant data and then send it to Command-and-Control Server and demand for ransom. However, in the sample analyzed, there was no connection observed to the C2 server and data transfer was not observed.</p>
                            <h4>Best Practices for Risk Reduction against Ransomware Attacks:</h4>
                            <p><strong>Backup your data:</strong> Ensure that your organization’s data is regularly backed up and the backups are stored in a separate location. This will help you recover your data if you fall victim to any ransomware attack.</p>
                            <p><strong>Keep software up to date:</strong> Ensure that your organization’s operating systems, software applications and other devices are updated with the latest security patches and updates. Regularly updating your software will help prevent known vulnerabilities from being exploited.</p>
                            <p><strong>Implement a strong password policy at your organization:</strong> Ensure that your employees use strong passwords and two-factor authentication to access organization’s systems and data. Weak passwords can be easily targeted by ransomware attackers.</p>
                            <p><strong>Regularly train employees on security best practices:</strong> Provide regular training to your employees on how to identify and avoid suspicious emails, links, and attachments that could be potential ransomware vectors.</p>
                            <p><strong>Limit access:</strong> Restrict access to sensitive organization’s data to only those who need it. This will help prevent ransomware from spreading through your organization’s network.</p>
                            <p><strong>Implement network segmentation:</strong> Segment your organization’s network into smaller subnetworks to prevent the spread of ransomware in the event of an attack.</p>
                            <p><strong>Use security tools:</strong> Implement security tools such as firewalls, antivirus / EDR, intrusion detection and prevention systems, identity governance and administration (IGA) and security monitoring / extended detection & response (XDR) solutions to monitor, protect, respond and remediate against malicious activities / incidents at your organization.</p>
                            <p><strong>Have a plan:</strong> Develop a plan for responding to ransomware attacks. This should include identifying key personnel and responsibilities, having a backup and recovery plan, and having a communication plan in place to inform stakeholders of the situation</p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Lockbit 3.0 – Analysis' , url: 'https://www.sequretek.com/resources/blog/lockbit-3-0-analysis'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
