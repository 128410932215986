// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const risks = [
    { image: '/images/rulesbook.svg', title: 'Stringent Regulations', description: 'SMBs must comply with their respective industry regulators. Non-compliance can result in penalties and service disruption.' },
    { image: '/images/hand-holding-usd.svg', title: 'Limited Resources', description: 'SMBs often cannot afford dedicated cybersecurity professionals or advanced security solutions; they have to rely on basic measures which are easily bypassed.' },
    { image: '/images/disk.svg', title: 'Legacy Technologies', description: 'Many SMBs use point products making them more susceptible to attacks. They need consolidation and coexistence with their IT investments.' },
    { image: '/images/lightbulb-on-blue.svg', title: 'Cybersecurity Awareness', description: `Cybersecurity for small and medium businesses requires vigilant and well-trained employees to mitigate the risk of phishing and social engineering attacks.` },
    { image: '/images/shieldsecurity.svg', title: 'Evolving Threats', description: 'The constantly changing cyberthreat landscape challenges SMBs to keep their security measures up-to-date.' },
    { image: '/images/userhelmetsafety.svg', title: 'Shortage of Skilled Manpower', description: 'The cybersecurity skills shortage leaves SMBs vulnerable, and lacking both expertise and resources for adequate protection.' },
    { image: '/images/risk.svg', title: 'Business Continuity or Security', description: `Prioritizing operational continuity over security fosters a false sense of safety and increases vulnerability.` },
    // { image: '/images/globe.svg', title: 'Compliance Conundrum', description: `Non-compliance with global industry regulations and data protection laws can trigger legal and financial repercussions.` },
    { image: '/images/shieldcheck.svg', title: 'Resilience', description: `SMBs are frequent targets of sophisticated cyberattacks, which can be crippling due to SMBs’ limited capabilities for incident response and remediation.` },
    { image: '/images/interactive.svg', title: 'Digital Transformation', description: `Many SMBs undergo digital transformation without integrating the necessary cybersecurity measures, thereby increasing their attack surface. ` },
    { image: '/images/queue.svg', title: 'Unaware Workforce', description: `An ignorant, overly curious, or under-trained workforce is a security risk as its members are easily outwitted by modern-day cybercriminals.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (CTEM) continuously monitors IT infrastructure for threats and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM, SOAR products and services help monitor, detect, and respond to cyberattacks in real-time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: `Sequretek’s CTEM platform’s governance and tailored compliance reports help meet the mandates of various regulatory bodies and acts.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]



export default function Business() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4100);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad1b01e5c52d1577f91a3']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | SMB Cybersecurity | Solutions for Small & Medium Businesses</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Secure your small or medium busines. Protect data, manage risks, and ensure compliance with robust, affordable cybersecurity solutions." />
                <meta name="keywords" content="Cybersecurity for small business, SMB Cybersecurity" />
                <meta property="og:title" content="Sequretek | SMB Cybersecurity | Solutions for Small & Medium Businesses" />
                <meta property="og:description" content="Secure your small or medium busines. Protect data, manage risks, and ensure compliance with robust, affordable cybersecurity solutions." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: ` linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/smb-bgimg.png')` }}>
                <div className='seq-description seq-des-sbm'>
                    <h5 data-aos="fade-up">Industries {' > '} Small & Medium Businesses </h5>
                    <h2 data-aos="fade-up">Protect Your SMB with Reliable, Affordable Cybersecurity Solutions</h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>Addressing SMB
                                <br />
                                Cybersecurity Challenges
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>Over 60% of SMBs are vulnerable to cyberattacks, while large corporations have resources for robust security. Small and medium businesses, already stretched thin, risk devastating consequences from a single attack. But affordable, user-friendly cybersecurity solutions exist specifically for SMBs to protect their reputation and resources, and meet their compliance needs.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Risk Radars</p>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 325 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 4 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg ' style={{ backgroundImage: `url('/images/bg-img68.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left seq-help-sm'>
                                <p >How Sequretek Helps</p>
                                <h3>Comprehensive SMB Cybersecurity Services</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 seq-ind-qimg offset-md-1'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 TESTIMONIAL SLIDER*/}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'smb'}
                />
            </div>
            {/* section 6 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img69.svg'}
                            mobilebg={'/images/bg-img70.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 7 RELATED RESOURCES*/}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='smb'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
