import { fillMissingDays, fillMissingMonths } from "../../utils/miscellneous";
import { RESET_DASHBOARD_DATA, GET_TICKET_METRICS, GET_LEADS_DETAILS, GET_LOGIN_TREND, GET_RESOURCE_TREND, GET_RECENT_LEAD, GET_RECENT_DEAL, GET_RECENT_TICKET, GET_RECENT_CONTENT, GET_CONSUMED_CONTENT, GET_NEXT_CONSUMED_CONTENT } from "../Types/types";

let intialState = {
    ticketMetric: [],
    isLoading: true,
    contentLoading: true
}

// This function was to initially store month details

function getMonthName(monthYearString = '2025-02') {
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Extract the month part from the 'YYYY-MM' string
    const monthNumber = parseInt(monthYearString.split('-')[1], 10); // 1-indexed month

    // Check if the month number is within the valid range (0 to 11)
    if (monthNumber < 1 || monthNumber > 12) {
        return "Invalid month number";
    }

    // Subtract 1 to convert to 0-indexed month (for the array lookup)
    return monthNames[monthNumber - 1];
}



// This function was to initially store dashboard user details

export default function dashboardReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RESET_DASHBOARD_DATA:
            stateCopy.ticketMetrics = [];
            stateCopy.months = [];
            stateCopy.leadAdded = [];
            stateCopy.leadApproved = [];
            stateCopy.loginMetric = [];
            stateCopy.recentResources = [];
            stateCopy.recentLeads = [];
            stateCopy.recentTickets = [];
            stateCopy.resourceCount = [];
            stateCopy.contentMonth = [];
            return stateCopy;
        case GET_TICKET_METRICS:
            stateCopy.ticketMetrics = action.payload;
            return stateCopy;
        case GET_LEADS_DETAILS:
            const completeData = action.payload?.length ? fillMissingMonths(action.payload, 6) : [];
            if (completeData?.length) {
                stateCopy.months = completeData?.map(el => getMonthName(el._id));
                stateCopy.leadAdded = completeData?.map(el => el.added);
                stateCopy.leadApproved = completeData?.map(el => el.approved);
            }
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_LOGIN_TREND:
            const data = action.payload?.length ? fillMissingDays(action.payload, 7) : [];
            stateCopy.loginMetric = data;
            return stateCopy;
        case GET_RESOURCE_TREND:
            // const scores = [{ name: 'Video', y: 30, color: '#7B68EE' }, { name: 'Datasheets', y: 50, color: '#2E2787' }, { name: 'Report', y: 60, color: '#48D1CC' }, { name: 'Advisory', y: 70, color: '#002D62' }, { name: 'Blog', y: 40, color: '#008080' }]
            const resourceData = action.payload?.length ? fillMissingMonths(action.payload, 6) : [];
            if (resourceData?.length) {
                stateCopy.contentMonth = resourceData?.map(el => getMonthName(el._id));
                const count = resourceData?.map(el => el.resource)
                stateCopy.resourceCount = resourceData?.map(el => [{ y: el.resource, color: el === Math.max(...count) ? '#5fbb97' : '#BFB8F2' }][0]);
            }
            return stateCopy;
        case GET_RECENT_CONTENT:
            stateCopy.recentResources = action.payload;
            return stateCopy;
        case GET_RECENT_LEAD:
            stateCopy.recentLeads = action.payload;
            return stateCopy;
        case GET_RECENT_DEAL:
            stateCopy.recentDeals = action.payload;
            return stateCopy;
        case GET_RECENT_TICKET:
            stateCopy.recentTickets = action.payload;
            return stateCopy;
        case GET_CONSUMED_CONTENT:
            stateCopy.consumeResources = action.payload;
            stateCopy.contentLoading = false;
            return stateCopy;
        case GET_NEXT_CONSUMED_CONTENT:
            stateCopy.consumeResources = stateCopy.consumeResources.concat(action.payload);
            stateCopy.contentLoading = false;
            return stateCopy;
        default:
            return stateCopy;
    }
}