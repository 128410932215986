// ############################ Require packages here ############################
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';


// ############################ Require files here ###############################

import { convertDate } from '../../../utils/miscellneous';
import { returnErrors } from '../../../Redux/ActionCreators/errorAction';



export default function TicketDetails({ setIsView, supportDetails, replies, replySent, ticketClosed }) {

    const [reply, setReply] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const changeAttach = (e) => {
        var Extension = e.target.value
            .substring(e.target.value.lastIndexOf(".") + 1)
            .toLowerCase();
        if (e.target.files[0].size > 2097152) {
            returnErrors(dispatch, "The file size cannot exceed 2MB", 202);
        }
        else {
            if (
                Extension === "png" ||
                Extension === "jpeg" ||
                Extension === "jpg"
            ) {
                const file = e.target.files[0];
                const reader = new FileReader();

                reader.onloadend = () => {
                    // setImageUrl(reader.result);
                };
                if (file) {
                    reader.readAsDataURL(file);
                    // setImageUrl(reader.result);
                    setAttachment(file);
                } else {
                    setAttachment(null)
                }
            } else {
                returnErrors(dispatch, "File Format not allowed", 202)
            }
        }
    }

    const sendReply = async () => {
        await setLoading(true);
        const data = new FormData();
        data.append('_id', supportDetails?._id);
        data.append('reply', reply);
        data.append('payload', attachment)
        await replySent(data);
        await setReply('');
        await setAttachment(null);
        await setLoading(false);
    }

    const getFullName = (fname, lname) => {
        return `${fname} ${lname}`
    }

    const closeTicket = async () => {
        let obj = {
            _id: supportDetails?._id
        }
        await ticketClosed(obj);
    }

    const details = JSON.parse(localStorage.getItem('profileDetails'));
    return (
        <Fragment>
            {/* TICKET DETAILS FORM */}
            <div className='add-screen'  >
                <div>
                    <h2 className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            <img src='/images/back.svg' className='cursor-pointer' alt='' onClick={() => setIsView()} />&nbsp;&nbsp;
                            Ticket Details
                        </div>
                        {supportDetails?.closed ? null :
                            <div className='leads-btn flex-row-reverse'>
                                {((supportDetails?.from?._id === details?._id) || (localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'support')) ?
                                    <button type='submit' onClick={closeTicket} >Mark as closed</button> :
                                    null
                                }
                            </div>}
                    </h2>
                </div>
                {/* TICKET DESCRIPTION FORM */}
                <div className='ticket-description'>
                    <div className='row me-0'>
                        <div className='col-md-3 mt-2'>
                            <h5>Question Type</h5>
                            <p className='text-capitalize'>{supportDetails?.type}</p>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <h5>Status</h5>
                            <p>{supportDetails?.closed ? 'Closed' : 'In Progress'}</p>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <h5>From</h5>
                            <p>{supportDetails?.from?.fname} {supportDetails?.from?.lname}</p>
                        </div>
                        <div className='col-md-3 mt-2'>
                            <h5>Email</h5>
                            <p>{supportDetails?.from?.email}</p>
                        </div>
                        <div className='col-md-3 mt-4'>
                            <h5>Created</h5>
                            <p>{convertDate(supportDetails?.createdAt)}</p>
                        </div>
                        <div className='col-md-3 mt-4'>
                            <h5>Updated</h5>
                            <p>{convertDate(supportDetails?.createdAt)}</p>
                        </div>
                        <div className='col-md-3 mt-4'>
                            <h5>Document Shared</h5>
                            <p>{supportDetails?.doc_shared ? 'Yes' : 'No'}</p>
                        </div>
                    </div>
                </div>
                {/* TICKET CHAT FORM */}
                <div className='ticket-chat'>
                    <div className="chat-container">
                        <div className='chat-message chat-message-left '>
                            <div className='chat-bubble chat-bubble-left '>
                                <h5><p className='d-flex align-items-center'><img src='/images/avatar1.svg' alt='' /> &nbsp;{getFullName(supportDetails?.from?.fname, supportDetails?.from?.lname)}&nbsp;
                                    {supportDetails?.attachments?.length ?
                                        <a href={supportDetails?.attachments[0]} target='_blank'>
                                            <img src='/images/attachment.png' alt='' />
                                        </a> :
                                        null
                                    }
                                </p><span>{convertDate(supportDetails?.createdAt)}</span></h5>
                                <p style={{ fontWeight: 400 }}>{supportDetails?.question}</p>
                            </div>
                        </div>
                        {replies?.map((el) => (
                            <div
                                key={el._id}
                                className={`chat-message ${el.name === getFullName(supportDetails?.from?.fname, supportDetails?.from?.lname) ? 'chat-message-left' : 'chat-message-right'}`}
                            >
                                <div
                                    className={el.name === getFullName(supportDetails?.from?.fname, supportDetails?.from?.lname) ? `chat-bubble chat-bubble-left` : `chat-bubble chat-bubble-right`}
                                >
                                    {
                                        <div>
                                            <h5><p className='d-flex align-items-center'><img src='/images/avatar1.svg' alt='' /> &nbsp;{el.name}&nbsp;
                                                {el?.attachments?.length ?
                                                    <a href={el?.attachments[0]} target='_blank'>
                                                        <img src='/images/attachment.png' alt='' />
                                                    </a> : null
                                                }
                                            </p><span>{convertDate(el.createdAt)}</span></h5>
                                            <p style={{ fontWeight: 400 }}>{el.text}</p>
                                        </div>

                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {supportDetails?.closed ? null :
                        <div className='form-group position-relative mt-5'>
                            <textarea
                                rows={4}
                                placeholder='Type Here...'
                                value={reply}
                                className='form-control ' onChange={(e) => setReply(e.target.value)}
                            />
                            <div className='mt-2'>
                                {attachment?.name}
                            </div>
                            <div className='attach'>
                                <img src='/images/attachment.png' alt='' />
                                <input type='file' className='file-upload'
                                    name="selectedFile"
                                    style={{ top: -5 }}
                                    accept="image/png, image/jpeg,image/jpg,.pdf"
                                    onChange={changeAttach} />
                            </div>
                            <button className='submit-btn' disabled={(reply === '' || loading)} onClick={sendReply}>Submit</button>
                        </div>
                    }
                </div>
            </div >
        </Fragment >
    )
}
