/* eslint-disable jsx-a11y/anchor-is-valid */
// ############################ Require packages here ############################
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Alert } from '@mui/material';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";
// ############################ Require files here ###############################
import { Modal, Spinner } from 'react-bootstrap';

import countries from '../../utils/countries.json';
import './styles.scss';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import { submitRegistrationForm } from '../../Redux/ActionCreators/authAction';


function PartnerRegistration() {
    const [active, setActive] = useState('register');
    const [loading, setLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState(null);
    const formRef = useRef(null);
    const [registerValues, setRegisterValues] = useState({ fname: '', lname: '', dial_code: "", mobile: '', email: "", organization: "", website: "", linkedin_url: "", country: "", job: "", partner: "", products: [], });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector(state => state.error);
    const location = useLocation();
    const formikRef = useRef();


    const registerSchema = Yup.object({
        fname: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
            .required('First Name is required'),
        lname: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
            .required('Last Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
        mobile: Yup.string()
            .trim('Only Numbers allowed')
            .strict(true)
            .matches(/^\d{10}$/, 'Phone number must be 10 digits')
            .required('Phone is required'),
        organization: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Organization Name should only contain alphabets, numbers, and space')
            .required('Organization is required'),
        website: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .required('Website is required'),
        job: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .required('Designation is required'),
        linkedin_url: Yup.string()
            .required('Linkedin Url is required'),
        country: Yup.object()
            .shape({
                value: Yup.string().required('You must select an option'),
            })
            .nullable() // allow null if you want to allow resetting to null
            .required('Country is Required'),
        products: Yup.array()
            .min(1, 'You must select at least one products')
            .required('Required'),
        roles: Yup.string().required('Partner is required')
    })





    // Check if the user is already logged in
    useEffect(() => {
        const userIsLoggedIn = localStorage.getItem('adminAuthToken') !== null
        if (userIsLoggedIn) {
            // Redirect to the dashboard page
            if (window.location.pathname === '/partner-portal-login') {
                if (localStorage.getItem('role') === 'support') {
                    navigate('/app/supports');
                }
                else {
                    navigate('/app/leads');
                }
            }
        }
    }, [location]);


    const selectTab = (val) => {
        if (val === 'login') {
            navigate('/partner-portal-login')
        } else {
            setActive(val);
            setRegisterValues({ fname: '', lname: '', mobile: '', email: "", organization: "", website: "", linkedin_url: "", country: "", job: "", partner: "", products: [], });
        }
    }

    const handleCountryChange = (event, setFieldValue) => {
        setFieldValue('country', event ? event : '');
        setFieldValue('dial_code', event ? event.dial_code : '');
    };

    const handleRegisterSubmit = async (values, { setSubmitting }) => {
        let obj = {
            "fname": values?.fname,
            "lname": values?.lname,
            "email": values?.email,
            "mobile": `${values?.dial_code}${values?.mobile}`,
            "country": values?.country?.value,
            "organization": values?.organization,
            designation: values?.job,
            website: values.website,
            linkedin: values?.linkedin_url,
            "products": values?.products,
            roles: [values?.roles]
        }
        setData(obj);
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    }

    const handleCaptchaSubmit = async () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        await setLoading(true);
        if (validateCaptcha(user_captcha_value, false) === true) {
            console.log('matched');
            setCaptchaError('');
            setIsConfirm(false);

            // Submit the registration form
            const res = await dispatch(submitRegistrationForm(data));

            if (res?.type === 'FORM_REGISTER') {

                // Reset the form and handle state updates
                if (formikRef.current) {
                    formikRef.current.resetForm();
                    formikRef.current.setFieldValue('country', null);
                    formikRef.current.setFieldValue('newsletter', false);

                }

                // Close the captcha modal after successful submission
            }
        } else {
            setCaptchaError('Captcha Does Not Match');
        }
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }



    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#040c30',
            zIndex: 3
        }),
        option: (provided, state) => ({
            ...provided,
            textAlign: 'left',
            backgroundColor: state.isSelected ? 'lightblue' : '#040c30', // Change background color on selection
            color: '#b6bcbf', // Ensure text is visible
            ':hover': {
                color: '#000',
                backgroundColor: '#b6bcbf' // Change background color on hover
            }
        })
    };


    const snackClosed = () => {
        dispatch(clearErrors());
    }



    return (
        <Fragment>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successMessage}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                open={error.successEnable}
                onClose={() => snackClosed()}
                autoHideDuration={3000}
            >
                <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                    {error.messageText}
                </Alert>
            </Snackbar>
            <div className={'login-module'}>
                {/* section 0 PAGE HEADING */}
                <Helmet>
                    <title>Sequretek | Partner Registration</title>
                    <meta http-equiv="X-Frame-Options" content="DENY" />
                    <meta name="description" content="Complete and submit the partner registration form to become a valued partner today!" />
                    <meta property="og:title" content="Sequretek | Partner Registration" />
                    <meta property="og:description" content="Complete and submit the partner registration form to become a valued partner today!" />
                </Helmet>
                {/* section 1 REGISTER */}
                <div className='container pe-0 ps-0'>
                    <div className="login-holder">
                        <div className="login-box text-center" style={{ width: '650px' }}>
                            <Fragment>
                                <h1 className='login-box-head'>{active === 'login' ? 'Sequretek Partner Portal' : 'Sequretek Partner Registration'}</h1>
                                <h2 className="mt-2 login-box-head1" style={{ height: 42 }}>Become a partner and help your customers stay ahead of emerging threats</h2>
                                {/* <div className='portal-tabs'>
                                    <div onClick={() => selectTab('login')} className={active === 'login' ? 'active portal-tab' : 'portal-tab'}>Login</div>
                                    <div onClick={() => selectTab('register')} className={active === 'register' ? 'active portal-tab' : 'portal-tab'}>Register</div>
                                </div> */}

                                <Formik innerRef={formikRef} enableReinitialize initialValues={registerValues} formRef={formRef} validationSchema={registerSchema} onSubmit={handleRegisterSubmit}>
                                    {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                                        <Form id='register' >
                                            <div className="row mt-4">
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="fname" autoComplete='off' className="form-control" placeholder="First Name *" />
                                                        <ErrorMessage name="fname" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="lname" autoComplete='off' className="form-control" placeholder="Last Name *" />
                                                        <ErrorMessage name="lname" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="organization" autoComplete='off' className="form-control" placeholder="Organization*" />
                                                        <ErrorMessage name="organization" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="email" name="email" autoComplete='off' className="form-control" placeholder="Business Email*" />
                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Select
                                                            name="country"
                                                            id="singleSelectCustom"
                                                            placeholder="Country*"
                                                            isMulti={false}
                                                            isClearable
                                                            isSearchable
                                                            value={values.country}
                                                            styles={customStyles}
                                                            onChange={(event) => handleCountryChange(event, setFieldValue)}
                                                            options={countries?.map(el => [{ ...el, label: el.name, value: el.name }][0])}
                                                        />
                                                        {errors.country?.value && touched.country ? (
                                                            <div className="error-message">{errors.country.value}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group d-flex">
                                                        <Field
                                                            style={{ width: '80px', background: '#ffffff1a' }}
                                                            name="dial_code"
                                                            placeholder=""
                                                            value={values?.dial_code}
                                                            className='form-control'
                                                            autoComplete='off'
                                                            disabled
                                                        />
                                                        <div style={{ width: '100%' }}>
                                                            <Field type="tel" name="mobile" autoComplete='off' maxlength={10} className="form-control" placeholder="Phone *" />
                                                            <ErrorMessage name="mobile" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="website" autoComplete='off' className="form-control" placeholder="Website*" />
                                                        <ErrorMessage name="website" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="job" autoComplete='off' className="form-control" placeholder="Designation*" />
                                                        <ErrorMessage name="job" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-12">
                                                    <div className="form-group">
                                                        <Field type="text" name="linkedin_url" autoComplete='off' className="form-control" placeholder="Organization's LinkedIn URL*" />
                                                        <ErrorMessage name="linkedin_url" component="div" className="error-message" />
                                                    </div>
                                                </div>

                                                <div className='col-md-12 mt-4 d-flex flex-column align-items-start justify-content-start'>
                                                    <label className='radio-label'>I’d like to partner with Sequretek as a/an *</label>
                                                    <div className='radios mt-2'>
                                                        <div class="form-check form-check-inline ">
                                                            <label class="form-check-label" htmlFor='mssp'>MSSP</label>&nbsp;
                                                            <Field class="form-check-input" type="radio" name={'roles'} value={'mssp'} />
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label" htmlFor='msp'>MSP</label>&nbsp;
                                                            <Field class="form-check-input" type="radio" name={'roles'} value={'msp'} />
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label" htmlFor='vars'>VAR</label>&nbsp;
                                                            <Field class="form-check-input" type="radio" name={'roles'} value={'vars'} />
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label" htmlFor='independent'>Independent Consultant</label>&nbsp;
                                                            <input class="form-check-input" type="radio" name={'roles'} value={'independent'} />
                                                        </div>
                                                        <div class="form-check form-check-inline">
                                                            <label class="form-check-label" htmlFor='other'>Other</label>&nbsp;
                                                            <Field class="form-check-input" type="radio" name={'roles'} value={'other'} />
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="roles" component="div" className="error-message" />
                                                </div>
                                                <div className='col-md-12 d-flex flex-column align-items-start justify-content-start mt-4'>
                                                    <label className='radio-label'>Products I am interesed in *</label>
                                                    <div className=' checkboxs mt-2' style={{ width: '124%' }}>
                                                        <div className="form-check form-check-inline">
                                                            <Field
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkbox1"
                                                                name='products'
                                                                value="Percept XDR and NG SIEM"
                                                            />
                                                            <label className="form-check-label" htmlFor="checkbox1">
                                                                Percept XDR & NG SIEM
                                                            </label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <Field
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkbox2"
                                                                name='products'
                                                                value="Percept Identity"
                                                            />
                                                            <label className="form-check-label" htmlFor="checkbox2">
                                                                Percept Identity
                                                            </label>
                                                        </div>

                                                        <div className="form-check form-check-inline">
                                                            <Field
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkbox3"
                                                                name='products'
                                                                value="Percept EDR"
                                                            />
                                                            <label className="form-check-label" htmlFor="checkbox3">
                                                                Percept EDR
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkbox4"
                                                                name='products'
                                                                value="Percept Compliance Manager"
                                                            />
                                                            <label className="form-check-label" htmlFor="checkbox4">
                                                                Percept Compliance Manager
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="products" component="div" className="error-message" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12 mt-3">
                                                {loading ?
                                                    <button type='submit' className='button-color form-control' style={{ backgroundColor: '#1BA431', borderColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                                    <button className='button-color form-control' disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner animation="border" variant="light" style={{ marginTop: 0 }} /> : 'Let’s Connect'}</button>
                                                }
                                                {/* <button type="submit" className='button-color form-control'>Lets Connect</button> */}
                                            </div>
                                            {loading ?
                                                <div className='mt-2 mb-3'>
                                                    <p className='seq-end-text'>Thanks for getting in touch. We'll come back to you shortly</p>
                                                </div> :
                                                null
                                            }
                                            <p className='seq-end-text'>Need help? Reach us at partners@sequretek.com</p>
                                        </Form>
                                    )}
                                </Formik>
                            </Fragment>
                        </div>
                    </div>
                </div>

                {/* // Captcha Modal */}
                <Modal
                    show={isConfirm}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    // onHide={() => setIsConfirm(false)}
                    dialogClassName={"confirm-modal"}
                >
                    {
                        loading ?
                            null :
                            <div className='close-sticky'>
                                <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                            </div>
                    }
                    <div className="p-4" >
                        <div>
                            <LoadCanvasTemplate reloadText="Reload" />
                        </div>

                        <div className='form-group mt-3'>
                            <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                        </div>
                        <span style={{ color: 'red' }}>{captchaError}</span>
                        <div className="save-btn1">
                            <button disabled={loading} onClick={() => handleCaptchaSubmit()}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>&nbsp;&nbsp;
                        </div>
                    </div>
                </Modal>
            </div>
        </Fragment >
    )
}


export default PartnerRegistration;
