// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Table } from 'react-bootstrap';
import exportFromJSON from "export-from-json";
import Select, { components } from 'react-select';


// ############################ Require files here ###############################

import './leads.scss';
import Paginations from '../../../utils/pagination';
import Addlead from './addlead';
import { getAllCompanies, getAllPartners, getAllProducts, getLeadExportData, getLeads, addLead, updateLead, deletedLead } from '../../../Redux/ActionCreators/leadsAction';
import { getCountries } from '../../../Redux/ActionCreators/usersAction';
import ConfirmModal from '../../../utils/confirmModal';
import { convertDate } from '../../../utils/miscellneous';
import { TopicLoader } from '../../../Loader/loader';
import { Dialog } from '@mui/material';

const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' width={18} alt='' />
        </components.DropdownIndicator>
    );
};
export default function Leads() {
    const [isAdd, setIsAdd] = useState(false);
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [size, setSize] = useState(10);
    const [partner, setPartner] = useState(null);
    const [product, setProduct] = useState(null);
    const [company, setCompany] = useState(null);
    const [status, setStatus] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isView, setIsView] = useState(false);
    const [leadDetails, setLeadDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: "",
        dial_code: "+1",
        company: "",
        website: "",
        country: "",
        city: "",
        status: "",
        products: [],
        comment: '',
        partner: '',
        attachment: '',
        doc_shared: false
    })
    const dispatch = useDispatch();

    const { isLoading, leads, totalPages, leadStatuses, allPartners, products, companies } = useSelector(state => state.leads);
    const { countries } = useSelector(state => state.users)

    const timerRef1 = React.useRef();

    useEffect(() => {
        dispatch(getAllPartners());
        dispatch(getAllProducts());
        dispatch(getAllCompanies());
        dispatch(getCountries());
        const details = JSON.parse(localStorage.getItem('profileDetails'));
        ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? setPartner({ value: details?.partner_id, label: `${details.fname} ${details?.lname}` }) : setPartner(null);
        dispatch(getLeads(page, size, query, details?.partner_id, '', '', ''));
    }, [])

    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber)
        await dispatch(getLeads(pageNumber, size, query, partner?.value, product?.value, company?.value, status?.value));
    };

    const selectPartner = async (e) => {
        setPartner(e);
        setPage(1);
        setQuery('');
        await dispatch(getLeads(1, size, '', e?.value, product?.value, company?.value, status?.value));
    }
    const selectProduct = async (e) => {
        setProduct(e);
        setPage(1);
        setQuery('');
        await dispatch(getLeads(1, size, '', partner?.value, e?.value, company?.value, status?.value));
    }

    const selectCompany = async (e) => {
        setCompany(e);
        setPage(1);
        setQuery('');
        await dispatch(getLeads(1, size, '', partner?.value, product?.value, e?.value, status?.value))
    }

    const selectStatus = async (e) => {
        setStatus(e);
        setPage(1);
        setQuery('');
        await dispatch(getLeads(1, size, '', partner?.value, product?.value, company?.value, e?.value));
    }

    const userAction = (detail, edit) => {
        const details = JSON.parse(localStorage.getItem('profileDetails'));
        setIsEdit(edit);
        if (detail === null) {
            setIsAdd(true);
            setLeadDetails({
                firstname: '',
                lastname: '',
                email: '',
                mobile: "",
                dial_code: "+1",
                company: "",
                website: "",
                country: "",
                city: "",
                status: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? 'pending' : '',
                products: [],
                comment: '',
                partner: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? details?.partner_id : '',
                attachment: '',
                doc_shared: false
            })
        } else {
            setIsAdd(true);
            const { fname, lname, email, mobile, dial_code, company, website, country, city, status, products, comment, partner, attachments, doc_shared, _id } = detail
            setLeadDetails({
                firstname: fname,
                lastname: lname,
                email: email,
                mobile: mobile,
                dial_code: dial_code,
                company: [{ label: company?.name, value: company?._id }],
                website: website,
                country: country?._id,
                city: city,
                status: status,
                products: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? products?.map(el => el._id) : products?.map(el => [{ label: el.name, value: el._id }][0]),
                comment: comment,
                partner: partner?._id,
                attachment: attachments[0],
                _id: _id,
                doc_shared: doc_shared
            })
        }
    }

    const getProduct = (value) => {
        const data = products.filter(obj => value.includes(obj._id));
        return data?.map(el => [{ name: el.name, _id: el._id }][0])
    }

    const handleSubmit = async (values) => {
        await setLoader(true);
        const data = new FormData();
        data.append("partner_id", values?.partner);
        data.append("fname", values?.firstname);
        data.append('lname', values.lastname);
        data.append('email', values.email);
        data.append('dial_code', values.dial_code);
        data.append('mobile', values.mobile);
        data.append('company', JSON.stringify(values?.company[0]?.__isNew__ ? { name: values?.company[0]?.label } : { _id: values?.company[0]?.value, name: values?.company[0]?.label }));
        data.append('website', values.website);
        data.append('city', values.city);
        data.append('country_id', values.country);
        data.append('status', values?.status === undefined ? '' : values?.status);
        data.append('products',
            ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? JSON.stringify(getProduct(values?.products)) : JSON.stringify(values?.products?.map(el => el.__isNew__ ? [{ name: el.label }][0] : [{ name: el.label, _id: el.value }][0])));
        leadDetails?._id && data.append('_id', leadDetails?._id)
        data.append('comment', values?.comment)
        values?.attachment && data.append('payload', values?.attachment);
        values?.doc_shared && data.append('doc_shared', values?.doc_shared);

        if (!isEdit) {
            const res = await dispatch(addLead(data));
            if (res?.type === 'ADD_LEAD') {
                await setIsAdd(false);
                await dispatch(getAllProducts());
                await dispatch(getAllCompanies());
                setLoader(false);
            }
        } else {
            const res = await dispatch(updateLead(data));
            if (res?.type === 'EDIT_LEAD') {
                await setIsAdd(false);
                dispatch(getLeads(page, size, query, partner?.value, '', '', ''));
                await dispatch(getAllProducts());
                await dispatch(getAllCompanies());
                setLoader(false);
            }
        }
        await setLoader(false);
    }


    const deleteLead = (id) => {
        setDeleteId(id);
        setIsConfirm(true);
    }


    const removeConfirm = async () => {
        const res = await dispatch(deletedLead(deleteId));
        if (res?.type === 'DELETE_LEAD') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }


    const downloadData = async () => {
        await setLoading(true);
        const res = await dispatch(getLeadExportData());
        if (res?.type === 'LEAD_EXPORT_DATA') {

            const data = res?.payload?.map(
                (el) => [
                    {
                        'First Name': el.fname,
                        'Last Name': el.lname,
                        'Email': el.email,
                        'Mobile no.': el.mobile,
                        'Company Name': el.company?.name,
                        'Partner Name': `${el.partner.fname} ${el.partner.lname}`,
                        'Products': el.products.map(el => el.name).join(', '),
                        'Status': el.status,
                        'website': el.website,
                        country: el.country?.name,
                        city: el.city
                    },
                ][0]
            );
            const fileName = "leads";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
        await setLoading(false);
    }

    const openView = async (el) => {
        await setLoading(true);
        await setIsView(true);
        await setLeadDetails(el);
        await setLoading(false);
    }

    const searchLead = (val) => {
        clearTimeout(timerRef1.current);
        setQuery(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch1(val), 800);
    };
    const delaySearch1 = async (val) => {
        dispatch(getLeads(1, size, val, partner?.value, product?.value, company?.value, status?.value));
    }



    return (
        <div className='leads-holder py-2 py-md-2 me-3'>
            {/* LEADS HEADING */}
            {!isAdd ?
                <Fragment>
                    <div>
                        <h2 className="d-flex align-items-center justify-content-between">
                            Leads
                            <div className='leads-btn'>
                                <button style={{ visibility: leads?.length ? 'visible' : 'hidden' }} disabled={loading} onClick={downloadData}>Export </button>
                                <button onClick={() => userAction(null, false)}>Add new Lead&nbsp;&nbsp; <img src='/images/plus-white.svg' alt='' /> </button>
                            </div>
                        </h2>
                    </div>
                    {/* LEADS HOLDER */}
                    <div className='users-table-holder'>
                        <div className='users-header flex-wrap'>
                            <div className='user-input' style={{ width: '35.8%' }}>
                                <div className="has-search"   >
                                    <span className="form-control-feedback">
                                        <img src="/images/search-icon.svg" alt='' width={15} />
                                    </span>
                                    <input type='search' placeholder='Search By Name' className='form-control' value={query} onChange={(e) => searchLead(e.target.value)} />
                                </div>

                            </div>
                            <div className='select-group mt-2 w-100'>
                                {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? null :
                                    <div>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={allPartners}
                                            isClearable
                                            onChange={selectPartner}
                                            placeholder='By Partner'
                                            value={partner}
                                        />
                                    </div>
                                }
                                <div className={((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? "" : 'ms-3'}>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={products}
                                        isClearable
                                        onChange={selectProduct}
                                        placeholder='By Product'
                                        value={product}
                                    />
                                </div>
                                <div className='ms-3'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={companies}
                                        isClearable
                                        onChange={selectCompany}
                                        placeholder='By Company'
                                        value={company}
                                    />
                                </div>
                                <div className='ms-3'>
                                    <Select
                                        components={{ DropdownIndicator }}
                                        options={leadStatuses}
                                        isClearable
                                        onChange={selectStatus}
                                        placeholder='By Status'
                                        value={status}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* LEADS TABLE */}
                        <div className='users-table'>
                            <Table responsive >
                                <thead>
                                    <tr className='table-header'>
                                        {/* <th className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></th> */}
                                        <th>LEAD NAME</th>
                                        <th >COMPANY NAME  </th>
                                        <th >PARTNER NAME</th>
                                        <th className='text-center'>PRODUCTS</th>
                                        <th className='text-center'>ADDED ON</th>
                                        <th className='text-center'>STATUS</th>
                                        <th className='text-center'>ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ?
                                        <tr>
                                            <td colSpan={8}> <TopicLoader /> </td>
                                        </tr>
                                        :
                                        leads?.length > 0 ?
                                            leads?.map((el, i) => {
                                                return (
                                                    <tr>
                                                        {/* <td className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></td> */}
                                                        <td style={{ width: '18%' }}>
                                                            <div className='d-flex align-items-center'>
                                                                {/* <div className='profile-img'>
                                                                    <img src='/images/avatar.svg' alt='' className='rounded-circle' />
                                                                </div>&nbsp;&nbsp;&nbsp; */}
                                                                {el.fname} {el.lname}
                                                            </div>
                                                        </td>
                                                        <td >{el.company?.name}</td>
                                                        <td >{el.partner?.fname} {el.partner?.lname}</td>
                                                        <td style={{ width: '20%' }}>
                                                            <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                                                {el.products?.map(el1 => {
                                                                    return (
                                                                        <Fragment>
                                                                            <div className='product mt-2'>{el1.name}</div>
                                                                            &nbsp;
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                        <td>
                                                            <div className={el.status === 'approved' ? 'active' : el.status === 'rejected' ? 'reject' : 'pending'}>
                                                                {el.status}
                                                            </div>
                                                        </td>
                                                        <td style={{ width: '13%' }} className='cursor-pointer text-center'>
                                                            <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openView(el)} />&nbsp;&nbsp;
                                                            <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={() => userAction(el, true)} />&nbsp;&nbsp;
                                                            {((localStorage.getItem('role') === 'admin') || (localStorage.getItem('role') === 'partner-admin')) ?
                                                                <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => deleteLead(el._id)} /> :
                                                                null
                                                            }
                                                            {/* <img src='/images/vertical-ham.svg' alt='' width={20} height={20} /> */}

                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan={8}>
                                                    <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                            {totalPages > 1 ?
                                <div className='d-flex justify-content-end me-3'>
                                    <Paginations className={'custom-pagination'} totalPages={totalPages} onPageChange={handlePageChange} />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                </Fragment > :
                <Fragment>
                    <Dialog
                        className='screen-loader'
                        open={loader}
                        onClose={() => { }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        Saving...
                    </Dialog>
                    <Addlead
                        setIsAdd={() => setIsAdd(false)}
                        leadDetails={leadDetails}
                        isEdit={isEdit}
                        handleSubmit={handleSubmit}
                        leadStatuses={leadStatuses}
                        allPartners={allPartners}
                        products={products}
                        companies={companies}
                        countries={countries}
                    />
                </Fragment>
            }

            {/* ################################## Confirmation Modal ################################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                type='lead'
                removeConfirm={removeConfirm}
            />

            {/* View Lead Details */}
            <Modal
                show={isView}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"view-modal"}
            >
                <div className='sticky-close'>
                    <img src='/images/close.svg' alt='' onClick={() => setIsView(false)} />
                </div>
                <div className='view-details'>
                    <h3>View Details</h3>
                    {loading ?
                        'Loading...' :
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Lead Name</h5>
                                    <p>{leadDetails?.fname} {leadDetails?.lname}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Email</h5>
                                    <p>{leadDetails?.email}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Mobile No</h5>
                                    <p>{leadDetails?.mobile}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Email</h5>
                                    <p>{leadDetails?.email}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Company Name</h5>
                                    <p>{leadDetails?.company?.name}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Company Website</h5>
                                    <p>{leadDetails?.website}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Country</h5>
                                    <p>{leadDetails?.country?.name}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>City</h5>
                                    <p>{leadDetails?.city}</p>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='view-detail'>
                                    <h5>Lead Status</h5>
                                    <p>{leadDetails?.status}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='view-detail'>
                                    <h5>Products</h5>
                                    <p>{leadDetails?.products?.map(el => el.name).join(', ')}</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='view-detail'>
                                    <h5>Document Shared</h5>
                                    <p>{leadDetails?.doc_shared ? 'Yes' : "No"}</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div >
    )
}
