// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// ############################ Require files here ###############################

import { getRelatedResources, getRelatedResource, unlockResources } from '../../Redux/ActionCreators/resourcesAction';
import { getId } from './downloadData';

export default function RelatedResources({ page, type, title }) {
    const [resource, setResource] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const details = { name: '', email: "", newsletter: true };
    const [url, setUrl] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const firstRender = async () => {
            if (page === 'home') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'platform') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'perceptxdr') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'perceptidentity') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'perceptedr') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'perceptcompliancemanager') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'partners') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'smb') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'financial-services') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'insurance') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'manufacturing') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'healthcare') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'pharma') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'retails') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'services') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else if (page === 'government') {
                const ids = await getId(page);
                await dispatch(getRelatedResource(ids));
            } else {
                await dispatch(getRelatedResources(type, ''));
            }
        }

        firstRender();
    }, [])

    const dispatch = useDispatch();
    const { relatedResources } = useSelector(state => state.resources);

    const validationSchema = Yup.object({
        name: Yup.string()
            .trim('Only alphanumricals allowed')
            .strict(true)
            .matches(/^[a-zA-Z0-9\s]+$/, 'Name should only contain alphabets, numbers, and spaces')
            .required('Name is required'),
        email: Yup.string()
            .required('Email is required')
            .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
    });

    const handleSubmit = async (values) => {
        const { type, title, url } = resource
        let obj = {
            name: values?.name,
            email: values?.email,
            resource_id: resource?._id,
            type: type,
            newsletter: values?.newsletter
        }
        const res = await dispatch(unlockResources(obj));
        if (res?.type === 'UNLOCK_RESOURCES') {

            if (type === 'blog') {
                window.location.href = url
            } else if (type === 'video') {
                navigate(`/resources/video-library/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'advisory') {
                navigate(`/resources/threat-advisories/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'report') {
                navigate(`/resources/report/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'datasheet') {
                navigate(`/resources/datasheet/${encodeURIComponent(title)}`, { state: { data: resource } })
            } else if (type === 'infographic') {
                navigate(`/resources/infographics/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'whitepaper') {
                navigate(`/resources/sequretek-white-paper/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'case-study') {
                navigate(`/resources/case-study/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'podcast') {
                navigate(`/resources/podcasts/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'demo') {
                navigate(`/resources/live-product-demos/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
            else if (type === 'event') {
                window.open(resource?.url, '_blank');
            }
            else if (type === 'factsheet') {
                navigate(`/resources/factsheet/${encodeURIComponent(title)}`, { state: { data: resource } })
            }
        }
        setIsOpen(false)

    }

    const openLock = (e, el) => {
        e.preventDefault();
        e.stopPropagation();
        setResource(el)
        setUrl(el.url);
        setIsOpen(true);
    }

    const openContent = async (e, el) => {
        await setResource(el)
        const { type, title, gated, url } = el
        if (gated) {
            await openLock(e, url)
        } else {
            if (type === 'blog') {
                window.location.href = el.url
            } else if (type === 'video') {
                navigate(`/resources/video-library/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'advisory') {
                navigate(`/resources/threat-advisories/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'report') {
                navigate(`/resources/report/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'datasheet') {
                navigate(`/resources/datasheet/${encodeURIComponent(title)}`, { state: { data: el } })
            } else if (type === 'infographic') {
                navigate(`/resources/infographics/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'whitepaper') {
                navigate(`/resources/sequretek-white-paper/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'case-study') {
                navigate(`/resources/case-study/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'podcast') {
                navigate(`/resources/podcasts/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'demo') {
                navigate(`/resources/live-product-demos/${encodeURIComponent(title)}`, { state: { data: el } })
            }
            else if (type === 'event') {
                window.open(el.url, '_blank')
            }
            else if (type === 'factsheet') {
                navigate(`/resources/factsheet/${encodeURIComponent(title)}`, { state: { data: el } })
            }
        }

    }

    // RELATEDRESOURCES WAS BEEN USED IN Insurance,banking,Manufacturing,Financial Services,Government,Healthcare,Pharmaceuticals,Retail,Services,Small & Medium Businesses,PLATFORM,Percept Identity,Percept XDR and NGSIEM,Percept EDR,Percept Compliance ManageResources,Home Page,PARTNERS, ALL BLOG PAGES REPORTS AND VIDEO PAGES

    return (
        <Fragment>
            {page === 'resources' ?
                relatedResources?.filter(el => el.title !== title)?.slice(0, 3)?.map(el => {
                    return (
                        <div className={window.innerWidth > 767 ? 'col-md-4 col-sm-6 d-flex align-self-stretch cursor-pointer mt-3' : 'col ps-0 cursor-pointer mt-3 '} loading="lazy">
                            <div className="card" onClick={(e) => openContent(e, el)} style={{ width: window.innerWidth > 767 ? '' : '280px', height: window.innerWidth > 767 ? '260px' : '280px' }} >
                                <img className="card-img-top position-relative" src={el.image} alt='' loading="lazy" />
                                <div className='type'>
                                    <div className='type-1' >
                                        {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                            el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' />
                                                    : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> :
                                                        el.type === 'factsheet' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                        }&nbsp;&nbsp;
                                        {el.type}
                                    </div>
                                    {el.gated ? <img src='/images/lock-icon.svg' onClick={(e) => openLock(e, el)} alt='' className='type-2' /> : null}
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title elipsis">{el.title}</h5>
                                </div>
                            </div>
                        </div>
                    )
                }) :

                relatedResources?.slice(0, 4)?.map(el => {
                    return (
                        <div className={window.innerWidth > 767 ? 'col-md-3 card-item col-sm-6 d-flex align-self-stretch cursor-pointer mt-3' : 'col card-item col-sm-6 cursor-pointer mt-3 '} loading="lazy">
                            <div className="card" onClick={(e) => el.gated ? openLock(e, el) : openContent(e, el)} style={{ width: window.innerWidth > 767 ? '' : '280px', height: window.innerWidth > 767 ? '260px' : '282px' }} >
                                <img className="card-img-top position-relative" src={el.image ? el.image : "/images/sample1.png"} alt='' loading="lazy" />
                                <div className='type'>
                                    <div className='type-1' >
                                        {el.type === 'report' ? <img src='/images/report-icon.svg' alt='' /> : el.type === 'advisory' ? <img src='/images/advisory-icon.svg' alt='' /> : el.type === 'podcast' ? <img src='/images/podcast-icon.svg' alt='' /> :
                                            el.type === 'demo' ? <img src='/images/demo-icon.svg' alt='' /> : el.type === 'datasheet' ? <img src='/images/datasheet-icon.svg' alt='' /> : el.type === 'case-study' ? <img src='/images/case-study-icon.svg' alt='' /> :
                                                el.type === 'blog' ? <img src='/images/blog-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'infographic' ? <img src='/images/infographics.svg' alt='' /> : el.type === 'whitepaper' ? <img src='/images/whitepaper-icon.svg' alt='' /> : el.type === 'event' ? <img src='/images/event-icon.svg' alt='' /> : el.type === 'video' ? <img src='/images/video-icon.svg' alt='' /> : el.type === 'factsheet' ? <img src='/images/webinar-icon.svg' alt='' /> : null
                                        }&nbsp;&nbsp;
                                        {el.type}
                                    </div>
                                    {el.gated ? <img src='/images/lock-icon.svg' alt='' className='type-2' /> : null}
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title elipsis">{el.title}</h5>
                                </div>
                            </div>
                        </div>
                    )
                })
            }


            {/* ##############Add modal ########################### */}
            <Modal
                show={isOpen}
                size="md"
                aria-labelledby="contained-modal-label-vcenter"
                centered
                dialogClassName={"unlock-modal"}
            >
                <Modal.Body style={{ position: 'relative', padding: '35px 40px' }}>
                    <img src='/images/unlock.svg' alt='' />
                    <h4>Unlock Resource</h4>
                    <p>To access this resource enter your business email </p>
                    <div
                        className="sticky-close"
                        onClick={() => setIsOpen(false)}
                    >
                        <img src="/images/close-white.svg" width="15px" alt="" />
                    </div>
                    <div className=''>
                        <Formik initialValues={details} validationSchema={validationSchema} onSubmit={handleSubmit}>
                            {() => (
                                <Form className="user_form">
                                    <div className="row mt-2">
                                        <div className="col-12 col-md-12 mt-2">
                                            <div className="form-group">
                                                <Field type="text" name="name" className="form-control" placeholder="*Name" />
                                                <ErrorMessage name="name" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12">
                                            <div className="form-group">
                                                <Field type="text" name="email" className="form-control" placeholder="*Business Email" />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-12 mt-2">
                                            <div className='form-group'>
                                                <div className="form-check form-check-inline">
                                                    <Field
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="checkbox"
                                                        name='newsletter'
                                                    // value={false}

                                                    />
                                                    <label className="form-check-label" htmlFor="checkbox">
                                                        Receive latest threat advisory and newsletters from Sequretek
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className='w-100 button-color h-48' >Submit</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
