// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));



const challenges = [{ image: '/images/folderopen.svg', title: 'Data Breaches', description: 'Instances of cybercriminals accessing customer information have risen. Data breaches erode customer trust, trigger fines, and cost retailers.' },
{ image: '/images/person.svg', title: 'Insider Threats', description: `Employees leaking sensitive information (accidentally or otherwise), mishandling data, or falling for scams can cause big problems.` },
{ image: '/images/cloud.svg', title: 'Third-Party Vulnerabilities', description: 'A security vulnerability in any third-party vendor system (payment processors, logistics partners, etc.) compromises core retail systems.' },
{ image: '/images/broken-chain-link-wrong.svg', title: 'Supply Chain Vulnerabilities', description: `Global supplier networks are potential risks. A supplier's data breach, for example, could leak a retailer's customer information.` },
{ image: '/images/shieldsecurity.svg', title: 'Ransomware', description: 'Attackers encrypt critical data, lock systems down, and demand a ransom for decryption. This evolving threat is a menace. ' },
{ image: '/images/mask.svg', title: 'Social Engineering', description: `Cybercriminals tricking employees into divulging sensitive information or granting access to systems is a serious cybersecurity threat for retailers.` },
{ image: '/images/risk.svg', title: 'Point-of-Sale (POS) Malware', description: `Malicious software that can steal customer payment card data directly from POS systems is a retail security hole.` },
{ image: '/images/shield-exclamation.svg', title: 'Data Security', description: `Retailers’ information base (customer, financial, purchase histories) is vulnerable to identity theft, credit card fraud, or dark web sales.` },
{ image: '/images/globe.svg', title: 'Global Compliance Maze', description: `Navigating the intricate regulatory framework across geographies like the US & India retail sectors is crucial for online and brick-and-mortar businesses.` },
{ image: '/images/block-star.svg', title: 'Emerging Technologies', description: `The adoption of cutting-edge technologies such as IoT, AI, and Big Data, fuel innovation but leave retailers vulnerable.` },
{ image: '/images/queue.svg', title: 'Unaware Workforce', description: `Companies with under-trained, unaware, or overly curious employees are vulnerable to exploitation by cyber adversaries.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (PCTEM) continuously monitors IT infrastructure for threats, and mitigates potential breaches.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time. ' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: 'Our governance and compliance reports help meet regulations like FTC, SEC, GDPR, RBI, DPDPA, PCI-DSS, and others.' },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]


export default function Retails() {
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 5200);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad1791e5c52d1577f9195']));
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sequretek | Boost Customer Trust with Retail Cybersecurity</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Omnichannel retail demands a delicate balance between customer experience and cybersecurity. Discover how robust, tailored cybersecurity solutions can protect your business, build trust, and drive loyalty." />
                <meta name="keywords" content="ecommerce security, cybersecurity for retail" />
                <meta property="og:title" content="Sequretek | Boost Customer Trust with Retail Cybersecurity" />
                <meta property="og:description" content="Omnichannel retail demands a delicate balance between customer experience and cybersecurity. Discover how robust, tailored cybersecurity solutions can protect your business, build trust, and drive loyalty." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: `linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/retail-bgimg.png')` }}>
                <div className='seq-description seq-des-retail'>
                    <h5 data-aos="fade-up">Industries {'>'} Retail </h5>
                    <h2 data-aos="fade-up" className='mx-10'>Strengthen Security, Win Trust, Earn Loyalty</h2>
                    <div data-aos="fade-up" className='text-center'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row  me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>Convenience vs. Cybersecurity: Retail's Tech Trade-Off</h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>Omnichannel retail presents unique challenges for customer experience and cybersecurity due to the blend of physical stores and high-tech systems. </p><br />
                            <p> Complex operations, high employee turnover, and disparate IT infrastructure models make balancing physical stores' and e-commerce security with a seamless customer experience a challenge.
                                The answer lies in proactive, robust and customized retail cybersecurity. Transparency in communication about breaches, and post-breach responses builds trust and customer lifetime value.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 METRICS */}
            <div className='seq-content-2security' data-aos="fade-up" style={{ backgroundImage: window.innerWidth > 767 ? `url('/images/bg-img72.svg')` : `url('/images/bg-img73.svg')` }}>
                <div className='container px-0'>
                    <div className='seq-cybersecurity'>
                        <div className='seq-cybersecurity-head'>
                            <h4>Retail Cybersecurity Insights</h4>
                        </div>
                        <div className='row seq-cybersecurity-body'>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/usd-circle.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>$2.96 million</h5>
                                    <p style={{ color: "#B6BCBF" }}>Average Cost of Retail Data Breach</p>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/shield-exclamation.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>59%</h5>
                                    <p style={{ color: "#B6BCBF" }}>Businesses Affected by Third-Party Breaches</p>
                                </div>
                            </div>
                            <div className='col-md-4 col-12 seq-cybersecurity-content'>
                                <img src='/images/brake-warning.svg' alt='' style={{ width: "48px", height: "48px" }} loading="lazy" />
                                <div>
                                    <h5 style={{ color: "#FFFFFF" }}>$51 billion</h5>
                                    <p style={{ color: "#B6BCBF" }}>Exposed to Business Email Compromise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='seq-cybersecurity seq-cybersecurity-man'>
                        {window.innerWidth > 767 ?
                            <div>
                                <div>
                                    <div className='seq-cybersecurity-head'>
                                        <h4>Regulatory Bodies and Acts:</h4>
                                    </div>
                                    <div className='seq-cybersecurity-body'>
                                        <div className='d-flex align-items-center justify-content-center seq-cybersecurity-body-items'>
                                            <h5 style={{ color: "#FFFFFF" }}>Geo-specific Laws</h5>
                                            <h5 style={{ color: "#FFFFFF" }}>Federal Trade Commission (FTC)</h5>
                                            <h5 style={{ color: "#FFFFFF" }}>Securities and Exchange Commission (SEC)</h5>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center seq-cybersecurity-body-items'>
                                            <h5 style={{ color: "#FFFFFF" }}>General Data Protection Regulation (GDPR)</h5>
                                            <h5 style={{ color: "#FFFFFF" }}>Central Consumer Protection Authority (CCPA)</h5>
                                            <h5 style={{ color: "#FFFFFF" }}>Payment Card Industry Data Security Standard (PCI-DSS)</h5>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-center seq-cybersecurity-body-items'>
                                            <h5 style={{ color: "#FFFFFF" }}>Digital Personal Data Protection Act (DPDPA)</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div>
                                    <div className='seq-cybersecurity-head'>
                                        <h4>Regulatory Bodies and Acts:</h4>
                                    </div>
                                    <ul className=' seq-cybersecurity-body'>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Geo-specific laws</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Federal Trade Commission (FTC)</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Securities and Exchange Commission (SEC)</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>General Data Protection Regulation (GDPR)</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Central Consumer Protection Authority (CCPA)</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Payment Card Industry Data Security Standard (PCI-DSS)</li>
                                        <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Digital Personal Data Protection Act (DPDPA)</li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='seq-cybersecurity seq-cybersecurity-man'>
                        {window.innerWidth > 767 ?
                            <div>
                                <div className='seq-cybersecurity-head'>
                                    <h4>Potential Penalties</h4>
                                </div>
                                <div className='row seq-cybersecurity-body'>
                                    <div className='col-4 d-flex justify-content-center seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>Steep Fines</h5>
                                    </div>
                                    <div className='col-4 d-flex justify-content-center seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>Reputational Damage</h5>
                                    </div>
                                    <div className='col-4 d-flex justify-content-center seq-cybersecurity-defences'>
                                        <h5 style={{ color: "#FFFFFF" }}>Lawsuits</h5>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className='seq-cybersecurity-head'>
                                    <h4>Potential Penalties</h4>
                                </div>
                                <ul className=' seq-cybersecurity-body'>
                                    <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Steep fines</li>
                                    <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Reputational damage</li>
                                    <li className='seq-cybersecurity-defences' style={{ color: "#FFFFFF" }}>Lawsuits</li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* section 4 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Challenges faced</p>
                <h3>Register Rampage
                    <br />
                    <span style={{ color: '#B6BCBF' }}>Security Gaps Costing Retailers Money and More</span> </h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 330 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    challenges?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 5 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: `url('/images/bg-img62.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>How Sequretek Helps Order-to-Shelf Security Concerns                                </h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 6 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'retail'}
                />
            </div>
            {/* section 7 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img63.svg'}
                            mobilebg={'/images/bg-img64.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 8 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='retails'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div >
    )
}