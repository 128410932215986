// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));



const risks = [
    { image: '/images/folderopen.svg', title: 'Data Breach and IP Theft', description: 'A pharma cyberattack can expose pharma IT and data assets to threat actors, leading to reputational and financial losses.' },
    { image: '/images/shieldsecurity.svg', title: 'Ransomware Risk', description: 'Pharma cyberattacks in the form of ransomware exfiltrate and encrypt critical data, disrupt operations, and cause control loss.' },
    { image: '/images/fostering.svg', title: 'Compliance Regulations', description: 'Mandatory compliance with all regulations like HIPAA, GLP, HITRUST, Compliance Monitoring Authority, and DST is complex and demanding.' },
    { image: '/images/shield-exclamation.svg', title: 'Expanding Attack Surface', description: `The proliferation of IT, OT, and Internet of Things (IoT) devices creates a larger attack surface and exposes new vulnerabilities.` },
    { image: '/images/person.svg', title: 'Insider Threat', description: 'Insiders, contractors and ecosystem users with legitimate access can abuse their privileges, steal data, sabotage systems, or introduce malware.' },
    { image: '/images/broken-chain-link-wrong.svg', title: 'Supply Chain Vulnerabilities', description: 'Reliance on third-party supply chains introduces weaknesses that attackers can exploit to access pharma assets.' },
    { image: '/images/mask.svg', title: 'Social Engineering Attacks', description: `Employees lacking cybersecurity awareness make their organizations vulnerable to attacks like phishing and other such attacks.` },
    { image: '/images/brake-warning.svg', title: 'Cyber Sabotage', description: `Persistent attackers apply AI-based reconnaissance and infiltrate systems, disrupting logistics and operations.` },
    { image: '/images/queue.svg', title: 'Unaware Workforce', description: `Companies with under-trained, unaware, or overly curious employees are vulnerable to exploitation by cyber adversaries.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Sequretek's Percept Continuous Threat Exposure Management (CTEM) continuously monitors IT infrastructure for threats and mitigates potential breaches.` },
{ image: '/images/shield.svg', title: 'Threat Detection and Response', description: 'Advanced cyber risk management with our EDR, XDR & NG SIEM, SOAR products and services help monitor, detect, and respond to cyberattacks in real-time. ' },
{ image: '/images/alert.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/user-key.svg', title: 'Conformance to Compliance Mandates', description: ` Our governance and compliance reports help comply with HIPAA, HITRUST, FDA guidelines, SEC, CERT-In and other regulations.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]



export default function Pharmaceuticals() {

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4100);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66dad12f1e5c52d1577f918a']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }


    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Pharma Cyber Resilience: Building a Secure Future Together</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Safeguard your pharmaceutical assets from cyber threats. Mitigate risks, ensure compliance, and build trust with robust cybersecurity." />
                <meta name="keywords" content="Pharma Cyber Attack, Pharma Cyber security, Supplychain, FDA Guidelines" />
                <meta property="og:title" content="Pharma Cyber Resilience: Building a Secure Future Together" />
                <meta property="og:description" content="Safeguard your pharmaceutical assets from cyber threats. Mitigate risks, ensure compliance, and build trust with robust cybersecurity." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: ` linear-gradient(4.09deg, rgba(6, 6, 93, 0.7) 4%, rgba(4, 12, 48, 0.9) 18.36%, rgba(4, 12, 48, 0.9) 55.89%, rgba(6, 6, 93, 0.7) 96.66%),url('/images/pharmaceuticals-bgimg.png')` }}>
                <div className='seq-description'>
                    <h5 data-aos="fade-up">Industries {' > '} Pharmaceuticals </h5>
                    <h2 data-aos="fade-up">Pharma Cybersecurity:
                        <p>Building Resilience Against Modern Cyberthreats</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Securing Pharma Assets:
                                <br />
                                Defending Data and Operations in the Digital Age.
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>The pharmaceutical sector is at the forefront of healthcare advances with invaluable intellectual property (IP) forming the backbone of the industry's success. Potential problems like patent infringement, data leaks, and counterfeit production are always a financial and health concern. Pharma cybersecurity challenges involve issues such as disruption of services through ransomware and identity-based threats. Regulatory non-compliance leads to hefty fines, legal obligations, and a tarnished reputation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 CHALLENGES */}
            <div className='seq-challenge'>
                <h3>Pharma Cybersecurity Landscape {window.innerWidth > 767 ? null : <span>: Navigating Key Security Issues</span>}
                    <br />
                    {window.innerWidth > 767 ? <span style={{ color: '#B6BCBF' }}>Navigating Key Security Issues</span> : null}</h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 305 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>
            </div>
            {/* section 4 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg' style={{ backgroundImage: `url('/images/bg-img61.svg')` }}>
                        <div className='row '>
                            <div data-aos="fade-up" className=' col-md-5 col-12 seq-help-left'>
                                <p>How Sequretek Helps</p>
                                <h3>Closing Pharma Security Gaps: Strengthening Compliance, Access Control, and Threat Response</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4  offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list '>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'pharma'}
                />
            </div>
            {/* section 6 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container ps-4 pe-4 '>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img74.svg'}
                            mobilebg={'/images/bg-img71.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 7 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='pharma'
                                    type=''
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
