import React, { useState, useEffect } from 'react';
import '../Leads/leads.scss';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { getAllConsumedResources, getNextConsumedResouces } from '../../../Redux/ActionCreators/dashboardAction';
import { TopicLoader } from '../../../Loader/loader';
import { convertDate } from '../../../utils/miscellneous';
import { useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

export default function ConsumeResources() {
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setPage(1);
        dispatch(getAllConsumedResources(1, location.state.id ? location.state.id : ""));
    }, [])

    const { contentLoading, consumeResources } = useSelector(state => state.dashboard);

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextConsumedResouces(page, location.state.id ? location.state.id : ""))
    }

    const searchName = () => {

    }

    return (
        <div className='leads-holder py-2 py-md-2 me-3' >
            {/* consumeResources CODE */}
            <div >
                <h2 className="d-flex align-items-center justify-content-between">
                    Consumed Resources
                    {/* <div className='leads-btn'>
                        <button style={{ visibility: consumeResources?.length ? 'visible' : 'hidden' }} disabled={loading} onClick={downloadData}>Export </button>
                        <button onClick={() => userAction(null, false)}>Add new Deal&nbsp;&nbsp; <img src='/images/plus-white.svg' alt='' /> </button>
                    </div> */}
                </h2>
            </div>
            <div className='users-table-holder'>
                <div className='users-header flex-wrap'>
                    <div className='user-input' style={{ width: '35.8%' }}>
                        {/* <div className="has-search"   >
                            <span className="form-control-feedback">
                                <img src="/images/search-icon.svg" alt='' width={15} />
                            </span>
                            <input type='search' placeholder='Search By Name' className='form-control' value={query} onChange={(e) => searchName(e.target.value)} />
                        </div> */}

                    </div>
                </div>
                <div className='users-table'>
                    {consumeResources &&
                        <InfiniteScroll
                            dataLength={consumeResources && consumeResources?.length} //This is important field to render the next data
                            next={fetchData}
                            hasMore={true}
                        >
                            <Table responsive >
                                <thead>
                                    <tr className='table-header'>
                                        <th>Resource</th>
                                        <th>Resource Type</th>
                                        {/* <th>Partner Name   </th> */}
                                        <th className='text-center'>User Name</th>
                                        <th className='text-center'>Viewed Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contentLoading ?
                                        <tr>
                                            <td colSpan={4}> <TopicLoader /> </td>
                                        </tr>
                                        :
                                        consumeResources?.length > 0 ?
                                            consumeResources && consumeResources?.map((el, i) => {
                                                return (
                                                    <tr>
                                                        <td>{el.title}</td>
                                                        <td className='text-capitalize'>{el.type}</td>
                                                        {/* <td >{el.partner?.name}</td> */}
                                                        <td style={{ width: '12%' }}>
                                                            <div className='d-flex align-items-center'>
                                                                {el?.user?.fname} {el?.user?.lname}
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>{convertDate(el.createdAt)}</td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan={7}>
                                                    <div className='empty-list'>
                                                        <img src='/images/empty.png' alt='' />
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                </tbody>
                            </Table>
                        </InfiniteScroll>}
                </div>
            </div>
        </div >
    )
}
