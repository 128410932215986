import { React } from 'react';

const ShareUrl = ({ details }) => {


    const getShareUrl = (sharesite) => {
        switch (sharesite) {
            case 'facebook':
                if (navigator.userAgent.match(/iPhone|Android/i)) {
                    if (/iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent))) {

                        window.location.href = `https://www.facebook.com/dialog/share?app_id= 1675249859222150&display=popup&href=${encodeURIComponent(details?.url)}`
                    }
                    else {
                        window.open(`https://www.facebook.com/dialog/share?app_id=1675249859222150&display=popup&href=${encodeURIComponent(details?.url)}`)
                    }
                }
                else {
                    window.open(`http://www.facebook.com/sharer/sharer.php?s=100&p[title]=${details?.title}&p[url]=${details?.url}&p[images][0]=${details?.image},sharer,toolbar=0,status=0,width=600px,height=600px`)
                }
                break
            case 'twitter':
                if (/iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent))) {
                    window.location.href = `http://twitter.com/share?url=${details?.url}&text=${details?.title},sharer,toolbar=0,status=0,width=626,height=436`
                }
                else {

                    window.open(`http://twitter.com/share?url=${details?.url}&text=${details?.title},sharer,toolbar=0,status=0,width=626,height=436`)
                }
                break
            case 'linkedin':
                if (/iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent))) {
                    window.location.href = `http://www.linkedin.com/shareArticle?url=${encodeURIComponent(details?.url)}&title=${details?.title},_blank,width=500,height=500,resizable=yes,scrollbars=yes`;
                }
                else {

                    window.open(`http://www.linkedin.com/shareArticle?url=${encodeURIComponent(details?.url)}&title=${details?.title},_blank,width=500,height=500,resizable=yes,scrollbars=yes`)
                }
                break
            case 'whatsapp':
                if (navigator.userAgent.match(/iPhone|Android/i)) {
                    if (/iP(ad|od|hone)/i.test(window.navigator.userAgent) && /WebKit/i.test(window.navigator.userAgent) && !(/(CriOS|FxiOS|OPiOS|mercury)/i.test(window.navigator.userAgent))) {
                        window.location.href = `https://api.whatsapp.com/send?text=${details?.url},toolbar=0,status=0,width=626,height=436`;
                    } else {

                        window.open(`https://api.whatsapp.com/send?text=${details?.url},toolbar=0,status=0,width=626,height=436`)
                    }
                }
                else {
                    window.open(`https://web.whatsapp.com/send?l=en&text=${details?.url}`)
                }
                break;
            case 'email':
                // window.open('mailto:address@mail.com?subject=sub&body=this is body')
                window.location.href = `mailto:?subject=${details?.url}&body=${details?.url}`;
                break;
            default:
                copy(details?.url)
                break
        }
    }

    const copy = async (url) => {
        let text = url;
        try {
            await navigator.clipboard.writeText(text);
        } catch (err) {
        }
    }


    return (
        <div>
            {window.innerWidth > 767 ?
                <div className='share-div '>
                    <p>Share this item </p>&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src='/images/twitter-icon-white.svg' alt='' onClick={() => getShareUrl('twitter')} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src='/images/facebook-icon-white.svg' alt='' onClick={() => getShareUrl('facebook')} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src='/images/linkedin-icon-white.svg' alt='' onClick={() => getShareUrl('linkedin')} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src='/images/email-icon-white.svg' alt='' onClick={() => getShareUrl('email')} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img src='/images/copy-icon.svg' alt='' onClick={() => getShareUrl('')} />
                </div> :
                <div className='share-div d-block '>
                    <p>Share this item </p>
                    <div className='mt-3 d-flex justify-content-between align-items-center' style={{ width: '75%' }}>
                        <img src='/images/twitter-icon-white.svg' alt='' onClick={() => getShareUrl('twitter')} />&nbsp;&nbsp;
                        <img src='/images/facebook-icon-white.svg' alt='' onClick={() => getShareUrl('facebook')} />&nbsp;&nbsp;
                        <img src='/images/linkedin-icon-white.svg' alt='' onClick={() => getShareUrl('linkedin')} />&nbsp;&nbsp;
                        <img src='/images/email-icon-white.svg' alt='' onClick={() => getShareUrl('email')} />&nbsp;&nbsp;
                        <img src='/images/copy-icon.svg' alt='' onClick={() => getShareUrl('')} />
                    </div>
                    <div style={{ width: '90%', marginTop: 25, border: '1px solid #0E49B5' }}></div>
                </div>
            }
        </div>
    )
}

export default ShareUrl
