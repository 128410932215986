// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';


// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs16() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Analysis of Chaos Ransomware</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Analysis of Chaos Ransomware</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Analysis of Chaos Ransomware</h3>
                        <p>02-JUNE-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>Chaos is an evolved iteration of the RYUK ransomware, and it was initially identified in June 2021. This ransomware comes in multiple versions. In this specific version, the file extensions of encrypted files are substituted with a series of random characters that represent the ransom. The Chaos ransomware is compiled as a .NET executable using <strong>AES</strong> and <strong>RSA</strong> algorithms to encrypt the data of the victim system.</p>
                            <h4>Static Analysis:</h4>
                            <p>The primary function of Chaos is self-explanatory. Initially, it checks for any duplicate instances to avoid conflicting operations. Then, it enters a sleep mode for a specified period, as configured. Following that, it attempts to establish persistence and scans directories to locate files for encryption. Additionally, Chaos propagates itself by including a ransom note alongside its spread.</p>
                            <img src='/images/blog-images/sample131.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure1: Chaos Main function loop</p>
                            <br />
                            <p>The malware analysis team at Sequretek conducted an analysis on a malware sample to determine how it infects systems. During their investigation, they identified specific functions used by the ransomware for malicious purposes. In the case of Chaos ransomware, it has been observed that after execution.</p>
                            <p>When executed, the malware carries out the following actions:</p>
                            <ul>
                                <li><p>It scans for any duplicate instances running and gathers information about the system’s drives.</p></li>
                                <li><p>It disables the Windows recovery setting or deletes shadow copies, making it harder to recover the system.</p></li>
                                <li><p>The malware makes copies of itself and stores them in different locations on the infected machine.</p></li>
                                <li><p>It proceeds to encrypt specific file extensions, rendering them inaccessible without the decryption key.</p></li>
                                <li><p>The malware alters the victim’s desktop wallpaper, potentially displaying a ransom message or other intimidating content.</p></li>
                                <li><p>Finally, it generates a ransom note demanding payment and provides instructions on how to make the ransom payment.</p></li>
                                <h4>File Details:</h4>
                                <Table responsive>
                                    <tr>
                                        <th><p>MD5</p></th>
                                        <th><p>9B02B542834573F9502CA83719A73A01</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>SHA-1</p></th>
                                        <th><p>F3BC7CF16EEC977772455F3FCE87FED505FB18E3</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>SHA-256</p></th>
                                        <th><p>e48bd2f16b53a3630f3fca69d0d236d15bc23b08754d980bd29b15841b0fdf14</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>File type</p></th>
                                        <th><p>Win32 EXE</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>Target Machine</p></th>
                                        <th><p>x32</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>File size</p></th>
                                        <th><p>137.50 KB (140800 bytes)</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>Compiler</p></th>
                                        <th><p>Portable Executable 32 .NET Assembly</p></th>
                                    </tr>
                                    <tr>
                                        <th><p>Signature</p></th>
                                        <th><p>Not signed</p></th>
                                    </tr>
                                </Table>
                                <li><p>The malware checks for a specific directory within the Temp folder. If the directory is not found, the malware will sleep for the designated time period indicated above.</p></li>
                                <img src='/images/blog-images/sample132.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 2: Checking directories in Temp Folder</p>
                                <br />
                                <li><p>The “Assembly.GetExecutionAssembly” function offers capabilities to examine the ongoing activity of running processes and their current state.</p></li>
                                <img src='/images/blog-images/sample133.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 3: Check running processes</p>
                                <br />
                                <li><p>The ransomware’s hardcoded functions utilize the “Get Filename” method to rename file extensions and validate them for encryption using the “valid Extensions” function.</p></li>
                                <img src='/images/blog-images/sample134.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 4 Encrypt file extension from function</p>
                                <br />
                                <li><p>Inside the “C://” drive of the victim’s machine, the function examines the drive name using the “Get Drive” method to identify and encrypt files based on the listed extensions, as depicted in Figure 6.</p></li>
                                <img src='/images/blog-images/sample135.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 5 Looking for directories on victim machine</p>
                                <br />
                                <li><p>This version of Chaos targets the following list of extensions for encryption.</p></li>
                                <img src='/images/blog-images/sample136.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 6 Name of extension used for encryption</p>
                                <br />
                                <li><p>Additionally, the ransomware creates a shortcut file within the startup folder and sets the file path.</p></li>
                                <img src='/images/blog-images/sample137.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 7 function of startup setting</p>
                                <br />
                                <li><p>Furthermore, during the analysis, we discovered a hardcoded function named “Open Note” that includes the ransom note for bitcoins. The content of the ransom note resides in the “droppedMessageTextbox” and is designated as “andrianov.txt“.</p></li>
                                <img src='/images/blog-images/sample138.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 8 Contain hardcoded ransom note function</p>
                                <br />
                            </ul>
                            <p>The ransomware employs the following commands to disable the Windows recovery setting or delete shadow copies:</p>
                            <br />
                            <p>To delete shadow copies:</p>
                            <ul>
                                <li><p>“Vssadmin delete shadows /all /quiet”.</p></li>
                                <li><p>“wmic shadow copy delete”</p></li>
                            </ul>
                            <p>To disable Recovery Mode:</p>
                            <ul>
                                <li><p>“bcdedit /set default bootstatuspolicy ignoreallfailures”</p></li>
                                <li><p>“bcdedit /set default recovery enabled no”</p></li>
                            </ul>
                            <p>To delete the Backup Catalog:</p>
                            <ul>
                                <li><p>“wbadmin delete catalog -quiet”</p></li>
                            </ul>
                            <img src='/images/blog-images/sample139.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 9 function for delete shadow copy</p>
                            <h4>Dynamic Analysis:</h4>
                            <p>During the execution of the payload in our sandbox environment to observe its behavior, we noticed that Chaos does not become active immediately after execution. It takes some time to activate its behavior, as mentioned statically.</p>
                            <p>After the main payload is executed, the following important activities are carried out:</p>
                            <ul>
                                <li><p>The malware makes a copy of itself in another location.</p></li>
                                <li><p>Before encrypting the victim’s machine, it deletes the shadow copy.</p></li>
                                <li><p>To terminate the runtime running process, the malware creates a copy of itself and executes svchost.exe from the “%appdata/Roaming%” path.</p></li>
                            </ul>
                            <p>Here is the step-by-step process followed by the malware:</p>
                            <ul>
                                <li><p>The first step involves checking for any other instances that may already be running. If such instances are found, the malware terminates itself.</p></li>
                                <li><p>If no other instances are detected, the malware drops a copy of itself at a specific location. It then waits for a short period of time before executing itself.</p></li>
                                <img src='/images/blog-images/sample140.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 10 Coping svchost.exe in appdata location</p>
                                <li><p>Create the shortcut link in startup folder to execute itself</p></li>
                                <img src='/images/blog-images/sample141.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 11 Set a link in the startup by shortcut</p>
                                <br />
                                <li><p>Encrypt specific file-extensions</p></li>
                                <p>Chaos ransomware propagates its encryption process across the listed drives using the Drive Info class. This class is responsible for retrieving local drive information by utilizing the GetDrives () and GetLogicalDrives () functions from the Win32 native API.</p>
                                <img src='/images/blog-images/sample142.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 12 Collect local drive information</p>
                                <br />
                                <p>After gathering information about the local drive, the ransomware proceeds to encrypt the files on the victim’s machine. It utilizes the extension “*.1iyT6baVyWM5” to override the original file extension, effectively changing the file extensions of the encrypted files.</p>
                                <img src='/images/blog-images/sample143.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 13 Encrypted file extension name (1iyT6baVyWM5)</p>
                                <br />
                                <img src='/images/blog-images/sample144.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 14 Encrypted files</p>
                                <br />
                                <li><p>Set desktop wallpaper in victim system</p></li>
                                <p>Once the files are encrypted, the malware triggers a function that sets the wallpaper on the victim’s computer. This function displays the wallpaper as a “jpg” image format.</p>
                                <img src='/images/blog-images/sample145.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 15 Chaos set wallpaper</p>
                                <br />
                                <li><p>Creates a ransom note and demand ransom</p></li>
                                <p>The ransom note string function instructs the user to send 200 dollars to a specific bitcoin wallet address, <strong>“3QpLGGaeFwxtV61p1bBUpTBzPcKdtPQpNA”</strong>, which is mentioned in the file named <strong>“andrianov.txt“</strong>. Once the transaction is confirmed, the user is instructed to send an email to <strong>“leonid.andrianoviaa@mail.ru”</strong> for file recovery.</p>
                                <img src='/images/blog-images/sample146.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 16 Ransom note</p>
                                <br />
                            </ul>
                            <h4>Detection Indicators:</h4>
                            <ul>
                                <li><p>Hash value of the sample analyzed is “9B02B542834573F9502CA83719A73A01”.</p></li>
                                <li><p>The payload of the ransomware has encrypted system files and generated a ransom note providing payment instructions.</p></li>
                                <li><p>The ransomware payload includes a bitcoin wallet address, “3QpLGGaeFwxtV61p1bBUpTBzPcKdtPQpNA”, for payment purposes.</p></li>
                                <li><p>After the transaction is confirmed, the ransomware sends an email via “leonid.andrianoviaa@mail.ru” for file recovery.</p></li>
                            </ul>
                            <h4>MITRE ATT&CK® Tactics and Techniques:</h4>
                            <br />
                            <h4>Execution TA0002</h4>
                            <ul>
                                <li><p>Scheduled Task/Job T1053</p></li>
                                <li><p>Creates COM task schedule object (often to register a task for auto start)</p></li>
                            </ul>
                            <h4>Persistence TA0003</h4>
                            <ul>
                                <li><p>Scheduled Task/Job T1053</p></li>
                                <li><p>Creates COM task schedule object (often to register a task for auto start)</p></li>
                            </ul>
                            <h4>Registry Run Keys / Startup Folder T1547.001</h4>
                            <ul>
                                <li><p>Stores files to the Windows startup directory</p></li>
                                <li><p>Creates a start menu entry (Start Menu\\Programs\\Startup)</p></li>
                            </ul>
                            <h4>Privilege Escalation TA0004</h4>
                            <ul>
                                <li><p>Scheduled Task/Job T1053</p></li>
                                <li><p>Creates COM task schedule object (often to register a task for autostart)</p></li>
                            </ul>
                            <h4>Registry Run Keys / Startup Folder T1547.001</h4>
                            <ul>
                                <li><p>Stores files to the Windows startup directory</p></li>
                                <li><p>Creates a start menu entry (Start Menu\\Programs\\Startup)</p></li>
                            </ul>
                            <h4>Defense Evasion TA0005</h4>
                            <ul>
                                <li><p>Obfuscated Files or Information T1027</p></li>
                                <li><p>Encode data using Base64</p></li>
                                <li><p>Encrypt data using AES via .NET</p></li>
                                <li><p>.NET source code contains long base64-encoded strings</p></li>
                            </ul>
                            <h4>Masquerading T1036</h4>
                            <ul>
                                <li><p>Creates files inside the volume driver (system volume information)</p></li>
                                <li><p>Drops PE files with benign system names</p></li>
                                <li><p>Drops executable to common a third party application directory</p></li>
                                <li><p>Creates files inside the user directory</p></li>
                                <li><p>Creates files inside the system directory</p></li>
                            </ul>
                            <h4>File Deletion T1070.004</h4>
                            <ul>
                                <li><p>Deletes the backup plan of Windows</p></li>
                                <li><p>May delete shadow drive data (may be related to ransomware)</p></li>
                                <li><p>Delete volume shadow copies</p></li>
                            </ul>
                            <h4>Virtualization/Sandbox Evasion T1497</h4>
                            <ul>
                                <li><p>Contains capabilities to detect virtual machines</p></li>
                                <li><p>May sleep (evasive loops) to hinder dynamic analysis</p></li>
                                <li><p>Contains long sleeps (&gt;= 3 min)</p></li>
                                <li><p>Contains medium sleeps (&gt;= 30s)</p></li>
                            </ul>
                            <h4>Disable or Modify Tools T1562.001</h4>
                            <ul>
                                <li><p>Creates guard pages, often used to prevent reverse engineering and debugging</p></li>
                            </ul>
                            <h4>Credential Access TA0006</h4>
                            <ul>
                                <li><p>OS Credential Dumping T1003</p></li>
                                <li><p>Tries to harvest and steal browser information (history, passwords, etc)</p></li>
                            </ul>
                            <h4>Discovery TA0007</h4>
                            <p>Process Discovery T1057</p>
                            <ul>
                                <li><p>Enumerate processes</p></li>
                                <li><p>Queries a list of all running processes</p></li>
                            </ul>
                            <p>System Information Discovery T1082</p>
                            <ul>
                                <li><p>Queries the cryptographic machine GUID</p></li>
                                <li><p>Reads software policies</p></li>
                                <li><p>Queries the volume information (name, serial number etc.) of a device</p></li>
                            </ul>
                            <p>File and Directory Discovery T1083</p>
                            <ul>
                                <li><p>Enumerate files on windows</p></li>
                                <li><p>Check if file exists</p></li>
                                <li><p>Writes ini files</p></li>
                                <li><p>Reads ini files</p></li>
                            </ul>
                            <p>Virtualization/Sandbox Evasion T1497</p>
                            <ul>
                                <li><p>Contains capabilities to detect virtual machines</p></li>
                                <li><p>May sleep (evasive loops) to hinder dynamic analysis</p></li>
                                <li><p>Contains long sleeps (&gt;= 3 min)</p></li>
                                <li><p>Contains medium sleeps (&gt;= 30s)</p></li>
                                <li><p>Software Discovery T1518</p></li>
                                <li><p>Enumerate processes</p></li>
                            </ul>
                            <p>Security Software Discovery T1518.001</p>
                            <ul>
                                <li><p>May try to detect the virtual machine to hinder analysis (VM artifact strings found in memory)</p></li>
                                <li><p>Contains capabilities to detect virtual machines</p></li>
                            </ul>
                            <h4>Lateral Movement TA0008</h4>
                            <ul>
                                <li><p>Taint Shared Content T1080</p></li>
                                <li><p>Infects executable files (exe, dll, sys, html)</p></li>
                            </ul>
                            <h4>Collection TA0009</h4>
                            <p>Data from Local System T1005</p>
                            <ul>
                                <li><p>Tries to harvest and steal browser information (history, passwords, etc)</p></li>
                            </ul>
                            <p>Clipboard Data T1115</p>
                            <ul>
                                <li><p>Monitor clipboard content</p></li>
                                <li><p>Read clipboard data</p></li>
                                <li><p>Creates a window with clipboard capturing capabilities</p></li>
                            </ul>
                            <p>Browser Session Hijacking T1185</p>
                            <ul>
                                <li><p>Overwrites Mozilla Firefox settings</p></li>
                            </ul>
                            <h4>Impact TA0034</h4>
                            <p>Data Encrypted for Impact T1486</p>
                            <ul>
                                <li><p>Writes a notice file (html or txt) to demand a ransom</p></li>
                                <li><p>Modifies user documents (likely ransomware behavior)</p></li>
                            </ul>
                            <p>Inhibit System Recovery T1490</p>
                            <ul>
                                <li><p>Uses bcdedit to modify the Windows boot settings</p></li>
                                <li><p>Delete volume shadow copies</p></li>
                            </ul>
                            <h4> Impact TA0040</h4>
                            <p>Data Encrypted for Impact T1486</p>
                            <ul>
                                <li><p>Writes a notice file (html or txt) to demand a ransom</p></li>
                                <li><p>Modifies user documents (likely ransomware behavior)</p></li>
                            </ul>
                            <p>Inhibit System Recovery T1490</p>
                            <ul>
                                <li><p>Uses bcdedit to modify the Windows boot settings</p></li>
                                <li><p>Delete volume shadow copies</p></li>
                            </ul>
                            <h4>Conclusion:</h4>
                            <p>Based on the observed behavior, Chaos ransomware gathers specific drive information from the victim’s machine, deletes shadow copies, and proceeds to encrypt the victim’s data using a random string extension. The encrypted data cannot be accessed without complying with the ransom demands. Decrypter Tool has been released by Truesec.</p>
                            <h4><u>Best Practices for Risk Reduction against Ransomware Attacks </u></h4>
                            <ul>
                                <li><p>Backup your data: Ensure that your organization’s data is regularly backed up and the backups are stored in a separate location. This will help you recover your data if you fall victim to any ransomware attack. </p></li>
                                <li><p>Keep software up to date: Ensure that your organization’s operating systems, software applications and other devices are updated with the latest security patches and updates. Regularly updating your software will help prevent known vulnerabilities from being exploited. </p></li>
                                <li><p>Implement a strong password policy at your organization: Ensure that your employees use strong passwords and two-factor authentication to access organization’s systems and data. Weak passwords can be easily targeted by ransomware attackers. </p></li>
                                <li><p>Regularly train employees on security best practices: Provide regular training to your employees on how to identify and avoid suspicious emails, links, and attachments that could be potential ransomware vectors. </p></li>
                                <li><p>Limit access: Restrict access to sensitive organization’s data to only those who need it. This will help prevent ransomware from spreading through your organization’s network. </p></li>
                                <li><p>Implement network segmentation: Segment your organization’s network into smaller subnetworks to prevent the spread of ransomware in the event of an attack. </p></li>
                                <li><p>Use security tools: Implement security tools such as firewalls, antivirus / EDR, intrusion detection and prevention systems, identity governance and administration (IGA) and security monitoring / extended detection & response (XDR) solutions to monitor, protect, respond and remediate against malicious activities / incidents at your organization.</p></li>
                                <li><p>Have a plan: Develop a plan for responding to ransomware attacks. This should include identifying key personnel and responsibilities, having a backup and recovery plan, and having a communication plan in place to inform stakeholders of the situation.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Analysis of Chaos Ransomware' , url: 'https://www.sequretek.com/resources/blog/analysis-of-chaos-ransomware'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
