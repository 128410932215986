// ############################ Require packages here ############################
import React from 'react'
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################


export default function CookiePolicy() {

    return (
        <div className='seq-cookie-policy'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Cookie Policy</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Understand Sequretek's use of cookies for website functionality, personalization, and analytics. Learn how to manage your cookie preferences." />
                <meta property="og:title" content="Sequretek | Cookie Policy" />
                <meta property="og:description" content="Understand Sequretek's use of cookies for website functionality, personalization, and analytics. Learn how to manage your cookie preferences." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='cookie-policy-header'>
                <h3>Cookie Policy</h3>
                <div className='container'>
                    <p className='mt-4 mb-4'>This Cookie Policy (&quot;Policy&quot;) explains how the websites owned and operated by Sequretek IT Solutions Private Limited (&quot;Sequretek&quot;) and its wholly owned subsidiary uses cookies to recognize when you visit our website at <a href='https://sequretek.com' style={{color:'#b6b6b6'}}>https://sequretek.com</a> (&quot;Site&quot;) using a browser. We advise you to carefully read this Policy to be aware of how, where and why we are using Cookies.</p>
                    <p className='mt-4 mb-4'>Per applicable data protection laws, you may be presented with the option to agree to the use of certain cookies prior to browsing Sequretek’s website. In doing so, you can consent to allow all cookies, consent only to specific categories of cookies, or reject the use of cookies except for those deemed necessary for the website or otherwise designed in a manner that is permissible under applicable law.</p>
                </div>
            </div>
            {/* section 2 DESCRIPTION */}
            <div className='cookie-description'>
                <div className='container'>
                    <div>
                        <h4>What is a Cookie?</h4>
                        <p>A cookie is a small text file that is stored on a user’s computer to remember their webpage actions or preferences over time. Cookies don’t read or modify data on the user’s computer, instead they help provide users with a better and faster online experience. Upon your initial visit to a website, your internet browser automatically stores small data files called cookies on your device. The next time you access the same website from the same device, the website will recognize that you have visited before, and in some cases adapt the content to your prior visits. This allows the website experience to be tailored to your personal interests and preferences.</p>
                        <p>A web beacon is a small graphic file that is placed on the web page the user visits and is often used to help deliver cookies and gather usage and performance data.</p>
                    </div>

                    <div className='mt-4 mb-4'>
                        <h4>
                            Why does Sequretek use cookies?
                        </h4>
                        <p><strong>To allow websites and web portals to function properly:</strong>&nbsp;
                            Cookies perform different functions that help us make your online experience smoother and more interactive. This is especially useful if you are using the same device and the same browser as you did when you visited our website previously.
                        </p>
                        <p>Some cookies are essential to maintain and operate Sequretek’s website and web portals. They allow us to provide you with consistent access to services and functionalities, consistent and updated information, and a seamless online experience.</p>
                        <p><strong>To help us improve our website and web portals:</strong>&nbsp;
                            Cookies help us improve our website and web portals by analyzing how visitors interact with them. We use that information to determine what is popular and what else visitors may find useful. That information allows us to understand how visitors use our website. We use that information to enhance users’ web experiences, and to display content most relevant to individual users.
                        </p>
                        <p><strong>To customize a user’s web experience on our websites and web portals:</strong>&nbsp;
                            Cookies also help us understand how you use and interact with our websites and web portals. They can be used to remember your preferences and user ID when you visit our websites. If we know your settings and preferences, we can provide a more personalized user experience by helping you navigate more efficiently to relevant pages, remember your personal preferences, and modify content based on those preferences to provide more personalized content (based on preferences or geography, for example).
                        </p>
                        <p>In addition to the above uses of cookies, web beacons, and other digital footprint
                            trackers, we also use third-party service providers to help us implement and utilize
                            cookies, web beacons, and other tracking technologies to analyze the data they collect.
                            In some cases, we link the information gathered by cookies, web beacons, and other
                            tracking technologies to a user’s personal information. We may also use cookies, web
                            beacons, tracking technologies, and the personal information they collect to tailor our
                            websites, web portal, and communications so they are more relevant to a user’s
                            interests. Additionally, cookies enable tailored content such as relevant advertisements
                            to be provided on partner websites.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Cookie Settings
                        </h4>
                        <p>Most web browsers support cookies, and users can control the use of cookies at the
                            individual browser level. Please note, if you choose to disable cookies all content will
                            remain visible; however, it may limit your use of certain features or functions on our
                            websites and web portals.
                        </p>
                        <p>
                            First-party cookies are cookies deployed by Sequretek on website owned and operated
                            by Sequretek. Third-party cookies are related to content from other website that may
                            deploy their own cookies, and that are set by an entity other than Sequretek.
                        </p>
                        <p>
                            If you decide to reject cookies that require prior consent, we will not use those cookies.
                            Additionally, if you withdraw consent to the use of cookies that was previously given to
                            us by using the Cookie Settings at the bottom of this page, we will no longer use the
                            relevant cookies and will delete them if they were first-party cookies. Rejecting the use
                            of functional cookies will result in decreased functionality of our website, and may
                            impact your ability to take advantage of certain website features.
                        </p>
                        <p>
                            We cannot delete third-party cookies. In order to delete third-party cookies, you must do
                            so through your browser settings.
                        </p>
                        <p>
                            We and our third-party partners use Local Storage (HTML5) to provide certain features
                            on our websites and web portals, to display advertising based on your web browsing
                            activities, and to store content information and preferences. Various web browsers may
                            offer their own management tools for removing HTML5.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            How to disable cookies
                        </h4>
                        <p>
                            If you do not want to receive cookies, most browsers allow you to control cookies
                            through their setting preferences.
                        </p>
                        <p>
                            For Analytics Cookies, you can read more about how the analytics service providers
                            use your information by visiting their respective websites.
                        </p>
                        <p>
                        For Google Analytics, please visit <a href='https://policies.google.com/privacy?hl=en.' style={{color:'#b6b6b6'}}>https://policies.google.com/privacy?hl=en.</a></p>
                        <p>You can also opt-out of Google Analytics here:</p> <a href='https://tools.google.com/dlpage/gaoptout.' style={{color:'#b6b6b6'}}>https://tools.google.com/dlpage/gaoptout.</a>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Changes to this Policy
                        </h4>
                        <p>
                            We may update our Policy from time to time to reflect, for example, changes to the
                            cookies we use or for other operational, legal, or regulatory reasons. Please therefore
                            revisit this Policy regularly to stay updated about Our use of cookies. Your continued
                            use of the website after we post any changes to the Policy on this page will constitute
                            your acknowledgment of the modifications and your consent to abide and be bound by
                            the modified Policy.
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h4>
                            Who to contact
                        </h4>
                        <p>
                            If you have any questions about our use of cookies, please email us at
                            privacy.website@sequretek.com
                        </p>
                    </div>
                    <div className='mt-4 mb-4'>
                        <h5>LAST UPDATED: AUGUST 2024</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}
