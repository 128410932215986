import { getApiCall } from "../../utils/requests";
import { returnErrors } from '../ActionCreators/errorAction';
import {
    RESET_DASHBOARD_DATA, GET_TICKET_METRICS, GET_LEADS_DETAILS, GET_LOGIN_TREND, GET_RESOURCE_TREND, GET_RECENT_LEAD, GET_RECENT_DEAL, GET_RECENT_TICKET, GET_RECENT_CONTENT, GET_CONSUMED_CONTENT, GET_NEXT_CONSUMED_CONTENT
} from "../Types/types";

export const resetDashboardData = () => async (dispatch) => {
    try {
        return dispatch({ type: RESET_DASHBOARD_DATA });
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Ticket details in Dashboard on partner portal 

export const getTicketDetails = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/metric/tickets?partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_TICKET_METRICS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Lead details in Dashboard on partner portal 

export const getLeadsDetail = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/metric/leads?month=6&partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_LEADS_DETAILS, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Login Trend details in Dashboard on partner portal 

export const getLoginTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/metric/logins?day=7&partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_LOGIN_TREND, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Resources Trend details in Dashboard on partner portal 

export const getResouceTrend = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/metric/resources?month=6&partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RESOURCE_TREND, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Recent Leads details in Dashboard on partner portal 

export const getRecentLeads = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/recent/leads?partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RECENT_LEAD, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Recent Deals in Dashboard on partner portal 

export const getRecentDeals = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/recent/deals?partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RECENT_DEAL, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Recent Ticket details in Dashboard on partner portal 

export const getRecentTickets = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/recent/tickets?partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RECENT_TICKET, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the Recent Resources details in Dashboard on partner portal 

export const getRecentResource = (id) => async (dispatch) => {
    try {
        const response = await getApiCall(`dashboard/recent/resources?partner_id=${id ? id : ''}`);
        if (response.status === 200) {
            return dispatch({ type: GET_RECENT_CONTENT, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for All Consumed Resources  in Dashboard on partner portal 

export const getAllConsumedResources = (page, id) => async (dispatch) => {
    try {
        let response
        if (id) {
            response = await getApiCall(`resource/analytic?size=12&page=${page}&partner_id=${id ? id : ""}`);
        } else {
            response = await getApiCall(`resource/analytic?size=12&page=${page}`);
        }
        if (response.status === 200) {
            return dispatch({ type: GET_CONSUMED_CONTENT, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

export const getNextConsumedResouces = (page, id) => async (dispatch) => {
    try {
        let response
        if (id) {
            response = await getApiCall(`resource/analytic?size=12&page=${page}&partner_id=${id ? id : ""}`);
        } else {
            response = await getApiCall(`resource/analytic?size=12&page=${page}`);
        }
        if (response.status === 200) {
            return dispatch({ type: GET_NEXT_CONSUMED_CONTENT, payload: response.data.data });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}