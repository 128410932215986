// ############################ Require packages here ############################
import React, { lazy, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// ############################ Require files here ###############################

import './style.scss';
import ScheduleForm from '../scheduleForm';
// import TestimonialSlider from '../testimonialSlider';
import RelatedResources from '../relatedResources';
import { getOneResource } from '../../../Redux/ActionCreators/resourcesAction';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const risks = [
    { image: '/images/fostering.svg', title: 'Fostering Trust', description: 'Trust and credibility, the two uncompromising pillars of banking, are under scrutiny, due to rising cyberthreats and geopolitical tensions.' },
    { image: '/images/flag.svg', title: 'Stringent Regulations', description: 'Banks must deal with regulatory pressure to comply with FFIEC, SEC, RBI, SEBI, SOX, GDPR, and PCI DSS, with penalties and service disruptions.' },
    { image: '/images/legacy.svg', title: 'Legacy Systems', description: 'Vulnerabilities in legacy systems such as outdated security updates and patches result in data exfiltration and unsecure data protection in banking.' },
    { image: '/images/cloud.svg', title: 'Value Chain Attacks', description: `Cybercriminals are increasingly targeting multiple third-party touchpoints to infiltrate banks' systems, resulting in significant cyber risks.` },
    { image: '/images/mask.svg', title: 'Increasing Sophistication of Cyber Attacks', description: 'Sophisticated attacks like Advanced Persistent Threats (APTs) and zero-day attacks infiltrate systems, posing significant challenges.' },
    { image: '/images/brake-warning.svg', title: 'Exploitation Risk', description: 'Banks attract cybercriminals motivated by financial gains, aim to steal funds, harvest account information (for future scams), and execute other malicious activities.' },
    { image: '/images/shield-color.svg', title: 'Safeguarding Sensitive Information', description: `Banks are prime targets of attacks due to the wealth of personal data –  account numbers, social security numbers, and other details – that they possess.` },
    { image: '/images/resources.svg', title: 'Resources', description: `Small and medium banks are more vulnerable to cyberthreats as their limited resources result in outdated security measures and an under-trained workforce.` },
    { image: '/images/cloud-security.svg', title: 'Cloud Security', description: `The journey to cloud adoption introduces new security considerations making robust, updated security architecture, and vigilant monitoring crucial. ` },
    { image: '/images/information.svg', title: 'Reputational Damage', description: `Data breaches in banking can significantly impact the reputation of banks, eroding customer trust, resulting in viability and business continuity issues.` },
    { image: '/images/queue.svg', title: 'Unaware Workforce', description: `An ignorant, overly-curious or under-trained workforce are security risks for bankers easily outwitted by modern-day cybercriminals.` },
]

const helpList = [{ image: '/images/pulse.svg', title: 'Enhanced Security Monitoring', description: `Our Percept Continuous Threat Exposure Management (CTEM) solution proactively defends the IT landscape by integrating with existing legacy and next-gen technologies.` },
{ image: '/images/alert.svg', title: 'Threat Detection and Response', description: 'Cyber risk management with our EDR, XDR & NG SIEM products and services helps monitor, detect, and respond to cyberattacks in real time.' },
{ image: '/images/user-key.svg', title: 'User Access Management', description: 'Our identity products and services prevent data breaches by continuously monitoring user activity and limiting access to authorized personnel only.' },
{ image: '/images/shield.svg', title: 'Conformance to Compliance Mandates', description: `Sequretek's CTEM platform’s governance and tailored compliance reports help meet security regulations by FFIEC, SEC, RBI and other bodies.` },
{ image: '/images/house-lock.svg', title: 'Vulnerability Management', description: 'Sequretek strengthens defenses, fixes vulnerabilities, and ensures system stability with regular audits, patching, and critical infrastructure update deployment.' },
{ image: '/images/customer-care-white.svg', title: 'Managed Security Services', description: `Sequretek MSS offers 24/7 onsite/remote services, including audit and advisory, risk and governance, incident response, server, network and perimeter security.` },
]



export default function Banking() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPage = (val) => {
        window.scrollTo(0, 0);
        if (val === 'contact') {
            window.scrollTo(0, 4200);
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const downloadFactsheet = async () => {
        const res = await dispatch(getOneResource(['66daac908fc4a52329d4bf5e']))
        if (res?.type === 'GET_ONE_RESOURCES') {
            navigate(`/resources/factsheet/${encodeURIComponent(res.payload?.title)}`, { state: { data: res?.payload } })
        }
    }

    return (
        <div className='seq-content-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Shield Your Bank From Cyberthreats</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Shield your bank from financial loss and reputational damage. Secure customer data and build trust with robust cybersecurity. Protect against evolving threats." />
                <meta name="keywords" content="Cybersecuirty for banks, Data breach in banking" />
                <meta property="og:title" content="Sequretek | Shield Your Bank From Cyberthreats" />
                <meta property="og:description" content="Shield your bank from financial loss and reputational damage. Secure customer data and build trust with robust cybersecurity. Protect against evolving threats." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-content-heading' style={{ backgroundImage: ` linear-gradient(41.44deg, #040c30 1.6%, rgba(6, 6, 93, 0.1) 10.69%, #06065d 76.56%),url('/images/bg-img10.png')` }}>
                <div className='seq-description' >
                    <h5 data-aos="fade-up">Industries{' > '}Banking </h5>
                    <h2 data-aos="fade-up" >Vault of Confidence

                        <p> Secure your Customers’ Assets and Trust</p>
                    </h2>
                    <div data-aos="fade-up" className='text-center mt-3'>
                        <button onClick={() => downloadFactsheet()}>Download Factsheet</button>
                    </div>
                </div>
            </div>
            {/* section 2 INFO */}
            <div className='seq-content-1'>
                <div className='container pe-0'>
                    <div className='row me-0'>
                        <div className='col-md-5 col-sm-12 col-12'>
                            <h4>
                                Fortify Your Digital Systems:<br />
                                Essential Cybersecurity for Modern Banking
                            </h4>
                        </div>
                        <div className='col-md-6 col-sm-12 offset-lg-1 col-12 seq-info-text'>
                            <p>With a record-breaking 2.85 million cyberattacks launched in 2023, causing an average loss of $5.90 million per data breach, a tempest is brewing in banking. Every incident erodes trust – the core of banking relationships. Banks are under immense pressure to safeguard their systems and customer data as the damage ranges from regulatory to financial to reputational.</p><br />
                            <p> By implementing robust security practices banks can stay ahead of the evolving cyberthreat landscape, protect their systems confidently, and maintain their stronghold.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 CHALLENGES */}
            <div className='seq-challenge'>
                <p>Risk Radar</p>
                <h3>The Multi-Faceted Cybersecurity Challenges in Banking</h3>
                <div className='container pe-0'>
                    {
                        window.innerWidth > 767 ?
                            <div className='row me-0 seq-card-items-row'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div data-aos="fade-up" className='col pe-0 d-flex align-self-stretch seq-card-items' style={{ flexGrow: 'unset' }}>
                                                <div className='seq-card' style={{ height: 345 }}>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <p>{el.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div className='d-flex overflow-auto flex-nowrap'>
                                {
                                    risks?.map(el => {
                                        return (
                                            <div className='seq-card'>
                                                <img src={el.image} alt='' loading="lazy" />
                                                <h4>{el.title}</h4>
                                                <p>{el.description}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                    }
                </div>

            </div>
            {/* section 4 HOW SEQURETEK HELPS */}
            <div className='seq-how-to-helps'>
                <div className='container'>
                    <div className='seq-help-bg ' style={{ backgroundImage: `url('/images/bg-img6.svg')` }}>
                        <div className='row '>
                            <div className=' col-md-5 col-12 seq-help-left' data-aos="fade-up">
                                <p>How Sequretek Helps</p>
                                <h3>Bridge Security Gaps: Strengthen Every Aspect from Compliance to Access to Threat Management</h3>
                                <button onClick={() => setPage('contact')}>Get started</button>
                            </div>
                            {window.innerWidth > 767 ?
                                <div className='col-md-4 offset-md-1 seq-ind-qimg'>
                                    <img src='/images/q-icon1.svg' alt='' height={300} loading="lazy" />
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className='seq-help-list'>
                        <div className='row'>
                            {
                                helpList?.map(el => {
                                    return (
                                        <div data-aos="fade-up" className='col-md-4 col-sm-12'>
                                            {window.innerWidth > 767 ?
                                                <div className='seq-card'>
                                                    <img src={el.image} alt='' loading="lazy" />
                                                    <h4>{el.title}</h4>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div> :
                                                <div className='seq-card'>
                                                    <div className='d-flex'>
                                                        <div className='d-flex justify-content-center align-items-center'><img src={el.image} alt='' loading="lazy" /></div>
                                                        <h4>{el.title}</h4>
                                                    </div>
                                                    <hr />
                                                    <p>{el.description}</p>
                                                </div>
                                            }

                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* section 5 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'banking'}
                />
            </div>
            {/* section 6 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container'>
                    <div className="insurance-partseven" data-aos="fade-up">
                        <ScheduleForm
                            text={'The security of your data and infrastructure is more critical than ever. Secure your sensitive data with Sequretek’s enhanced security monitoring and threat detection and ensure that your critical information remains confidential and protected.'}
                            bg={'/images/bg-img5.svg'}
                            mobilebg={'/images/bg-img48.svg'}
                            page='banking'
                        />
                    </div>
                </div>
            </div>
            {/* section 7 RELATED RESOURCES */}
            <div className='seq-resources'>
                <div className='container px-0'>
                    <h4>Related resources</h4>
                    <div className='row ms-0 me-0'>
                        <div className='col-md-12 ps-0'>
                            <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto'}>
                                <RelatedResources
                                    page='banking'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setPage('resources')}>Resource Center</button></div>}
        </div>
    )
}
