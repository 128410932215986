import { postApiCall } from '../../utils/requests';
import { returnErrors } from '../ActionCreators/errorAction';
import { GENERATE_OTP, USER_LOGIN, FORGET_PASSWORD, RESET_PASSWORD } from '../Types/types';


// This function was an api call for the login page to generate otp in app section on the website

export const generateOTP = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/otp`, login);
        if (response.status === 200) {
            returnErrors(dispatch, response.data.message, response.status)
            return dispatch({ type: GENERATE_OTP, payload: response.data.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}

// This function was an api call for the login page to validate login credentials in app section on the website

export const userLogin = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/login`, login);

        if (response.status === 200) {
            return dispatch({ type: USER_LOGIN, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an api call for the login page to accept invites in app section on the website

export const acceptInvite = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/accept-invite`, obj);
        if (response.status === 200) {
            return dispatch({ type: USER_LOGIN, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an api call for the Register page to submit Registation credentials in app section on the website

export const submitRegistrationForm = (login) => async (dispatch) => {
    try {
        const response = await postApiCall(`external/form/registration`, login);
        if (response.status === 200) {
            return dispatch({ type: 'FORM_REGISTER', payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an api call for the login page for forgot Password in app section on the website

export const forgotPassword = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/forgot-password`, obj);
        if (response.status === 200) {
            return dispatch({ type: FORGET_PASSWORD });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status);
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an api call for the login page to Reset Password in app section on the website

export const resetPasswords = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/reset-password`, obj);
        if (response.status === 200) {
            return dispatch({ type: RESET_PASSWORD, payload: response.data });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
        }
    } catch (error) {
        returnErrors(dispatch, error.response?.data.message, error.response?.status)
    }
}

// This function was an api call for the login page to Verify Otp in app section on the website

export const verifyLink = (obj) => async (dispatch) => {
    try {
        const response = await postApiCall(`users/verify/otp`, obj);
        if (response.status === 200) {
            // returnErrors(dispatch, response.data.message, response.status)
            return dispatch({ type: 'VERIFY_CODE' });
        }
        else {
            returnErrors(dispatch, response.data.message, response.status)
            return dispatch({ type: 'LINK_EXPIRED' });
        }
    } catch (error) {
        returnErrors(dispatch, error?.response?.data.message, error?.response?.status)
    }
}