// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState, useRef } from 'react';
import './styles.scss';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import { Modal, Spinner } from 'react-bootstrap';
import {
    loadCaptchaEnginge,
    LoadCanvasTemplate,
    validateCaptcha,
} from "react-simple-captcha";
// ############################ Require files here ###############################

import countries from '../../utils/countries.json';
import { submitScheduleDemoForm } from '../../Redux/ActionCreators/contactusAction';




export default function ScheduleDemo() {
    const [type, setType] = useState('');
    const [loading, setLoading] = useState(false);
    const [captchaError, setCaptchaError] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [data, setData] = useState(null);
    const initialValues = {
        fname: '', lname: '', organization: '', email: '', dial_code: "", mobile: '', products: [], services: [], country: "", message: "", newsletter: false
    };
    const dispatch = useDispatch();
    const location = useLocation();
    const formikRef = useRef();

    useEffect(() => {
        if (location?.state?.type) {
            setType(location?.state?.type)
        } else {
            setType('')
        }
    }, [])

    const validationSchema =
        Yup.object({
            fname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true,)
                .matches(/^[a-zA-Z0-9\s]+$/, 'First Name should only contain alphabets, numbers, and spaces')
                .required('First Name is required'),
            lname: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Last Name should only contain alphabets, numbers, and spaces')
                .required('Last Name is required'),
            organization: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .matches(/^[a-zA-Z0-9\s]+$/, 'Organization should only contain alphabets, numbers, and spaces')
                .required('Organization is required'),
            email: Yup.string()
                .trim('No extra space allowed')
                .strict(true)
                .required('Email is required')
                .matches(/^([\w.-]+)@(?!hotmail\.com|gmail\.com|googlemail\.com|yahoo\.com|gmx\.com|ymail\.com|outlook\.com|bluewin\.ch|protonmail\.com|t-online\.de|web\.de|online\.de|aol\.com|live\.com)([a-zA-Z\d-]+\.)+([a-zA-Z]{2,63})$/, 'Enter Only Business email address'),
            mobile: Yup.string()
                .trim('Only Numbers allowed')
                .strict(true)
                .matches(/^\d{10}$/, 'Phone number must be 10 digits')
                .required('Phone is required'),
            country: Yup.object()
                .shape({
                    value: Yup.string().required('You must select an option'),
                })
                .nullable() // allow null if you want to allow resetting to null
                .required('Country is Required'),
            products: Yup.array()
                .min(1, 'You must select at least one products')
                .required('Products is Required'),
            services: Yup.array()
                .min(1, 'You must select at least one services')
                .required('Services is Required'),
            message: Yup.string()
                .trim('Only alphanumricals allowed')
                .strict(true)
                .max(1500, "Maximum 1500 characters allowed")
                .matches(/^[a-zA-Z0-9\s]+$/, 'Message should only contain alphabets, numbers, and spaces')
                .notRequired(''),
        });


    const handleCountryChange = (event, setFieldValue) => {
        setFieldValue('country', event ? event : '');
        setFieldValue('dial_code', event ? event.dial_code : '');
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        let obj = {
            "fname": values?.fname,
            "lname": values?.lname,
            "organization": values.organization,
            "email": values?.email,
            "mobile": `${values?.dial_code}${values?.mobile}`,
            "country": values?.country?.value,
            "products": values?.products,
            "services": values?.services,
            "message": values?.message,
            "newsletter": values?.newsletter
        }
        setData(obj)
        setIsConfirm(true);
        setSubmitting(false);
        setTimeout(() => {
            loadCaptchaEnginge(6);
        }, 500);
    }
    const handleCaptchaSubmit = async () => {
        let user_captcha_value = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha_value, false) === true) {
            setCaptchaError('');
            await setLoading(true);
            const res = await dispatch(submitScheduleDemoForm(data));
            if (res?.type === 'DEMO_SUBMIT') {
                if (formikRef.current) {
                    formikRef.current.resetForm();
                    formikRef.current.setFieldValue('country', null);
                    formikRef.current.setFieldValue('newsletter', false);
                    setIsConfirm(false);

                }
            }
            setTimeout(() => {
                setLoading(false)
            }, 3000);
        } else {
            setCaptchaError('Captcha Does Not Match');
        }
    };

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: '#040c30',
            zIndex: 3
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? 'lightblue' : '#040c30', // Change background color on selection
            color: '#b6bcbf', // Ensure text is visible
            ':hover': {
                color: '#000',
                backgroundColor: '#b6bcbf' // Change background color on hover
            }
        })
    };

    return (
        <Fragment>
            <div className='seq-schedule-forms'>
                <div className='reverse-grad'>
                </div>
                {/* section 0 PAGE HEADING */}
                <Helmet>
                    <title>Sequretek | Live Product Demos | Discover Our Solutions</title>
                    <meta http-equiv="X-Frame-Options" content="DENY" />
                    <meta name="description" content="Experience the power of our cybersecurity solutions firsthand through live demonstrations. Witness the real-world benefits and capabilities of our products." />
                    <meta property="og:title" content="Sequretek | Live Product Demos | Discover Our Solutions" />
                    <meta property="og:description" content="Experience the power of our cybersecurity solutions firsthand through live demonstrations. Witness the real-world benefits and capabilities of our products." />
                </Helmet>
                {/* section 1 BANNER */}
                <h1>Schedule a Demo</h1>

                {/* section 2 LEFT SECTION */}
                <div className=" insurance-partseven pt-5">
                    <div className='container'>
                        <div className="row px-5">
                            <div className="col-md-3 col-sm-12 col-12 left-section" style={{ backgroundImage: window.innerWidth > 767 ? `url('/images/bg-img14.svg')` : `url('/images/bg-img14.png')` }}>
                                <div className='left-section-text'>
                                    <img className='justify-content-start' src='/images/lightbulb-on.svg' alt='' />
                                    <h2>Ready to Protect Your Enterprise?</h2>
                                </div>
                                <p className='justify-content-between'>Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.</p>
                                {/* {window.innerWidth > 767 ? null : <div className='insu-btn'><button className='insu-demo-btn' >Schedule a demo</button> </div>} */}
                            </div>
                            {/* section 2-2 RIGHT SECTION */}
                            <div className="col-md-9 col-sm-12 form-section">
                                <div className='right-section' >
                                    <h3>Request a discussion or schedule a demo</h3>
                                    <Formik innerRef={formikRef} initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                        {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                            < Form className='input-form'>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <div className='form-group'>
                                                            <div className='form-group mt-2'>
                                                                <Field
                                                                    type="text"
                                                                    name="fname"
                                                                    placeholder="First Name*"
                                                                    className='form-control'
                                                                    autoComplete='off'
                                                                />
                                                                <ErrorMessage name="fname" component="div" className="error-message" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='form-group mt-2'>
                                                            <Field
                                                                type="text"
                                                                name="lname"
                                                                placeholder="Last Name*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="lname" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='form-group mt-2'>
                                                            <Field
                                                                type="text"
                                                                name="organization"
                                                                placeholder="Organization*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="organization" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='form-group mt-2' >
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                placeholder="Business Email*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="email" component="div" className="error-message" />
                                                        </div>
                                                    </div>

                                                    <div className='col-md-6'>
                                                        <div className='form-group mt-2' >
                                                            <Select
                                                                name="country"
                                                                id="singleSelectCustom"
                                                                placeholder="Select Country"
                                                                isMulti={false}
                                                                isClearable
                                                                isSearchable
                                                                value={values.country}
                                                                styles={customStyles}
                                                                onChange={(event) => handleCountryChange(event, setFieldValue)}
                                                                options={countries?.map(el => [{ ...el, label: el.name, value: el.name }][0])}
                                                            />
                                                            {errors.country?.value && touched.country ? (
                                                                <div className="error-message">{errors.country.value}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <div className='form-group mt-2 d-flex' >
                                                            <Field
                                                                style={{ width: '80px' }}
                                                                name="dial_code"
                                                                placeholder=""
                                                                value={values?.dial_code}
                                                                className='form-control'
                                                                autoComplete='off'
                                                                disabled
                                                            />
                                                            <Field
                                                                type="tel"
                                                                name="mobile"
                                                                maxLength={10}
                                                                placeholder="Phone*"
                                                                className='form-control'
                                                                autoComplete='off'
                                                            />
                                                        </div>
                                                        <ErrorMessage name="mobile" component="div" className="error-message" />
                                                    </div>
                                                </div>
                                                <div className='row prods-serves'>
                                                    {type === 'service' ? null :
                                                        <div className=" products-list">
                                                            <label className='label-head'>Products Interested In</label>
                                                            <div className='prods-input'>
                                                                <div className="form-check label-select">
                                                                    <Field className="form-check-input " type="checkbox" id="products" value='Percept XDR and NG SIEM' name='products' />
                                                                    <label className="form-check-label" htmlFor="products">
                                                                        Percept XDR & NG SIEM
                                                                    </label>
                                                                </div>
                                                                <div className="form-check label-select">
                                                                    <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept EDR'} />
                                                                    <label className="form-check-label" htmlFor="products">
                                                                        Percept EDR
                                                                    </label>
                                                                </div>
                                                                <div className="form-check label-select">
                                                                    <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept Identity'} />
                                                                    <label className="form-check-label" htmlFor="products">
                                                                        Percept Identity
                                                                    </label>
                                                                </div>
                                                                <div className="form-check label-select">
                                                                    <Field className="form-check-input " type="checkbox" id="products" name='products' value={'Percept Compliance Manager'} />
                                                                    <label className="form-check-label" htmlFor="products">
                                                                        Percept Compliance Manager
                                                                    </label>
                                                                </div>
                                                                <ErrorMessage name="products" component="div" className="error-message" />
                                                            </div>
                                                        </div>}
                                                    <div className="col-md-3 products-list">
                                                        <label className='label-head'>Services Interested In</label>
                                                        <div className='prods-input prods-input-right'>
                                                            <div className="form-check label-select">
                                                                <Field className="form-check-input" type="checkbox" id="services" name='services' value={'Governance Risk and Compliance'} />
                                                                <label className="form-check-label" htmlFor="services">
                                                                    Governance Risk and Compliance
                                                                </label>
                                                            </div>
                                                            <div className="form-check label-select">
                                                                <Field className="form-check-input" type="checkbox" id="services" name='services' value={'Security Operations and Management'} />
                                                                <label className="form-check-label" htmlFor="services">
                                                                    Security Operations and Management
                                                                </label>
                                                            </div>
                                                            <div className="form-check label-select">
                                                                <Field className="form-check-input" type="checkbox" value="Incident Response and Management" id="services" name='services' />
                                                                <label className="form-check-label" htmlFor="services">
                                                                    Incident Response and Management
                                                                </label>
                                                            </div>
                                                            <div className="form-check label-select">
                                                                <Field className="form-check-input" type="checkbox" value="Patch Management as a Service" id="services" name='services' />
                                                                <label className="form-check-label" htmlFor="services">
                                                                    Patch Management as a Service
                                                                </label>
                                                            </div>
                                                            <ErrorMessage name="services" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='msg-feld'>
                                                    <div className="mb-3">
                                                        <label className='message-text d-flex'>Your Message </label>
                                                        <p className='text-restrictions'>Only alphanumerical characters are allowed</p>
                                                        <div className='form-group' >
                                                            <Field
                                                                type="text"
                                                                as='textarea'
                                                                rows={5}
                                                                maxLength="250"
                                                                name="message"
                                                                placeholder="How can we help?"
                                                                className='form-control text-field'
                                                                autoComplete='off'
                                                            />
                                                            <ErrorMessage name="message" component="div" className="error-message" />
                                                        </div>
                                                    </div>
                                                    <div className="form-check mb-3">
                                                        <input className="form-check-input" type="checkbox"
                                                            checked={values.newsletter}
                                                            onChange={() => setFieldValue('newsletter', !values.newsletter)}
                                                            id="newsletter" name='newsletter'
                                                        />
                                                        <label className="form-check-label input-check-box" htmlFor="newsletter">
                                                            Receive the latest threat advisories and newsletters from Sequretek
                                                        </label>
                                                    </div>
                                                    {loading ?
                                                        <button type='submit' className='input-submit-btn btn btn-danger' style={{ backgroundColor: '#1BA431', borderColor: '#1BA431' }} ><img src='/images/tick-white.svg' alt='' /></button> :
                                                        <button className='input-submit-btn btn btn-danger' disabled={isSubmitting} type="submit">{isSubmitting ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>
                                                    }
                                                    {loading ?
                                                        <div className='mt-3'>
                                                            <p>Thanks for getting in touch. We'll come back to you shortly</p>
                                                        </div> :
                                                        null
                                                    }
                                                    <div className='submit-text'>
                                                        <p>Your privacy matters. By filling out this form, you consent to the collection, storage, and use of your PII as per our privacy policy. We will protect your data and not share it with third parties without your consent, except as required by law.</p>
                                                    </div>
                                                </div>
                                            </Form>)
                                        }
                                    </Formik>
                                </div>
                            </div>
                        </div>
                        <Modal
                            show={isConfirm}
                            size="md"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            // onHide={() => setIsConfirm(false)}
                            dialogClassName={"confirm-modal"}
                        >
                            {loading ? null :
                                <div className='close-sticky'>
                                    <img src='/images/close.svg' alt='' onClick={() => setIsConfirm(false)} />
                                </div>
                            }
                            <div className="p-4" >
                                <div className=''>
                                    <LoadCanvasTemplate reloadText="Reload" />
                                </div>

                                <div className='form-group mt-3'>
                                    <input className='form-control' placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                                </div>
                                <span style={{ color: 'red' }}>{captchaError}</span>
                                <div className="save-btn1">
                                    <button disabled={loading} onClick={() => handleCaptchaSubmit()}>{loading ? <Spinner animation="border" variant="light" /> : 'Submit'}</button>&nbsp;&nbsp;
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div >
        </Fragment>
    )
}
