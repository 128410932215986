// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';


export default function Blogs13() {

    const navigate = useNavigate();
    return (
        <div className='seq-reports-view'  >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek Blog-Trojan.msil lovgate Analysis</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Trojan.msil lovgate Analysis</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Trojan.msil lovgate Analysis</h3>
                        <p>17-JULY-2023 | Sanket Wagh </p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample62.jpg' width={'100%'} height={318} alt='' />
                            <h4>Overview</h4>
                            <p>“trojan.msil/lovgate” is a .Net-based Remote Access Trojan (RAT) and data stealer for gaining initial access and is often used for Malware-As-A-Service (MaaS). “trojan.msil/lovgate” gets delivered through phishing emails and has capabilities to enable keylogging, screen capture, form-grabbing, credential stealing, and more. Credentials will be ex-filtrated from multiple applications, including Google Chrome, Mozilla Firefox, etc.</p>
                            <p>Upon execution, activity of the malware are as follows: –</p>
                            <ul>
                                <li><p>Steal the user credentials.</p></li>
                                <li><p>Send the data to the attacker server.</p></li>
                                <li><p>Enables Persistence.</p></li>
                            </ul>
                            <h4>Delivery Method</h4>
                            <p>While monitoring a client on our XDR solution, the Security Operations team noticed an anomalous communication that included an IP address that was listed in our Threat Intel feed as a malicious IP. This indicator was present in an email received by the client. The client shared that email with Sequretek Malware Research Team for detailed analysis.</p>
                            <h4>Investigation Process</h4>
                            <ul>
                                <li><p>Email Header analysis</p></li>
                                <li><p>Email Body content analysis</p></li>
                            </ul>
                            <h4>Email Header Analysis</h4>
                            <ul>
                                <li><p>SPF: neutral with IP Unknown!</p></li>
                                <li><p>DMARC: none</p></li>
                                <li><p>MTA Encryption: Poor</p></li>
                                <li><p>Email travel Path: 5 hops by email server</p></li>
                                <li><p>Sender Validation: Good</p></li>
                                <li><p>Mail Server: scom (203[.]242[.]197[.]31)</p></li>
                                <li>
                                    <p>Email Sender IP address: 212[.]87[.]204[.]134</p>
                                    <ul>
                                        <li><p>IP Is blacklisted</p></li>
                                        <li><p>Detected in abuse activity</p></li>
                                        <ul>
                                            <li><p>SMTP auth dictionary attack</p></li>
                                            <li><p>SPAM</p></li>
                                        </ul>
                                    </ul>
                                </li>
                            </ul>
                            <p>Emails lacking authentication indicators (SPF and DMARC) indicate phishing emails that also contain malware samples. Further the originator IP address has been reported multiple times from distinct sources with the first report on 06th March 2023, and the most recent being 3 months ago.</p>
                            <br />
                            <h4>EMAIL Body Content Analysis:</h4>
                            <p>The attachment in the email is send by the attacker to the victim via phishing email, which contains zip file as shown in (figure 1).</p>
                            <img src='/images/blog-images/sample105.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure 1: Phishing Email containing Zip file</p>
                            <br />
                            <p>On downloading zip and on unzipping it, exe file will be extracted as shown in (figure 1).</p>
                            <img src='/images/blog-images/sample106.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                            <p>Figure2: EXE file extracted on unzipping the downloaded file</p>
                            <h4>File Details:</h4>
                            <Table responsive>
                                <tr>
                                    <th><p>MD5</p></th>
                                    <th><p>bd43373032914b158e1c011251f7402b</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-1</p></th>
                                    <th><p>849d897908340069afadb1ca61bc8a9bbb1f18b1</p></th>
                                </tr>
                                <tr>
                                    <th><p>SHA-256</p></th>
                                    <th><p>accc20bc6b2bb01f68c7790e0c5a3a62bc30f8366fb6db99a2315a42a8ed7b2b</p></th>
                                </tr>
                                <tr>
                                    <th><p>File type</p></th>
                                    <th><p>Win32 EXE</p></th>
                                </tr>
                                <tr>
                                    <th><p>Target Machine</p></th>
                                    <th><p>x32</p></th>
                                </tr>
                                <tr>
                                    <th><p>File size</p></th>
                                    <th><p>404.50 KB (414208 bytes)</p></th>
                                </tr>
                                <tr>
                                    <th><p>Compiler</p></th>
                                    <th><p>.NET executable</p></th>
                                </tr>
                                <tr>
                                    <th><p>Signature</p></th>
                                    <th><p>Not signed</p></th>
                                </tr>
                                <tr>
                                    <th><p>Legal Copy right</p></th>
                                    <th><p>Copyright 2023</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Version</p></th>
                                    <th><p>1, 0, 0, 0</p></th>
                                </tr>
                                <tr>
                                    <th><p>Product Name </p></th>
                                    <th><p>Windows Application 3</p></th>
                                </tr>
                                <tr>
                                    <th><p>File Description </p></th>
                                    <th><p>Windows Application 3</p></th>
                                </tr>
                            </Table>
                            <br />
                            <h4>Static Analysis</h4>
                            <ul>
                                <li><p>Sequretek Malware Analysis Team analysed the sample to determine the infection methodology.</p></li>
                                <li><p>It is a .NET (v4.0. 30319)  executable file with a compiled date of 2023-04-25 17:46:52 UTC. (Figure3)</p></li>
                                <img src='/images/blog-images/sample107.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure3: .NET (v4.0. 30319) executable file</p>
                                <br />
                                <li><p>The malware uses internal name “fcA.exe”, as shown in (Figure4).</p></li>
                                <img src='/images/blog-images/sample108.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 4: Internal name of the malware</p>
                                <br />
                                <li><p>Malware use random function name to hide its actual activity, a typical “trojan.msil/lovgate” pattern.</p></li>
                                <img src='/images/blog-images/sample109.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 5: Random function name of the malware</p>
                                <br />
                                <p>Further, the malware deletes Zone Identifier to remove its origin as an untrusted file downloaded from the Internet.</p>
                                <img src='/images/blog-images/sample110.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 6: Malware deletes Zone Identifier.</p>
                                <br />
                                <p>Dynamic Analysis</p>
                                <li><p>Sequretek Malware Analysts executed the sample in our sandbox environment to observe its behaviour and the following activities were observed: –</p></li>
                                <li><p>On execution, it copies itself to the location “%appdata%” and names itself “exe”.</p></li>
                                <img src='/images/blog-images/sample111.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure7: Copy and execute the payload from the location</p>
                                <br />
                                <li><p>Uses schtasks.exe or at.exe to add and modify task schedules. The malware adds a scheduled task “Nafifas” via the schtasks command line utility to enable persistence.</p></li>
                                <img src='/images/blog-images/sample112.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 8: Set task schedule</p>
                                <br />
                                <p>“msil/lovgate” stores all the important configuration data and IoC’s such as browser names list, c2 host name, SMTP credentials and sends to attacker to maintain a persistent connection.</p>
                                <img src='/images/blog-images/sample113.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 9: Malware keeps its configuration data</p>
                                <br />
                            </ul>
                            <h4>Network Activity</h4>
                            <ul>
                                <li><p>During our analysis, we found that the malware used port 25 to send data to the id “info@pacificbluesmashrepairs.com.au”, as shown in (figure 10)</p></li>
                                <img src='/images/blog-images/sample114.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 10: SMTP connection for sharing information</p>
                                <br />
                                <li><p>Payload communicates to the remote address as shown in (figure 11)</p></li>
                                <img src='/images/blog-images/sample115.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 11: Remote Address for data sharing</p>
                                <br />
                                <li><p>TCP stream of SMTP traffic shows stolen data sent to the attacker’s email account as shown in (figure-12).</p></li>
                                <img src='/images/blog-images/sample116.png' alt='' style={{ width: window.innerWidth > 767 ? '' : '100%' }} />
                                <p>Figure 12: TCP Stream</p>
                                <br />
                            </ul>
                            <h4>Indicators of Compromise:</h4>
                            <ul>
                                <li>
                                    <p>File Hashes:</p>
                                    <ul>
                                        <li><p>bd43373032914b158e1c011251f7402b</p></li>
                                        <li><p>849d897908340069afadb1ca61bc8a9bbb1f18b1</p></li>
                                        <li><p>accc20bc6b2bb01f68c7790e0c5a3a62bc30f8366fb6db99a2315a42a8ed7b2b</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>IP Address:</p>
                                    <ul>
                                        <li><p>199[.]79[.]62[.]20</p></li>
                                        <li><p>212[.]87[.]204[.]134</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Sub-Domain</p>
                                    <ul>
                                        <li><p>bh-20[.]webhostbox[.]net</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Domain</p>
                                    <ul>
                                        <li><p>Webhostbox[.]net</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Email-ID</p>
                                    <ul>
                                        <li><p>admin@seilhitec[.]com</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <h4>MITRE ATT&CK Tactics and Techniques:</h4>
                            <p>Execution TA0002</p>
                            <ul>
                                <li>
                                    <p>Windows Management Instrumentation T1047</p>
                                    <ul>
                                        <li><p>Queries sensitive network adapter information (via WMI, Win32_NetworkAdapter, often done to detect virtual machines)</p></li>
                                        <li><p>Queries BIOS Information (via WMI, Win32_Bios)</p></li>
                                        <li><p>exe queries hardware properties via WMI.</p></li>
                                        <li><p>Queries sensitive processor information (via WMI, Win32_Processor, often done to detect virtual machines)</p></li>
                                        <li><p>Queries process information (via WMI, Win32_Process)</p></li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Scheduled Task/Job T1053</p>
                                    <ul>
                                        <li><p>Uses schtasks.exe or at.exe to add and modify task schedules</p></li>
                                        <li><p>Schedules task “Nafifas” via the schtasks command line utility</p></li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Persistence TA0003</p>
                            <br />
                            <h4>Conclusion:</h4>
                            <p>“trojan.msil/lovgate” is a spyware Trojan written in the .NET framework that has been observed since at least 2014. The malware has many features like key logging, clipboard capture, and screen capturing and extracting stored passwords from many browsers and beacons this information back to the C2. It supports all versions of the Windows operating system.</p>
                            <h4>Best Practices for Risk Reduction against Ransomware Attacks:</h4>
                            <ul>
                                <li><p><strong>Backup your data:</strong> Ensure that your organization’s data is regularly backed up and the backups are stored in a separate location. This will help you recover your data if you fall victim to any ransomware attack.</p></li>
                                <li><p><strong>Keep software up to date:</strong> Ensure that your organization’s operating systems, software applications and other devices are updated with the latest security patches and updates. Regularly updating your software will help prevent known vulnerabilities from being exploited.</p></li>
                                <li><p><strong>Implement a strong password policy at your organization:</strong> Ensure that your employees use strong passwords and two-factor authentication to access organization’s systems and data. Weak passwords can be easily targeted by ransomware attackers.</p></li>
                                <li><p><strong>Regularly train employees on security best practices:</strong> Provide regular training to your employees on how to identify and avoid suspicious emails, links, and attachments that could be potential ransomware vectors.</p></li>
                                <li><p><strong>Limit access:</strong> Restrict access to sensitive organization’s data to only those who need it. This will help prevent ransomware from spreading through your organization’s network.</p></li>
                                <li><p><strong>Implement network segmentation:</strong> Segment your organization’s network into smaller subnetworks to prevent the spread of ransomware in the event of an attack.</p></li>
                                <li><p><strong>Use security tools:</strong> Implement security tools such as firewalls, antivirus / EDR, intrusion detection and prevention systems, identity governance and administration (IGA) and security monitoring / extended detection & response (XDR) solutions to monitor, protect, respond and remediate against malicious activities / incidents at your organization.</p></li>
                                <li><p><strong>Have a plan:</strong> Develop a plan for responding to ransomware attacks. This should include identifying key personnel and responsibilities, having a backup and recovery plan, and having a communication plan in place to inform stakeholders of the situation</p></li>
                            </ul>


                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Sequretek Blog-Trojan.msil lovgate Analysis' , url: 'https://www.sequretek.com/resources/blog/trojan-msil-lovgate-analysis'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
