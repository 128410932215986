import React, { Fragment, useEffect, useState } from 'react';
import './sidebar.scss';
import { useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';
import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { getMenus, trackLogin, logOutUser } from '../../Redux/ActionCreators/sidebarAction';
import { clearErrors } from '../../Redux/ActionCreators/errorAction';


/* ######################################### Partner Portal Header and Sidebar ########################### */

function Sidebar() {

    const [activeTab, setActiveTab] = useState('users');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const sideMenuList = useSelector(state => state.sidebar.sideMenuList);
    const error = useSelector(state => state.error);

    useEffect(() => {
        const firstRender = async () => {
            dispatch(getMenus());
            dispatch(trackLogin());
            updateDimensions();
            setActiveTab(window.location.pathname.split('/')[2]);
        }
        firstRender();
    }, [])

    useEffect(() => {
        setActiveTab(window.location.pathname.split('/')[2]);
    }, [location])


    const logout = async () => {
        const res = await dispatch(logOutUser());
        if (res?.type === 'Logout_user') {
            localStorage.removeItem('adminAuthToken');
            localStorage.removeItem('loginDetails');
            localStorage.removeItem('role');
            navigate('/partner-portal-login');
        }
    }

    const isSidebar = () => {
        if ($("body").hasClass("BodyCloseNav")) {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        } else {
            document.body.classList.add("BodyCloseNav");
            document.body.classList.remove("scrollhidden");
        }
    };

    const updateDimensions = () => {
        let w = window.screen.width;
        if (w <= 1024) {
            document.body.classList.add("BodyCloseNav");
        } else {
            document.body.classList.remove("BodyCloseNav");
            document.body.classList.add("scrollhidden");
        }
    };

    const isTabActive = (pathname) => {
        setActiveTab(pathname);
        // if (window.innerWidth < 767) {
        document.body.classList.add("BodyCloseNav");
        document.body.classList.remove("scrollhidden");
        // }
    }


    const getIntial = (fname, lname) => {
        const i1 = fname?.split('')[0]
        const i2 = lname?.split('')[0]
        return i1 + i2
    }

    const snackClosed = () => {
        dispatch(clearErrors());
    }

    // const { profileDetails } = profile


    return (
        <Fragment >

            <div className='dashboard-container'>
                {
                    <div className="side-bar">

                        <div className="logo-dashboard">
                            <div className='logo'  >
                                <div >
                                    <img src='/images/logo.svg' alt='' width={210} />
                                </div>

                            </div>
                        </div>
                        <div className="menu-close">
                            <button
                                type="button"
                                className="close"
                                onClick={() => isSidebar()}
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className='profile-overflow'>
                            <div className="dashboard-links mt-2">
                                <ul>
                                    {sideMenuList.map((items, i) => {
                                        return (
                                            <Link
                                                key={i}
                                                to={`/app/${items.action}`}
                                                onClick={() => isTabActive(items.action)}
                                            >
                                                <li
                                                    id={"sidebar-item-" + i}
                                                    className={
                                                        activeTab === items.action
                                                            ? "dashboard-active"
                                                            : null
                                                    }
                                                >
                                                    <img
                                                        src={activeTab === items.action ? items.activeIcon : items.icon}
                                                        alt=''
                                                    />
                                                    {items.text}
                                                </li>
                                            </Link>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                <div className="dashboard-header">
                    <div className="Colapselink">
                        <button
                            className="btn rounded-circle  d-block d-xl-none"
                            onClick={() => isSidebar()}
                        >
                            <img src="/images/colapselink.svg" alt='' />
                        </button>
                    </div>
                    <div className="dashboard-profile-holder">
                        <div className='d-flex align-items-center'>
                            <h4 className='mb-0 text-white'>{localStorage.getItem('fname')} {localStorage.getItem('lname')}</h4>&nbsp;&nbsp;
                            <div className="dropmain-holder d-inline-block">
                                <div
                                    className="drop-profile-hold initial-circle-custom"
                                >
                                    {/* {profileDetails?.image ? (
                                        <img
                                            src={profileDetails?.image} alt=''
                                            onError={(e) => {
                                                if (e.target.src !== "./images/placeholder-image.jpg") {
                                                    e.target.onerror = "./images/placeholder-image.jpg";
                                                    e.target.src = "./images/placeholder-image.jpg";
                                                }
                                            }}
                                        />
                                    ) : */}
                                    {getIntial(localStorage.getItem('fname'), localStorage.getItem('lname'))}

                                    {/* } */}
                                </div>
                                <div className="drop-inst">

                                    {/* <div className="logout" onClick={() => navigate('/profile')}>

                                        Profile
                                    </div> */}
                                    <div className="logout" onClick={() => logout()}>
                                        {/* <img src='/images/logout.png' alt='' /> &nbsp; &nbsp; */}
                                        Sign Out
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashboard-header-heading">
                        <div
                            className="mobile-logo d-block d-md-none"
                        >
                            <div className='logo' >
                                <div style={{}}>
                                    <img src='/images/logo.svg' alt='' width={210} />
                                </div>
                                {/* <img src={localStorage.getItem("logo")} className="logo" /> */}
                            </div>
                        </div>

                    </div>


                    <div className="logo1"  >
                        <img src='/images/logo.svg' alt='' width={210} />

                    </div>

                </div>
                <div className='layout-container' style={{ paddingLeft: window.innerWidth < 767 ? 15 : 321 }}  >
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={error.successMessage}
                        autoHideDuration={3000}
                        onClose={snackClosed}
                    >
                        <Alert onClose={snackClosed} severity="error" sx={{ width: '100%' }}>
                            {error.messageText}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center', }}
                        open={error.successEnable}
                        onClose={() => snackClosed()}
                        message={error.messageText}
                        autoHideDuration={4000}
                    >
                        <Alert onClose={snackClosed} severity="success" sx={{ width: '100%' }}>
                            {error.messageText}
                        </Alert>
                    </Snackbar>
                    <React.Suspense fallback={''}>
                        <Outlet />
                    </React.Suspense>
                </div>

            </div>
        </Fragment >

    )
}




export default Sidebar
