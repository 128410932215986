// ############################ Require packages here ############################
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// ############################ Require files here ###############################

import RelatedResources from '../relatedResources';
import ScheduleForm from '../scheduleForm';
import ShareUrl from './shareUrl';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

export default function Podcasts() {
    const [details, setDetails] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { relatedResources } = useSelector(state => state.resources);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (location?.state?.data) {
            setDetails(location?.state?.data)
        }
    }, [location?.state?.data])

    useEffect(() => {
        window.scrollTo(0, 0);
        const firstRender = async () => {
            if (!location?.state?.data) {
                const resourceData = relatedResources?.filter(el => el.title === decodeURIComponent(window.location.pathname.split('/')[3]))[0];
                if (resourceData) {
                    await setDetails(resourceData);
                }
            }
        }
        firstRender();
    }, [relatedResources])


    return (
        <div className='seq-reports-view'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Listen to Our Podcasts | Expert Insights & Stories</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Tune into our podcasts for industry speak and engaging stories. Stay updated with the latest trends and deep dives into key industry topics." />
                <meta property="og:title" content="Sequretek | Listen to Our Podcasts | Expert Insights & Stories" />
                <meta property="og:description" content="Tune into our podcasts for industry speak and engaging stories. Stay updated with the latest trends and deep dives into key industry topics." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'podcast' } })}>{' > '} Podcasts</span><span> {' > '} {details?.title}</span></li>
                    </ul>
                </div>
                {/* section 2-1 PLAYER */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>{details?.title}</h3>
                        <div className='video-player' >
                            <ReactPlayer className='player-style' url={details?.url} width='100%' height={window.innerWidth > 767 ? '583px' : '160px'} />
                        </div>
                    </div>
                </div>
                <ShareUrl details={details} />
                {/* section 2-2 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-2'}>
                                    <RelatedResources
                                        page='resources'
                                        type='podcast'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 3 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>

        </div >
    )
}
