import React from 'react';
import Modal from 'react-bootstrap/Modal';


export default function ConfirmModal({ isConfirm, setIsConfirm, removeConfirm, type }) {
    return (
        <div>
            {/* ########## Confirm Delete modal ############### */}
            <Modal
                show={isConfirm}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4" >
                    <h1>
                        Are you sure you want to remove this {type === 'user' ? 'user' : type === 'lead' ? 'Lead' : type === 'deal' ? 'Deal' : type === 'category' ? 'Category' : type === 'resource-bank' ? 'Resource' : type === 'ticket' ? 'Ticket' : null}?
                    </h1>
                    <div className="save-btn">
                        <button onClick={removeConfirm}>Yes</button>&nbsp;&nbsp;
                        <button onClick={() => setIsConfirm(false)}>No</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
