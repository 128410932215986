// ############################ Require packages here ############################
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Table } from 'react-bootstrap';

// ############################ Require files here ###############################

import './resources.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
import ShareUrl from './shareUrl';

export default function Blogs2() {

    const navigate = useNavigate();

    return (
        <div className='seq-reports-view' >
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Living Off the Land (LoTL) Attacks: Navigating Hidden Cyber Threats</title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Discover how attackers use LoTL techniques to exploit system tools for malicious purposes. Explore Sequretek’s strategies to detect and counter these elusive threats." />
                <meta name="keywords" content="Cyberattacks, Cyber security, endpoint security" />
                <meta property="og:title" content="Living Off the Land (LoTL) Attacks: Navigating Hidden Cyber Threats" />
                <meta property="og:description" content="Discover how attackers use LoTL techniques to exploit system tools for malicious purposes. Explore Sequretek’s strategies to detect and counter these elusive threats." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='container contain-width'>
                <div className='breadcrumb pe-0 ps-0'>
                    <ul>
                        <li><span onClick={() => navigate('/resources-hub')}>Resources  </span>
                            <span onClick={() => navigate('/resources-hub', { state: { type: 'blog' } })}>{' > '} Blogs</span>
                            <span>{' > '} Living off the Land (LOTL) Attacks Play the Blind Spots</span>
                        </li>
                    </ul>
                </div>
                {/* section 2 DISCRIPTION */}
                <div className='seq-report-view'>
                    <div className='blog-div'>
                        <h3>Living off the Land (LOTL) Attacks Play the Blind Spots</h3>
                        <p>15-FEB-2024</p>
                        <div className='blog-body'>
                            <img src='/images/blog-images/sample8.jpg' width={'100%'} alt='' loading="lazy" />
                            <h4>Understanding LOTL</h4>
                            <p>The current security models rely predominantly on discrete IoCs that relate to processes, file system changes, network traffic and registry changes.  However, like every defence system, a computer defence system too has a weak link.  Additionally, to avoid being detected due to known IoCs, attackers utilise living off the land (LoTL) tactics, where they use tools are an intrinsic part and already installed on the target system.  They try to drop as few malicious files as possible to avoid detection.  Only using clean system tools, and not having a malicious binary file on disk that could be scanned, means that some traditional security measures will not be able to detect and block the attack.</p>
                            <h4>Some of the other features of this attack vector include:-</h4>
                            <ul>
                                <li><p>Even when log files are generated it can be difficult to spot anomalies.</p></li>
                                <li><p>The use of system tools and common cloud services for data exfiltration does not often ring alarm bells.</p></li>
                                <li><p>Even if an attack is discovered, the living off the land approach makes it difficult to attribute the attack to a specific attack group as all groups use similar techniques and tools.</p></li>
                            </ul>
                            <p>Similar attack methods are quite common on Unix systems, where most of the work is done by command line tools.  Python, Perl, or Bash scripts, together with system binaries, can provide all the functionality that an attacker needs on a Unix computer. [i]</p>
                            <h4>Debunking Myths</h4>
                            <p>It is easy to misconstrue LoTL attacks with fileless attacks because of how nuanced their approaches are.  ­­Here’s the distinction between malware and fileless malware based on “living off the land” techniques:</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Feature</p></th>
                                    <th><p>Traditional Malware</p></th>
                                    <th><p>Fileless Malware</p></th>
                                    <th><p>Living off the Land</p></th>
                                </tr>
                                <tr>
                                    <td><p>Definition</p></td>
                                    <td><p>Malicious software designed to harm</p></td>
                                    <td><p>Exploits legitimate tools without files</p></td>
                                    <td><p>Broader approach using legitimate tools</p></td>
                                </tr>
                                <tr>
                                    <td><p>File footprint</p></td>
                                    <td><p>Viruses, worms, Trojans</p></td>
                                    <td><p>Scripts, commands, shellcode, registry malware</p></td>
                                    <td><p>Scripting, exploiting vulnerabilities, manipulating settings</p></td>
                                </tr>
                                <tr>
                                    <td><p>LoTL subset?</p></td>
                                    <td><p>No</p></td>
                                    <td><p>Many LoTL techniques are fileless malware</p></td>
                                    <td><p>Broader term, not all LoTL is fileless</p></td>
                                </tr>
                                <tr>
                                    <td><p>Dual-use tools?</p></td>
                                    <td><p>Not directly related</p></td>
                                    <td><p>Downloading is not LoTL due to file activity</p></td>
                                    <td><p>No, violates LoTL criteria</p></td>
                                </tr>
                            </Table>
                            <h4>LOTL Techniques</h4>
                            <p>Combining multiple techniques is common in LoTL attacks.  Here are some key techniques:</p>
                            <ul>
                                <li><p>Exploit Kits: Targeting existing vulnerabilities for direct memory injection (fileless attacks).</p></li>
                                <li><p>Tool Hijacking: Misusing legitimate tools like FTP, PowerShell, WMI for privilege escalation, lateral movement, data theft, and backdoor creation.</p></li>
                                <li><p>Registry & Memory-based Malware: Evading detection by residing in registry or memory.</p></li>
                                <li><p>Stolen Credentials: Leveraging compromised accounts for further access and native tool usage.[ii]</p></li>
                            </ul>
                            <h4>Legitimate Tools Often Exploited for LotL Attacks</h4>
                            <p>The obvious advantage for the attacker is that clean and legitimate system utilities and tools are executed for malicious purposes. This can bypass most application whitelisting approaches as well as some security tools.[iii]</p>
                            <Table responsive>
                                <tr>
                                    <th><p>Tool</p></th>
                                    <th><p>Description</p></th>
                                    <th><p>LoTL Uses</p></th>
                                </tr>
                                <tr>
                                    <th><p>PowerShell Scripts</p></th>
                                    <th><p>Versatile scripting language for Windows automation and administration.</p></th>
                                    <th>
                                        <p>Downloading malicious payloads,Executing commands for lateral movement and privilege escalation</p>
                                        <p>Installing persistent malware through scheduled tasks</p>
                                        <p>Stealing and exfiltrating data.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>VB Scripts</p></th>
                                    <th><p>Less common but still used for scripting tasks.</p></th>
                                    <th>
                                        <p>Similar to PowerShell scripts, performing malicious actions like downloading payloads, automating tasks, and exfiltrating data.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>WMI (Windows Management Instrumentation)</p></th>
                                    <th><p>Interface for managing and automating Windows systems.</p></th>
                                    <th>
                                        <p>Performing reconnaissance to identify vulnerabilities and system information. </p>
                                        <p>Executing commands remotely for lateral movement and privilege escalation.</p>
                                        <p>Modifying system settings for persistence.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>Mimikatz</p></th>
                                    <th><p>Password dumping and credential theft tool.</p></th>
                                    <th>
                                        <p>Stealing credentials from memory, allowing further access and privilege escalation.</p>
                                        <p>Obtaining credentials for lateral movement and accessing sensitive resources,</p>
                                        <p> Resetting passwords to maintain persistence.</p>
                                    </th>
                                </tr>
                                <tr>
                                    <th><p>PsExec</p></th>
                                    <th><p>Remote command execution tool.</p></th>
                                    <th>
                                        <p> Executing commands across the network without requiring local access.</p>
                                        <p> Performing actions like installing malware, stealing data, and establishing persistence.</p>
                                        <p> Providing attackers with remote control over compromised systems.</p>
                                    </th>
                                </tr>
                            </Table>
                            <h4>The LOLBAS Project</h4>
                            <p>The LOLBAS project, originally called Living Off the Land Binaries, Scripts, and Libraries, serves a crucial defensive purpose by helping security professionals identify and understand legitimate tools and resources that attackers could potentially exploit in LoTL attacks.  Here’s how LOLBAS plays a role in defending against LoTL attacks:</p>
                            <ul>
                                <li><p>Cataloguing LoTL tools: LOLBAS maintains a comprehensive list of common tools and techniques used in LoTL attacks, such as PowerShell scripts, WMI commands, and native executables.</p></li>
                                <li><p>Understanding legitimate vs. malicious use: For each tool, LOLBAS provides information about its legitimate uses and how it can be misused in malicious contexts.</p></li>
                                <li><p>Detection and prevention: This information helps security professionals better detect suspicious activity within their systems and implement preventive measures to mitigate the risk of LoTL attacks. [iv]</p></li>
                            </ul>
                            <h4>Human-Operated Targeted Attacks using LoTL techniques[v]</h4>
                            <p>These campaigns represent a rising trend in the attack landscape, combining the strategic planning and manual intervention of human attackers with the stealthy techniques of Living Off the Land (LoTL) tactics. Here’s how it works:</p>
                            <ul>
                                <li><p>Targeting and Initial Infiltration:</p></li>
                                <li><p>Unlike automated attacks, these campaigns meticulously target specific victims. Attackers research vulnerabilities, valuable data, and potential entry points.</p></li>
                                <li><p>Social engineering tactics like phishing emails, exploiting privileged access, or compromising third-party connections are common ways to gain initial access.</p></li>
                                <li><p>Leveraging LoTL Tactics:</p></li>
                                <li><p>Once inside, attackers leverage legitimate system tools and resources to minimize their footprint and evade detection.</p></li>
                                <li><p>Scripts written in PowerShell, commands like WMI, and other native tools allow them to move laterally across the network undetected.</p></li>
                                <li><p>Persistence mechanisms like modifying registry entries or utilising memory-based malware ensure they maintain access even after initial detection.</p></li>
                                <li><p>Human Intervention and Escalation:</p></li>
                                <li><p>Unlike automated attacks, human operators can adapt their tactics based on real-time observations and exploit specific weaknesses they discover.</p></li>
                                <li><p>They actively seek ways to escalate privileges and gain access to critical systems or valuable data.</p></li>
                                <li><p>Ransomware or other malicious payloads can be tailored to the specific victim’s environment for maximum impact.</p></li>
                            </ul>
                            <h4>Examples:</h4>
                            <ul>
                                <li><p>Ransomware: Encrypting data using native disk utilities, exfiltrating data via DNS tunnelling.</p></li>
                                <li><p>Financial Trojans: Injecting code into browsers, using tools like Mimikatz for credential theft.</p></li>
                                <li><p>Espionage: Stealing intellectual property using hidden processes and exploiting internal tools.</p></li>
                            </ul>
                            <h4>Ransomware Groups Embracing LoTL Tactics[vi]</h4>
                            <p>Cyber criminals are adopting these tactics to spread threats like ransomware and financial Trojans. Here are some recent examples of attack groups using LoTL techniques to deploy ransomware:</p>
                            <ul>
                                <li><p>AppleJeus: It primarily uses LoTL tactics after initial infection, minimising file activity and evading traditional detection. PowerShell and WMI play key roles in lateral movement, reconnaissance, and persistence. The attack highlights the increasing use of LoTL methods by sophisticated threat actors like Lazarus Group.</p></li>
                                <li><p>LockBit: This notorious ransomware gang is known for using a combination of LoTL and RaaS (Ransomware-as-a-Service) models. They leverage PowerShell scripts, WMI commands, and other native tools for lateral movement, privilege escalation, and encryption.  Their attacks have targeted major corporations and critical infrastructure providers worldwide.</p></li>
                                <li><p>Conti: This highly active ransomware group also relied heavily on LoTL tactics during their peak, frequently using PowerShell, PsExec, and other legitimate tools to achieve their goals. Conti’s operations were disrupted in early 2023, but their techniques continue to inspire other attackers.</p></li>
                                <li><p>ALPHV (aka ALPHVbet, BlackCat): This group has emerged as a major player in the ransomware landscape, known for its sophisticated attack methods and focus on high-value targets. They utilise LoTL techniques extensively, including PowerShell, WMI, and Mimikatz for credential theft and persistence.</p></li>
                                <li><p>Vice Society: This Russian ransomware group has gained notoriety for its attacks on healthcare and education institutions. Their tactics involve a combination of social engineering, exploit kits, and LoTL techniques like PowerShell scripting and scheduled tasks for remote access and ransomware deployment.</p></li>
                                <li><p>Karakurt: This group primarily targets Turkish organizations and has utilized LoTL methods like exploiting vulnerabilities in Microsoft Exchange servers and using PowerShell scripts for lateral movement and data exfiltration.</p></li>
                            </ul>
                            <h4>Conclusion</h4>
                            <p><u>Sequretek</u> provides valuable assistance to detect abnormal behaviour associated with LoTL attacks:</p>
                            <p><u>Percept Endpoint Detection and Response (EDR):</u></p>
                            <ul>
                                <li><p>App whitelisting serves as a foundational layer, limiting the use of scripting languages or implementing strict controls can reduce the risk of these attacks.</p></li>
                                <li><p>EDR monitors running processes and identifies anomalies like unexpected child processes, excessive resource usage, or unauthorized execution in unusual locations</p></li>
                                <li><p>EDR Telemetry acts as a monitoring and detection system, identifying deviations from expected behaviour that might indicate LoTL activity.</p></li>
                                <li><p>EDR can utilize advanced analytics techniques like machine learning to detect subtle LoTL attacks that might evade basic signature-based detection.</p></li>
                            </ul>
                            <p><u>Percept Extended Detection and Response (XDR):</u></p>
                            <ul>
                                <li><p>XDR goes beyond EDR by collecting data from various sources beyond endpoints (network, cloud, applications) and correlating it for a more comprehensive view.</p></li>
                                <li><p>This broader perspective helps detect LoTL attacks that involve actions across different systems, providing a wider net for catching stealthy tactics.</p></li>
                                <li><p>XDR can leverage UEBA to analyse user and entity behaviour across the entire environment, detecting anomalous activities indicating LoTL tactics like unusual privilege escalations, suspicious file transfers, or unauthorised access attempts.</p></li>
                            </ul>
                            <p>Security Operations Centre (SOC):</p>
                            <ul>
                                <li><p>Sequretek SOC team consist of cybersecurity professionals who monitor security systems, analyse alerts, and respond to incidents in real-time.</p></li>
                                <li><p>They possess expertise in detecting and mitigating LoTL attacks, offering specialised knowledge and rapid response capabilities.</p></li>
                                <li><p>Threat Hunting:</p></li>
                                <li><p>Sequretek’s Threat hunting capability involves proactively searching for threats within your environment, uncovering LoTL attacks that might evade traditional detection methods.</p></li>
                                <li><p>Additional Services:</p></li>
                                <li><p>Vulnerability Management: Identify and patch vulnerabilities that attackers could exploit for LoTL attacks.</p></li>
                                <li><p>Security Awareness Training: Educate employees to recognize and avoid social engineering tactics used in LoTL campaigns.</p></li>
                                <li><p>Incident Response: Assist with investigating and remediating LoTL attacks after they occur.</p></li>
                            </ul>
                            <h4>References:</h4>
                            <p>[i] <a target='_blank' rel="noreferrer" href='https://socradar.io/living-off-the-land-lotl-the-invisible-cyber-threat-lurking-in-your-system/'>https://socradar.io/living-off-the-land-lotl-the-invisible-cyber-threat-lurking-in-your-system/</a></p>
                            <p>[ii] <a  target='_blank' rel="noreferrer" href='https://docs.broadcom.com/doc/istr-living-off-the-land-and-fileless-attack-techniques-en'>https://docs.broadcom.com/doc/istr-living-off-the-land-and-fileless-attack-techniques-en</a></p>
                            <p>[iii] <a target='_blank' rel="noreferrer" href='https://www.kiteworks.com/risk-compliance-glossary/living-off-the-land-attacks/'>https://www.kiteworks.com/risk-compliance-glossary/living-off-the-land-attacks/</a></p>
                            <p>[iv] <a target='_blank' rel="noreferrer" href='https://lolbas-project.github.io/'>https://lolbas-project.github.io/</a></p>
                            <p>[v] <a target='_blank' rel="noreferrer" href='https://www.sentinelone.com/blog/inside-the-mind-of-a-cyber-attacker-tactics-techniques-and-procedures-ttps-every-security-practitioner-should-know/'>https://www.sentinelone.com/blog/inside-the-mind-of-a-cyber-attacker-tactics-techniques-and-procedures-ttps-every-security-practitioner-should-know/</a></p>
                            <p>[v] <a target='_blank' rel="noreferrer" href='https://attack.mitre.org/'>https://attack.mitre.org/</a></p>
                        </div>
                    </div>
                </div>
                <ShareUrl 
                    details = {{title:'Living Off the Land (LoTL) Attacks: Navigating Hidden Cyber Threats'  , url: 'https://www.sequretek.com/resources/blog/living-off-the-land-attacks'}}
                />
                {/* section 3 RELATED RESOURCES */}
                <div className='seq-resources'>
                    <div className={window.innerWidth > 767 ? 'ps-0' : 'ps-3'}>
                        <h4>Related resources</h4>
                        <div className='row ms-0 me-0'>
                            <div className='col-md-12 ps-0'>
                                <div className={window.innerWidth > 767 ? 'row ' : 'row flex-nowrap overflow-auto ms-0'}>
                                    <RelatedResources
                                        page='resources'
                                        type='blog'
                                        title='Living off the Land Attacks Play the Blind Spots'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container '>
                    <div className="insurance-partseven">
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/bg-img23.png'}
                            page='resources'
                            text={`Don't wait until it's too late. Strengthen your cyber defences today with Sequretek. Contact us to schedule a demo and learn how our cybersecurity solutions can arm your enterprise against evolving threats.`}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
