import {
    RESET_LIST, GET_ALL_CATEGORY, SEARCH_CATEGORY, ADD_CATEGORY, CHANGE_CATEGORY_STATUS, DELETE_CONTENT,
    GET_INDUSTRIES, GET_TOPICS, GET_SERVICES, GET_TYPE_COUNT, GET_RESOURCES, GET_MIXED_RESOURCE, GET_NEXT_RESOURCES, GET_CATEGORY_RESOURCES, ADD_CATEGORY_RESOURCE, REMOVE_CATEGORY_RESOURCE,
    RESET_RESOURCE_LOADER, GET_EXTERNAL_RESOURCES, GET_EXTERNAL_INDUSTRIES, GET_EXTERNAL_TOPICS, GET_EXTERNAL_TYPES, GET_RELATED_RESOURCES
} from "../Types/types";

// This function was to initially store resources details

let intialState = {
    isLoading: false,
    categories: [],
    resourceList: [],
    resourceListForCategory: [],
    industries: [],
    services: [],
    topcis: [],
    types: [{ label: 'Blog', value: 'blog' }, { label: 'Report', value: 'report' }, { label: 'Infographics', value: 'infographic' }, { label: 'Advisory', value: 'advisory' }, { label: 'Video', value: 'video' }, { label: 'Event & Webinars', value: 'event' }, { label: 'Press Release', value: 'press' }, { label: 'Media Coverage', value: 'media' }, { label: 'Whitepaper', value: 'whitepaper' },
    { label: 'Case Study', value: 'case-study' }, { label: 'Datasheets', value: 'datasheet' },
    ],
    extIndustries: [],
    extTopics: [],
    extTypes: [],
    externalResources: [],
    isLoadingResource: true,
    resourceTypes: []
}

// This function was to initially store Related Resources details

export default function resourceReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case RESET_LIST:
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_ALL_CATEGORY:
            stateCopy.categories = action.payload;
            stateCopy.categoriesCopy = action.payload;
            stateCopy.isLoading = false;
            return stateCopy;
        case SEARCH_CATEGORY:
            if (action.payload === '') {
                stateCopy.categories = stateCopy.categoriesCopy;
            }
            else {
                const filterResult = stateCopy.categoriesCopy.filter(el => { return el.name.toLowerCase().includes(action.payload.toLowerCase()) });
                stateCopy.categories = filterResult;
            }
            return stateCopy;
        case ADD_CATEGORY:
            stateCopy.categories = [...stateCopy.categories, action.payload];
            return stateCopy;
        case CHANGE_CATEGORY_STATUS:
            if (action.payload.type === 'category') {
                stateCopy.categories.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.active = action.payload.checked
                    }
                })
            } else {
                stateCopy.resourceList.forEach(el => {
                    if (el._id === action.payload._id) {
                        el.active = action.payload.checked
                    }
                })
            }
            return stateCopy;
        case DELETE_CONTENT:
            if (action.payload.type === 'category') {
                stateCopy.categories = stateCopy.categories.filter(el => el._id !== action.payload.id);
            } else {
                stateCopy.resourceList = stateCopy.resourceList.filter(el => el._id !== action.payload.id);
            }
            return stateCopy;
        case GET_TYPE_COUNT:
            stateCopy.resourceTypes = action.payload.map(el => [{ ...el, label: el.displayname, value: el.type }][0]);
            stateCopy.websiteResources = action.payload.map(el => [{ ...el, label: el.displayname, value: el.type }][0]);
            return stateCopy;
        case GET_RESOURCES:
            stateCopy.resourceList = action.payload.data;
            stateCopy.pages = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_NEXT_RESOURCES:
            stateCopy.resourceList = stateCopy.resourceList.concat(action.payload.data);
            stateCopy.pages = action.payload.pages;
            stateCopy.isLoading = false;
            return stateCopy;
        case GET_MIXED_RESOURCE:
            stateCopy.mixedResources = action.payload.data.filter(el => el.active);
            return stateCopy;
        case GET_CATEGORY_RESOURCES:
            stateCopy.resourceListForCategory = action.payload;
            return stateCopy;
        case GET_INDUSTRIES:
            stateCopy.industries = action.payload?.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_TOPICS:
            stateCopy.topics = action.payload?.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_SERVICES:
            stateCopy.services = action.payload?.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;

        case ADD_CATEGORY_RESOURCE:
            const updatedList = stateCopy.resourceList.map((el) => {
                if (el._id === action.payload._id) {
                    return { ...el, checked: true };
                }
                return el;
            });
            let newData = {
                ...stateCopy,
                resourceList: [...updatedList],
                resourceListForCategory: [...stateCopy.resourceListForCategory, action.payload],
            };
            return newData;

        case REMOVE_CATEGORY_RESOURCE:
            const dataSource = [...stateCopy.resourceListForCategory];
            const filteredQuestions = dataSource.filter(
                (allQuestions) => allQuestions._id !== action.payload
            );
            return {
                ...stateCopy,
                resourceList: [
                    ...stateCopy.resourceList.map((el) => {
                        if (el.id === action.payload) {
                            return { ...el, checked: false };
                        }
                        return el;
                    }),
                ],
                resourceListForCategory: filteredQuestions,
            };
        case RESET_RESOURCE_LOADER:
            stateCopy.isLoadingResource = true;
            stateCopy.isLoading = true;
            return stateCopy;
        case GET_EXTERNAL_RESOURCES:
            stateCopy.externalResources = action.payload.data;
            stateCopy.total = action.payload.pages;
            stateCopy.isLoadingResource = false;
            return stateCopy;
        case GET_EXTERNAL_INDUSTRIES:
            stateCopy.extIndustries = action.payload?.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_EXTERNAL_TOPICS:
            stateCopy.extTopics = action.payload?.map(el => [{ ...el, label: el.name, value: el._id }][0]);
            return stateCopy;
        case GET_EXTERNAL_TYPES:
            stateCopy.extTypes = action.payload.filter(el => ((el.count !== 0) && (el.website)))?.map(el => [{ ...el, label: el.displayname, value: el.type }][0]);
            return stateCopy;
        case GET_RELATED_RESOURCES:
            stateCopy.relatedResources = action.payload.data.filter(el => el.website);
            return stateCopy;
        default:
            return stateCopy;
    }
}
