// ############################ Require packages here ############################
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Spinner, Table } from 'react-bootstrap';
import Switch from "react-switch";
import Select, { components } from 'react-select';
import exportFromJSON from "export-from-json";


// ############################ Require files here ###############################

import '../Leads/leads.scss';
import Paginations from '../../../utils/pagination';
import AddUser from './addUser';
import { resetLists, getRoles, getSequertekUsers, addUser, resendInvite, handleStatusChange, updateUser, deletedUser, getPartnerList, getCountries, addPartner, updatePartner, getPartnerUsers, getUserExportData, getPartnerExportData, getPartnerUserExportData } from '../../../Redux/ActionCreators/usersAction';
import { TopicLoader } from '../../../Loader/loader';
import ConfirmModal from '../../../utils/confirmModal';
import { convertDate } from '../../../utils/miscellneous';


const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/arrow-down.svg' width={18} alt='' />
        </components.DropdownIndicator>
    );
};

export default function Users() {
    const [isAdd, setIsAdd] = useState(false);
    const [activeTab, setActiveTab] = useState('sequretek');
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [size, setSize] = useState(10);
    const [query, setQuery] = useState('');
    const [role, setRole] = useState(null);
    const [status, setStatus] = useState(null);
    const [country, setCountry] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [details, setDetails] = useState(null);
    const [search, setSearch] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [isView, setIsView] = useState(false);
    const [isResend, setIsResend] = useState(false);
    const [userDetails, setUserDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: "",
        dial_code: "+1",
        role: ''
    })
    const [partnerDetails, setPartnerDetails] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: "",
        dial_code: "+1",
        country: '',
        city: '',
        pincode: '',
        joined: '',
        company: ''
    });

    const [isPartnerView, setIsPartnerView] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [isConfirm, setIsConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const { roles, statusList, isLoading, sequertekUsers, totalPage, adminPartners, countries, partnerUsers, total, partnerRoles } = useSelector(state => state.users);

    const dispatch = useDispatch();
    const timerRef = React.useRef(null);
    const timerRef1 = React.useRef(null);


    useEffect(() => {
        dispatch(resetLists());
        setStatus(null);
        setCountry(null);
        setRole(null);
        setQuery('');
        setPage(1);
        const firstRender = async () => {
            dispatch(getRoles());
            if ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) {
                const details = JSON.parse(localStorage.getItem('profileDetails'));
                setIsPartnerView(true);
                setPartnerId(details?.partner_id);
                await dispatch(resetLists());
                await dispatch(getPartnerUsers(1, size, '', role?.value, status?.value, details?.partner_id));
            } else {
                if (activeTab === 'sequretek') {

                    dispatch(getSequertekUsers(1, size, '', '', ''));
                } else {
                    dispatch(getCountries())
                    dispatch(getPartnerList(1, size, '', '', ''));
                }
            }
        }
        firstRender();
    }, [activeTab])

    const handlePageChange = (pageNumber) => {
        setPageCount(pageNumber)
        if (activeTab === 'sequretek') {
            dispatch(getSequertekUsers(pageNumber, size, query, role?.value, status?.value));
        } else {
            dispatch(getPartnerList(pageNumber, size, query, country?.value, status?.value));
        }
    };

    const handlePage = (pageNumber) => {
        setPage(pageNumber);
        dispatch(getPartnerUsers(pageNumber, size, search, role?.value, status?.value, (details?._id || partnerId)));
    }

    const onBtnChange = (e) => {
        setActiveTab(e);
    }

    const selectRole = (e, type) => {
        setRole(e);
        setPage(1);
        setPageCount(1)
        setQuery('');
        setSearch('');
        if (type === 'sequretek') {
            dispatch(getSequertekUsers(1, size, '', e?.value, status?.value));
        } else {
            dispatch(getPartnerUsers(1, size, '', e?.value, status?.value, details?._id || partnerId));
        }
    }

    const selectCountry = (e) => {
        setCountry(e);
        setPage(1);
        setQuery('');
        dispatch(getPartnerList(1, size, '', e?.value, status?.value));
    }

    const selectStatus = (e, type) => {
        setStatus(e);
        setPage(1);
        setQuery('');
        setPageCount(1);
        setSearch('');
        if (type === 'sequretek') {
            dispatch(getSequertekUsers(1, size, '', role?.value, e?.value));
        } else if (type === 'partner') {
            dispatch(getPartnerList(1, size, query, country?.value, e?.value));
        } else {
            dispatch(getPartnerUsers(1, size, '', role?.value, e?.value, details?._id || partnerId));
        }
    }

    const searchQuery = (val) => {
        clearTimeout(timerRef.current);
        setQuery(val);
        setPage(1);
        timerRef.current = setTimeout(() => delaySearch(val), 800);
    };

    const delaySearch = async (val) => {
        if (activeTab === 'sequretek') {
            dispatch(getSequertekUsers(1, size, val, role?.value, status?.value));
        } else {
            dispatch(getPartnerList(1, size, val, country?.value, status?.value));
        }
    }

    const searchPartner = (val) => {
        clearTimeout(timerRef1.current);
        setSearch(val);
        setPageCount(1);
        timerRef1.current = setTimeout(() => delaySearch1(val), 800);
    };
    const delaySearch1 = async (val) => {
        dispatch(getPartnerUsers(1, size, val, role?.value, status?.value, details?._id || partnerId));
    }

    const handleChange = (id, checked) => {
        dispatch(handleStatusChange(id, !checked, activeTab));
    }

    const userAction = async (detail, edit) => {
        ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? setIsPartnerView(false) : setIsPartnerView(false)
        setIsEdit(edit);
        if (detail === null) {
            setIsAdd(true);
            setUserDetails({
                firstname: '',
                lastname: '',
                email: '',
                mobile: "",
                dial_code: "+1",
                role: ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? 'manager' : ''
            })
        } else {
            setIsAdd(true);
            const { fname, lname, dial_code, roles, email, mobile, _id } = detail
            setUserDetails({
                _id: _id,
                role: roles.map(el => el._id)[0],
                firstname: fname,
                lastname: lname,
                email: email,
                mobile: mobile,
                dial_code: dial_code
            })
        }

    }


    const handleSubmit = async (values) => {
        let obj = {
            fname: values.firstname,
            lname: values.lastname,
            email: values.email,
            role_id: values.role,
            mobile: values?.mobile,
            dial_code: values?.dial_code,
            _id: userDetails?._id
        }

        if (!isEdit) {
            const res = await dispatch(addUser(obj));
            if (res?.type === 'ADD_USER') {
                await setIsAdd(false);
                // await dispatch(getSequertekUsers(page, size, '', '', ''));
            }
        } else {
            const res = await dispatch(updateUser(obj));
            if (res?.type === 'EDIT_USER') {
                await setIsAdd(false);
                ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ?
                    await dispatch(getPartnerUsers(page, size, '', role?.value, status?.value, partnerId))
                    : await dispatch(getSequertekUsers(pageCount, size, '', '', ''));
            }
        }
        ((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ? setIsPartnerView(true) : setIsPartnerView(false)
    };

    const userPartnerAction = async (detail, edit) => {
        setIsEdit(edit);
        if (detail === null) {
            setIsAdd(true);
            setPartnerDetails({
                firstname: '',
                lastname: '',
                email: '',
                mobile: "",
                dial_code: "+1",
                country: '',
                joined: '',
                city: '',
                pincode: '',
                company: ''
            })
        } else {
            setIsAdd(true);
            const { fname, lname, email, mobile, dial_code, country_id, city, joined, pincode, company, _id } = detail
            setPartnerDetails({
                _id: _id,
                firstname: fname,
                lastname: lname,
                email: email,
                mobile: mobile,
                dial_code: dial_code,
                country: country_id,
                city: city,
                pincode: pincode,
                joined: joined.split('T')[0],
                company: company
            })
        }

    }

    const handlePartnerSubmit = async (values) => {
        let obj = {
            fname: values.firstname,
            lname: values.lastname,
            email: values.email,
            country_id: values.country,
            mobile: values?.mobile,
            dial_code: values?.dial_code,
            city: values?.city,
            pincode: values?.pincode,
            joined: new Date(values.joined),
            company: values?.company,
            _id: partnerDetails?._id
        }

        if (!isEdit) {
            const res = await dispatch(addPartner(obj));
            if (res?.type === 'ADD_PARTNER') {
                await setIsAdd(false);
                // await dispatch(getPartnerList(page, size, '', '', ''));
            }
        } else {
            const res = await dispatch(updatePartner(obj));
            if (res?.type === 'EDIT_PARTNER') {
                await setIsAdd(false);
                await dispatch(getPartnerList(page, size, '', '', ''));
            }
        }
    };

    // const deleteUser = (id) => {
    //     setDeleteId(id);
    //     setIsConfirm(true);
    // }



    const removeConfirm = async () => {
        const res = await dispatch(deletedUser(deleteId, activeTab));
        if (res?.type === 'DELETE_USER') {
            setIsConfirm(false);
            setDeleteId('');
        }
    }

    const confirmUser = (id) => {
        setDeleteId(id);
        setIsResend(true);
    }

    const resendConfirm = async () => {
        await setLoading(true);
        const res = await dispatch(resendInvite(deleteId));
        if (res?.type === 'RESEND_INVITES') {
            setIsResend(false);
            setDeleteId('');
        }
        await setLoading(false);
    }


    const openPartnerView = async (el) => {
        setIsPartnerView(true);
        setDetails(el);
        await dispatch(resetLists());
        await dispatch(getPartnerUsers(1, size, '', role?.value, status?.value, el._id));
    }

    const exportUserData = async () => {
        await setLoading(true);
        const res = await dispatch(getUserExportData());
        if (res?.type === 'USER_EXPORT_DATA') {
            const data = res?.payload?.map(
                (el) => [
                    {
                        'First Name': el.fname,
                        'Last Name': el.lname,
                        'Email': el.email,
                        'Mobile no.': el.mobile,
                        "Role": el.roles?.map(el => el.title).join(', '),
                        'Status': el.invited ? 'Invited' : el.active ? 'Active' : 'Inactive',
                    },
                ][0]
            );
            const fileName = "users";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
        await setLoading(false);
    }

    const exportPartnerData = async () => {
        await setLoading(true);
        const res = await dispatch(getPartnerExportData());
        if (res?.type === 'PARTNER_EXPORT_DATA') {
            const data = res?.payload?.map(
                (el) => [
                    {
                        'First Name': el.fname,
                        'Last Name': el.lname,
                        'Email': el.email,
                        'Mobile no.': el.mobile,
                        "Role": el.roles?.map(el => el).join(', '),
                        "Joined": convertDate(el.joined),
                        "Users": el.users,
                        'Status': el.invited ? 'Invited' : el.active ? 'Active' : 'Inactive',
                        country: el.country?.name,
                        city: el.city,
                        pincode: el.pincode
                    },
                ][0]
            );
            const fileName = "partners";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
        await setLoading(false);
    }

    const getPartnerUserData = async (id) => {
        await setLoading(true);
        const res = await dispatch(getPartnerUserExportData(id));
        if (res?.type === 'PARTNER_USER_EXPORT_DATA') {
            const data = res?.payload?.map(
                (el) => [
                    {
                        'First Name': el.fname,
                        'Last Name': el.lname,
                        'Email': el.email,
                        'Mobile no.': el.mobile,
                        "Role": el.roles?.map(el => el.title).join(', '),
                        'Status': el.invited ? 'Invited' : el.active ? 'Active' : 'Inactive',
                    },
                ][0]
            );
            const fileName = "partner-users";
            const exportType = await exportFromJSON.types.csv;
            await exportFromJSON({ data, fileName, exportType });
        }
        await setLoading(false);
    }

    const goBack = () => {
        if (((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager'))) {
            setIsAdd(false);
            setIsPartnerView(true)
        } else {
            setIsAdd(false)
        }
    }

    const openView = (el) => {
        setIsView(true);
        setUserDetails(el);
    }

    const customStyle = {
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                textTransform: 'capitalize'
            }
        },
    }

    return (
        <div className='leads-holder py-2 py-md-2 me-3'>
            {/* USER  */}
            {
                !isPartnerView ?
                    (!isAdd ?
                        <Fragment>
                            <div>
                                <div className='users-tab'>
                                    <div>
                                        <button className={activeTab === 'sequretek' ? "active-btn" : null} onClick={() => onBtnChange('sequretek')}>Sequretek</button>&nbsp;
                                        <button className={activeTab === 'partner' ? "active-btn" : null} onClick={() => onBtnChange('partner')}>Partner</button>&nbsp;
                                    </div>
                                    <div>
                                        <button disabled={loading} onClick={() => activeTab === 'partner' ? exportPartnerData() : exportUserData()}>Export </button>&nbsp;&nbsp;
                                        <button style={{ background: "#2076cd", color: "#FFF", borderRadius: 8 }} onClick={() => activeTab === 'partner' ? userPartnerAction(null, false) : userAction(null, false)}>{activeTab === 'partner' ? 'Add Partner' : 'Add user'}</button>
                                    </div>
                                </div>

                            </div>
                            {activeTab === 'sequretek' ?
                                <div className='users-table-holder'>
                                    <div className='users-header'>
                                        <div className='user-input'>
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/search-icon.svg" alt='' width={15} />
                                                </span>
                                                <input type='search' placeholder='Search By Name' className='form-control' value={query} onChange={(e) => searchQuery(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='select-group'>
                                            <div>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={roles}
                                                    isClearable
                                                    onChange={(e) => selectRole(e, 'sequretek')}
                                                    placeholder='By Role'
                                                    styles={customStyle}
                                                    value={role}
                                                />
                                            </div>
                                            <div className='ms-3'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={statusList}
                                                    isClearable
                                                    onChange={(e) => selectStatus(e, 'sequretek')}
                                                    placeholder='By Status'
                                                    value={status}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='users-table'>
                                        <Table responsive >
                                            <thead>
                                                <tr className='table-header'>
                                                    {/* <th className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></th> */}
                                                    <th>USER NAME</th>
                                                    <th className='text-center'>EMAIL  </th>
                                                    <th className='text-center'>ROLE</th>
                                                    <th className='text-center'>STATUS</th>
                                                    <th className='text-center'>ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ?
                                                    <tr>
                                                        <td colSpan={7}> <TopicLoader /> </td>
                                                    </tr>
                                                    :
                                                    sequertekUsers?.length > 0 ?
                                                        sequertekUsers?.map((el) => {
                                                            return (
                                                                <tr key={el._id}>
                                                                    {/* <td className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></td> */}
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            {/* <div className='profile-img'>
                                                                                <img src='/images/avatar.svg' alt='' className='rounded-circle' />
                                                                            </div>&nbsp;&nbsp;&nbsp; */}
                                                                            {el?.fname} {el?.lname}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>{el?.email}</td>
                                                                    <td>
                                                                        <div className='d-flex justify-content-center align-items-center'>
                                                                            <div className='product'>
                                                                                {el?.roles?.map(el => el.title).join(', ')}
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className=' text-center' >
                                                                            {(el?.invited && !el.active) ?
                                                                                <p>Invited
                                                                                    <br />
                                                                                    <span className='cursor-pointer' onClick={() => confirmUser(el._id)} style={{ fontSize: 10 }}>Resend Invite</span>
                                                                                </p> :
                                                                                <p onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    handleChange(el._id, el.active)
                                                                                }}> <Switch
                                                                                        onChange={(e) => { console.log(e) }}
                                                                                        width={30}
                                                                                        height={16}
                                                                                        uncheckedIcon={false}
                                                                                        checkedIcon={false}
                                                                                        onColor='#00B24B'
                                                                                        checked={el.active ? true : false} /><br />
                                                                                    {(el?.invited && !el.active) ? 'Invited' : el?.active ? 'Active' : 'Inactive'}
                                                                                </p>}
                                                                        </div>
                                                                    </td>
                                                                    <td className='cursor-pointer text-center'>
                                                                        <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openView(el, 'sequretek')} />&nbsp;&nbsp;
                                                                        <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={() => userAction(el, true, activeTab)} />&nbsp;&nbsp;
                                                                        {/* <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => deleteUser(el._id)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <img src='/images/vertical-ham.svg' alt='' width={20} height={20} /> */}

                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className='empty-list'>
                                                                    <img src='/images/empty.png' alt='' />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        {totalPage > 1 ? <div className='d-flex justify-content-end me-3'>
                                            <Paginations className={'custom-pagination'} totalPages={totalPage} onPageChange={handlePageChange} />
                                        </div> :
                                            null
                                        }
                                    </div>
                                </div> :
                                <div className='users-table-holder'>
                                    {/* USER TABLE  */}
                                    <div className='users-header'>
                                        <div className='user-input'>
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/search-icon.svg" alt='' width={15} />
                                                </span>
                                                <input type='search' placeholder='Search By Name' className='form-control' value={query} onChange={(e) => searchQuery(e.target.value)} />
                                            </div>

                                        </div>
                                        <div className='select-group' style={{ width: '35%' }}>
                                            <div>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={countries}
                                                    isClearable
                                                    onChange={selectCountry}
                                                    placeholder='By Country'
                                                    value={country}
                                                />
                                            </div>
                                            <div className='ms-3'>
                                                <Select
                                                    components={{ DropdownIndicator }}
                                                    options={statusList}
                                                    isClearable
                                                    onChange={(e) => selectStatus(e, 'partner')}
                                                    placeholder='By Status'
                                                    value={status}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='users-table'>
                                        <Table responsive >
                                            <thead>
                                                <tr className='table-header'>
                                                    {/* <th className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></th> */}
                                                    <th >PARTNER NAME</th>
                                                    <th className='text-center'>COUNTRY</th>
                                                    <th className='text-center'>EMAIL  </th>
                                                    <th className='text-center'>STATUS</th>
                                                    <th className='text-center'>NO OF USERS</th>
                                                    <th className='text-center'>ACTION</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ?
                                                    <tr>
                                                        <td colSpan={7}> <TopicLoader /> </td>
                                                    </tr>
                                                    :
                                                    adminPartners?.length > 0 ?
                                                        adminPartners?.map((el, i) => {
                                                            return (
                                                                <tr>
                                                                    {/* <td className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></td> */}
                                                                    <td>
                                                                        <div className='d-flex align-items-center'>
                                                                            {/* <div className='profile-img'>
                                                                                <img src='/images/avatar.svg' alt='' className='rounded-circle' />
                                                                            </div>&nbsp;&nbsp;&nbsp; */}
                                                                            {el?.fname} {el?.lname}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>{el.country?.name}</td>
                                                                    <td className='text-center'>{el.email}</td>
                                                                    <td className='text-center'>
                                                                        <div className=' text-center' >
                                                                            {(el.invited && !el.active) ?
                                                                                <p>Invited</p>
                                                                                :
                                                                                <p onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    e.stopPropagation();
                                                                                    handleChange(el._id, el.active)
                                                                                }}> <Switch
                                                                                        onChange={(e) => { console.log(e) }}
                                                                                        width={30}
                                                                                        height={16}
                                                                                        uncheckedIcon={false}
                                                                                        checkedIcon={false}
                                                                                        onColor='#00B24B'
                                                                                        checked={el.active ? true : false} /><br />
                                                                                    {el.active ? 'Active' : 'Inactive'}
                                                                                </p>}
                                                                        </div>
                                                                    </td>
                                                                    <td className='text-center'>{el.users}</td>
                                                                    <td className='cursor-pointer text-center'>
                                                                        <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openPartnerView(el)} />&nbsp;&nbsp;
                                                                        <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={() => userPartnerAction(el, true)} />&nbsp;&nbsp;
                                                                        {/* <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => deleteUser(el._id)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <img src='/images/vertical-ham.svg' alt='' width={20} height={20} /> */}

                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) :
                                                        <tr>
                                                            <td colSpan={7}>
                                                                <div className='empty-list'>
                                                                    <img src='/images/empty.png' alt='' />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                }
                                            </tbody>
                                        </Table>
                                        {totalPage > 1 ?
                                            <div className='d-flex justify-content-end me-3'>
                                                <Paginations className={'custom-pagination'} totalPages={totalPage} onPageChange={handlePageChange} />
                                            </div> :
                                            null
                                        }
                                    </div>
                                </div>

                            }
                        </Fragment> :
                        <Fragment>

                            <AddUser
                                setIsAdd={() => goBack()}
                                userDetails={userDetails}
                                isEdit={isEdit}
                                handleSubmit={handleSubmit}
                                activeTab={activeTab}
                                partnerDetails={partnerDetails}
                                handlePartnerSubmit={handlePartnerSubmit}
                                countries={countries}
                                roles={roles}
                                partnerRoles={partnerRoles}
                            />
                        </Fragment>
                    ) :
                    <Fragment>
                        {(localStorage.getItem('role') === 'partner-admin' || localStorage.getItem('role') === 'manager') ?
                            <h2 className="d-flex align-items-center justify-content-between">
                                Users
                                <div className='leads-btn'>
                                    <button style={{ visibility: partnerUsers?.length ? 'visible' : 'hidden' }} disabled={loading} onClick={() => getPartnerUserData(details?._id || partnerId)} >Export </button>
                                    <button onClick={() => userAction(null, false)}>Add user&nbsp;&nbsp; <img src='/images/plus-white.svg' alt='' /> </button>
                                </div>
                            </h2>
                            :
                            <Fragment>
                                <div className='partner-header'>
                                    <h2 className="d-flex justify-content-between align-items-center">
                                        <div><img src='/images/back.svg' alt='' onClick={() => setIsPartnerView(false)} className='cursor-pointer' /> &nbsp;&nbsp;&nbsp;{'Partner Details'}</div>
                                        {partnerUsers?.length ?
                                            <div className='leads-btn'>
                                                <button disabled={loading} onClick={() => getPartnerUserData(details?._id || partnerId)}>Export </button>
                                            </div> :
                                            null
                                        }

                                    </h2>
                                </div>
                                <div className='partner-details'>
                                    <div className='row me-0'>
                                        <div className='col-md-3 mt-2'>
                                            <h5>Partner Name</h5>
                                            <p>{details?.fname} {details?.lname}</p>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                            <h5>Email</h5>
                                            <p>{details?.email}</p>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                            <h5>Phone Number</h5>
                                            <p>{details?.mobile}</p>
                                        </div>
                                        <div className='col-md-3 mt-2'>
                                            <h5>Date Joined</h5>
                                            <p>{convertDate(details?.joined)}</p>
                                        </div>
                                        <div className='col-md-3 mtop '>
                                            <h5>Country</h5>
                                            <p>{details?.country?.name}</p>
                                        </div>
                                        <div className='col-md-3 mtop'>
                                            <h5>City</h5>
                                            <p>{details?.city}</p>
                                        </div>
                                        <div className='col-md-3 mtop'>
                                            <h5>Pincode</h5>
                                            <p>{details?.pincode}</p>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        }

                        <div className='users-table-holder mt-3'>

                            <div className='users-header'>
                                <div className='user-input'>
                                    <div className="has-search"  >
                                        <span className="form-control-feedback">
                                            <img src="/images/search-icon.svg" alt='' width={15} />
                                        </span>
                                        <input type='search' placeholder='Search By Name' className='form-control' value={search} onChange={(e) => searchPartner(e.target.value)} />
                                    </div>

                                </div>
                                <div className='select-group'>
                                    <div>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={partnerRoles}
                                            isClearable
                                            onChange={(e) => selectRole(e, 'partnerUser')}
                                            placeholder='By Role'
                                            styles={customStyle}
                                            value={role}
                                        />
                                    </div>
                                    <div className='ms-3'>
                                        <Select
                                            components={{ DropdownIndicator }}
                                            options={statusList}
                                            isClearable
                                            onChange={(e) => selectStatus(e, 'partnerUser')}
                                            placeholder='By Status'
                                            value={status}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className='users-table'>
                                <Table responsive >
                                    <thead>
                                        <tr className='table-header'>
                                            {/* <th className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></th> */}
                                            <th>USER NAME</th>
                                            <th>EMAIL  </th>
                                            <th className='text-center'>ROLE</th>
                                            <th className='text-center'>STATUS</th>
                                            {(localStorage.getItem('role') === 'partner-admin' || localStorage.getItem('role') === 'manager') ? <th className='text-center'>ACTION</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ?
                                            <tr>
                                                <td colSpan={5}>
                                                    <TopicLoader />
                                                </td>
                                            </tr>
                                            :
                                            partnerUsers?.length > 0
                                                ? partnerUsers?.map((el) => {
                                                    return (
                                                        <tr key={el._id}>
                                                            {/* <td className='text-center'><input type='checkbox' style={{ width: 18, height: 18 }} /></td> */}
                                                            <td>
                                                                <div className='d-flex align-items-center'>
                                                                    {/* <div className='profile-img'>
                                                                        <img src='/images/avatar.svg' alt='' className='rounded-circle' />
                                                                    </div>&nbsp;&nbsp;&nbsp; */}
                                                                    {el?.fname} {el?.lname}
                                                                </div>
                                                            </td>
                                                            <td>{el?.email}</td>
                                                            <td>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <div className='product'>
                                                                        {el?.roles?.map(el => el.title).join(', ')}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className=' text-center' >
                                                                    {(el?.invited && !el.active) ?
                                                                        <p>Invited
                                                                            <br />
                                                                            <span className='cursor-pointer' onClick={() => confirmUser(el._id)} style={{ fontSize: 10 }}>Resend Invite</span>
                                                                        </p> :
                                                                        (localStorage.getItem('loginEmail') === el.email ?
                                                                            <p>{(el?.invited && !el.active) ? 'Invited' : el?.active ? 'Active' : 'Inactive'}</p>
                                                                            : <p onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                handleChange(el._id, el.active)
                                                                            }}> <Switch
                                                                                    onChange={(e) => { console.log(e) }}
                                                                                    width={30}
                                                                                    height={16}
                                                                                    uncheckedIcon={false}
                                                                                    checkedIcon={false}
                                                                                    onColor='#00B24B'
                                                                                    checked={el.active ? true : false} /><br />
                                                                                {(el?.invited && !el.active) ? 'Invited' : el?.active ? 'Active' : 'Inactive'}
                                                                            </p>)
                                                                    }
                                                                </div>
                                                            </td>
                                                            {((localStorage.getItem('role') === 'partner-admin') || (localStorage.getItem('role') === 'manager')) ?
                                                                <td className='cursor-pointer text-center'>
                                                                    <img src='/images/view-icon.svg' alt='' width={20} height={20} onClick={() => openView(el)} />&nbsp;&nbsp;
                                                                    <img src='/images/edit-icon.svg' alt='' width={20} height={20} onClick={() => userAction(el, true)} />&nbsp;&nbsp;
                                                                    {/* <img src='/images/delete-icon.svg' alt='' width={20} height={20} onClick={() => deleteUser(el._id)} />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    <img src='/images/vertical-ham.svg' alt='' width={20} height={20} /> */}
                                                                </td> :
                                                                null
                                                            }
                                                        </tr>
                                                    )
                                                }) :
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='empty-list'>
                                                            <img src='/images/empty.png' alt='' />
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </Table>
                                {total > 1 ?
                                    <div className='d-flex justify-content-end me-3'>
                                        <Paginations className={'custom-pagination'} totalPages={total} onPageChange={handlePage} />
                                    </div> :
                                    null
                                }
                            </div>
                        </div>
                    </Fragment >
            }

            {/* ################################## Confirmation Modal ################################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                setIsConfirm={() => setIsConfirm(false)}
                type='user'
                removeConfirm={removeConfirm}
            />

            {/* Confirm User resend invites */}
            <Modal
                show={isResend}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"confirm-modal"}
            >
                <div className="p-4" >
                    <h1>
                        Are you sure you want to resend invite?
                    </h1>
                    <div className="save-btn d-flex align-items-center">
                        <button onClick={resendConfirm}>{loading ? <Spinner animation="border" variant="light" /> : 'Yes'}</button>&nbsp;&nbsp;
                        <button onClick={() => setIsResend(false)}>No</button>
                    </div>
                </div>
            </Modal>

            {/* View User Details */}
            <Modal
                show={isView}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // onHide={this.handleCloseModal}
                dialogClassName={"view-modal"}
            >
                <div className='sticky-close'>
                    <img src='/images/close.svg' alt='' onClick={() => setIsView(false)} />
                </div>
                {loading ?
                    'Loading...'
                    :
                    (userDetails &&
                        <div className='view-details'>
                            <h3>View Details</h3>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>First Name</h5>
                                        <p>{userDetails?.fname}</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>Last Name</h5>
                                        <p>{userDetails?.lname}</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>Email</h5>
                                        <p>{userDetails?.email}</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>Mobile No</h5>
                                        <p>{userDetails?.mobile}</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>Role</h5>
                                        <p className='text-capitalize'>{userDetails?.roles?.map(el => el.title).join(', ')}</p>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='view-detail'>
                                        <h5>Status</h5>
                                        <p className='text-capitalize'>{userDetails?.invited ? 'Invited' : userDetails?.active ? 'Active' : 'Inactive'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Modal>
        </div >
    )
}
