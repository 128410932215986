// ############################ Require packages here ############################
import React, { lazy, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Aos from 'aos';
import ReactPlayer from 'react-player';

// ############################ Require files here ###############################

import './product.scss';
import ScheduleForm from '../scheduleForm';
import RelatedResources from '../relatedResources';
const TestimonialSlider = lazy(() => import("../testimonialSlider"));


const PerceptPlatformBenefits = [
    { image: '/images/overview.svg', title: 'Real-Time Security Visibility', description: 'Instant insights into regulatory compliance, enterprise risk posture, risk vis-à-vis vulnerable systems, and cloud security posture.' },
    { image: '/images/api.svg', title: 'Vulnerability & Configuration Management', description: 'Provides a continuous evaluation of attack surface exposure, including vulnerabilities, misconfigurations and security gaps.' },
    { image: '/images/workflow-setting.svg', title: 'Effective Resource Allocation', description: ' Prioritization of security efforts and better resource management with data queuing based on external feeds and internal scans.' },
    { image: '/images/dashboard-monitor.svg', title: 'Dashboards, Reporting, Analytics', description: ' AI-based analytics and regulatory compliance reports and dashboards offer quick and real-time view for effective risk management.' },
    { image: '/images/circle-exclamation-check.svg', title: 'Timely Threat Response', description: 'Criticality-based automated remediation with built-in workflows, playbooks, APIs, and security actions mitigates risks continuously.' },
    { image: '/images/shield-trust.svg', title: 'Compliance Variance', description: 'Policy, registry, application, configuration, and patch scanning discover vulnerabilities and establish compliance variance.' },
    { image: '/images/sensor-alert.svg', title: 'Real-Time Assessments', description: 'Periodic feeds of access credentials, vulnerabilities, patches, alerts, guidelines, policies, and registries aid precise assessment.' },

]

const Graphitems = [
    { image: '/images/shieldplus.svg', title: 'Asset Discovery & Mapping' },
    { image: '/images/shieldplus.svg', title: 'Continuous GRC Monitoring' },
    { image: '/images/shieldplus.svg', title: 'Continuous Vulnerability Scanning & Management' },
    { image: '/images/shieldplus.svg', title: 'Continuous Configuration Management' },
    { image: '/images/shieldplus.svg', title: 'Threat Intelligence Integrations' },
    { image: '/images/shieldplus.svg', title: 'Dashboard & Reporting' },
    { image: '/images/shieldplus.svg', title: 'Cloud-Native Compliance & Exposure Management' },
    { image: '/images/shieldplus.svg', title: 'Continuous Application Security' },
    { image: '/images/shieldplus.svg', title: 'Upload & Download Data' },
    { image: '/images/shieldplus.svg', title: 'Analyze, Scan and Update Configurations and Policies' },
    { image: '/images/shieldplus.svg', title: 'Vulnerability Finder' },
    { image: '/images/shieldplus.svg', title: 'Risk Scoring' },
    { image: '/images/shieldplus.svg', title: ' Remediation Mapping' },
    { image: '/images/shieldplus.svg', title: 'Data Queuing' },
    { image: '/images/shieldplus.svg', title: 'Patching Recommendations' },



]

const PerceptManager = () => {
    const active = 'perceptManager';
    const [secNav, setSecNav] = useState(false);


    useEffect(() => {
        window.addEventListener('scroll', scrollSecondNav);
        return () => {
            window.removeEventListener('scroll', scrollSecondNav);
        }
    }, [])

    useEffect(() => {
        Aos.init({ duration: 1000 })
    }, [])

    const scrollSecondNav = () => {
        const secondaryHeader = document.querySelector('.secondary-header');
        const mainHeaderHeight = 72;
        const contentTop = document.querySelector('.seq-Percept-about')?.offsetTop;

        if (window.scrollY > contentTop - mainHeaderHeight) {
            secondaryHeader.classList.add('sticky');
        } else {
            secondaryHeader.classList.remove('sticky');
        }
    }


    const toggleNav = () => {
        setSecNav(!secNav)
    }


    const navigate = useNavigate();

    const setActive = (val) => {
        window.scrollTo(0, 0);
        if (val === 'perceptXdr') {
            navigate('/products/extended-detection-and-response-and-ngsiem')
        } else if (val === 'PerceptIdentity') {
            navigate('/products/identity-and-access-governance')
        } else if (val === 'perceptEdr') {
            navigate('/products/endpoint-detection-and-response')
        } else if (val === 'perceptplatform') {
            navigate('/sequretek-percept-platform')
        } else if (val === 'products') {
            window.innerWidth > 767 ? window.scrollTo(0, 4800) : window.scrollTo(0, 4100);
        } else if (val === 'datasheet') {
            navigate('/resources-hub', { state: { type: 'datasheet' } })
        } else if (val === 'resources') {
            navigate('/resources-hub')
        }
    }
    return (
        <div className='seq-Percept-page'>
            {/* section 0 PAGE HEADING */}
            <Helmet>
                <title>Sequretek | Cloud-native Compliance and Exposure Management </title>
                <meta http-equiv="X-Frame-Options" content="DENY" />
                <meta name="description" content="Continuously monitor GRC, scan vulnerabilities across environments, and mitigate risks with Percept Compliance Manager. Ensure compliance and strengthen IT security." />
                <meta name="keywords" content="Compliance Management Software, Vulnerability management, *Audit and compliance" />
                <meta property="og:title" content="Sequretek | Cloud-native Compliance and Exposure Management" />
                <meta property="og:description" content="Continuously monitor GRC, scan vulnerabilities across environments, and mitigate risks with Percept Compliance Manager. Ensure compliance and strengthen IT security." />
            </Helmet>
            {/* section 1 BANNER */}
            <div className='seq-Percept-heading' style={{ backgroundImage: `linear-gradient(41.44deg, rgba(4, 12, 48, 0.85) 37.24%, rgba(6, 6, 93, 0.85) 63.08%),url('/images/bg-img24.png')` }}>
                <div className='container pe-0 ps-0'>
                    <div className='seq-Percept-hero d-flex justify-content-center'>
                        <div className='seq-Percept-hero-identity' data-aos="fade-up">
                            <h3 className='pb-0 seq-Percept-hero-identity-head d-flex justify-content-center'>Prioritize Your Security Efforts</h3>
                            <p className='seq-Percept-hero-identity-text'>Cloud-native AI-powered analyzer with proactive, continuous compliance management for enterprise risk posture visibility</p>
                            {window.innerWidth > 767 ?
                                <div className='d-flex  justify-content-center'>
                                    <button onClick={() => setActive('products')}><strong>Get Compliance Manager</strong></button>
                                    {/* <button className='seq-Percept-hero-identity-btn' onClick={() => setActive('datasheet')}><strong>Download Datasheet</strong></button> */}
                                </div> :
                                <div>
                                    <button onClick={() => setActive('products')}><strong>Get Compliance Manager</strong></button>
                                    {/* <button className='seq-Percept-hero-identity-btn' onClick={() => setActive('datasheet')}><strong>Download Datasheet</strong></button> */}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='seq-Percept-tab-pages'>
                {/* section 2 SECOND NAVBAR*/}
                {window.innerWidth > 767 ?
                    <div>
                        <div className='secondary-header contact-tabs d-flex justify-content-center align-items-center'>
                            <div onClick={() => setActive('perceptplatform')} className={active === 'perceptplatform' ? 'seq-tab active' : 'seq-tab'}>
                                About Percept Platform
                            </div>
                            <div onClick={() => setActive('perceptXdr')} className={active === 'perceptXdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept XDR & NG SIEM
                            </div>
                            <div onClick={() => setActive('PerceptIdentity')} className={active === 'PerceptIdentity' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Identity
                            </div>
                            <div onClick={() => setActive('perceptEdr')} className={active === 'perceptEdr' ? 'seq-tab active' : 'seq-tab'}>
                                Percept EDR
                            </div>
                            <div onClick={() => setActive('perceptManager')} className={active === 'perceptManager' ? 'seq-tab active' : 'seq-tab'}>
                                Percept Compliance Manager
                            </div>
                        </div>
                    </div>
                    :
                    <div className='secondary-header'>
                        <div className='seq-sec-nav-mobile d-flex justify-content-evenly'>
                            <div className="seq-active d-flex align-items-center">
                                <h4>Percept Compliance Manager</h4>
                            </div>
                            <div className="dropdown d-flex" onClick={toggleNav} >
                                <img src={secNav === true ? '/images/up-arrow.svg' : '/images/down-arrow.svg'} alt="Menu" loading="lazy" />
                            </div>
                        </div>
                        <nav className={`menu ${secNav ? 'open' : ''}`}>
                            <ul className='seq-drop-items'>
                                <li onClick={() => setActive('perceptXdr')}>Percept XDR and NGSIEM</li>
                                <li onClick={() => setActive('PerceptIdentity')}>Percept Identity</li>
                                <li onClick={() => setActive('perceptEdr')}>Percept EDR</li>
                                <li onClick={() => setActive('perceptplatform')}>About Percept Platform</li>
                            </ul>
                        </nav>
                    </div>
                }
                {/* section 3 PLAYER */}
                <div className='seq-Percept-about'>
                    {/* PLAYER */}
                    <div className='container pe-0 ps-0' data-aos="fade-up">
                        <div className='d-flex justify-content-center seq-Percept-player' >
                            <ReactPlayer url='https://youtu.be/LyabtMRy4EQ ' className='seq-Percept-about-player' width='1128px' height='595px' />
                        </div>
                    </div>
                    {/* Img Session */}
                    <div className='seq-benifits-img'>
                        <div className='container pe-0 ps-0'>
                            <div className='d-flex justify-content-center'><h4>PERCEPT COMPLIANCE MANAGER</h4></div>
                            {window.innerWidth > 767 ? <object type="image/svg+xml" data-aos="fade-up" className='seq-percept-main-img' aria-label='image' data="/images/bg-img79.svg"></object> :
                                <div data-aos="fade-up">
                                    {/* accordion one */}
                                    <div className='seq-identity-accordionone'>
                                        <div class="accordion" id="accordionExample">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>End-to-end Security Visibility</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Compliance with industry regulations</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Enterprise risk posture</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Risk vis-à-vis vulnerable systems</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Cloud security posture</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Vulnerability & Configuration Management</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Environment vulnerabilities</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Misconfigurations</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Configuration gaps</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Attack surface exposure</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Application security gaps</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Dashboards, Reports, Analytics</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Compliance dashboards</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>AI-based analytics</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Any-to-any reporting</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Continuous threat exposure management (with XDR integration)</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Regulatory compliance reports</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-blue.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Automated Remediation</div>
                                                    </button>
                                                </h2>
                                                <div id="collapsefour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Built-in workflows for remediation</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Automated remediation (SOAR)</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Patching based on criticality</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Remediation playbooks</p>
                                                        </div>
                                                        <div className='d-flex'>
                                                            <div className='d-flex align-items-center'><img src='/images/shieldplus.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;
                                                            <p>Cloud API</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'><h5>What you get</h5></div>
                                    {/* GIF */}
                                    <div className='seq-identity-gif d-flex'>
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                    </div>
                                    <div className='seq-identity-accordionthree'>
                                        <div class="accordion" id="accordionExamplethree">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header">
                                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className='d-flex align-items-center'><img src='/images/angle-circle-down-wight.svg' alt='' loading="lazy" /></div>&nbsp;&nbsp;&nbsp;&nbsp;<div>Cloud-native AI-based Percept Compliance Manager</div>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExamplethree">
                                                    <div class="accordion-body">
                                                        <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '15px' }}>
                                                            <div className='d-flex'>
                                                                <p>Continuous GRC monitoring</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Continuous vulnerability management</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Policy-based assurance </p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Static and dynamic scans </p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Continuous response</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Automated remediation</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Continuous application security</p>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <p>Continuous configuration management</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='seq-identity-gif-rotate d-flex'>
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                        <img src='/images/Uparrow-img.gif' alt='' loading="lazy" />
                                    </div>
                                    {/* accordion two */}
                                    <div className='d-flex justify-content-center'><h5>What we scan</h5></div>
                                    <div className='seq-manager-scan'>
                                        <div className='d-flex flex-nowrap overflow-auto' style={{ gap: '20px' }}>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/network.svg' alt='' loading="lazy" /></div>
                                                <p>Endpoints</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/database.svg' alt='' loading="lazy" /></div>
                                                <p>Enterprise apps, webservers, databases</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/system-cloud.svg' alt='' loading="lazy" /></div>
                                                <p>Cloud directories</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/handshake-trust.svg' alt='' loading="lazy" /></div>
                                                <p>Cloud security brokers (CASB)</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/block-brick-fire.svg' alt='' loading="lazy" /></div>
                                                <p>Firewalls</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/function-process.svg' alt='' loading="lazy" /></div>
                                                <p>Operational technology</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/network-cloud.svg' alt='' loading="lazy" /></div>
                                                <p>Cloud point products</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/network-analytic.svg' alt='' loading="lazy" /></div>
                                                <p>Network & perimeter</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/cloud-gear-automation.svg' alt='' loading="lazy" /></div>
                                                <p>Public cloud environments</p>
                                            </div>
                                            <div className='seq-manager-scan-items'>
                                                <div className='d-flex justify-content-center'><img src='/images/sensor-smoke.svg' alt='' loading="lazy" /></div>
                                                <p>Cloud SaaS</p>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            }
                        </div>
                    </div>
                    {/* section 3-2 BENEFITS */}
                    <div className='seq-Percept-benefits'>
                        <div className='container pe-0 ps-0'>
                            <p style={{ color: '#2076CD', fontSize: '16px' }}>BENEFITS</p>
                            <div className='row' style={{ marginBottom: '18px', width: '100%' }}>
                                <div className='col-12 col-md-6 col-sm-12'>
                                    <h3>Bi-Annual Approaches Aren’t Enough. Our Continuous Compliance Management Gets it Done</h3>
                                </div>
                                <div className='col-12 col-md-6 col-sm-12'>
                                    <p>The need of the hour is continuous GRC monitoring, vulnerability management, application security, and configuration management to deliver cloud-native compliance and mitigate risk exposure. Percept Compliance Manager with its AI-powered technology gets it done continuously at scale across complex, hybrid IT environments.</p>
                                </div>
                            </div>
                            <div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row me-0 seq-bentfits-row-card seq-benefits-cards'>
                                            {
                                                PerceptPlatformBenefits?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col  pe-0 d-flex justify-content-center align-self-stretch seq-Perceptmanager-card'>
                                                            <div className='seq-card'>
                                                                <div className='d-flex justify-content-start align-items-center'>
                                                                    <img src={el.image} alt='' loading="lazy" />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                                <p >{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap'>
                                            {
                                                PerceptPlatformBenefits?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='col-md-3' style={{ gap: '24px', paddingTop: '35px' }}>
                                                            <div className='seq-card'>
                                                                <img src={el.image} alt='' style={{ width: '48px', height: '48px', marginBottom: '12px' }} loading="lazy" />
                                                                <h4>{el.title}</h4>
                                                                <p>{el.description}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                        {/* section 3-3 FEATURES */}
                        <div className='container pe-0 ps-0'>
                            <div className='seq-Percept-graph '>
                                <p className='d-flex justify-content-center align-items-center' data-aos="fade-up">FEATURES</p>
                                <h3 className='d-flex justify-content-center align-items-center' data-aos="fade-up">Detect Threats Efficiently and Respond Rapidly With Percept Compliance Manager</h3>
                                <img className='seq-Percept-graph-img' src='/images/percept-manager-graph.png' data-aos="fade-up" alt='' loading="lazy" />
                                <div>
                                </div>
                                {
                                    window.innerWidth > 767 ?
                                        <div className='row d-flex justify-content-center align-items-center'>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='seq-Percept-graph-extra col-md-2 ' style={{ height: '175px' }} >
                                                            <div>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' loading="lazy" />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        :
                                        <div className='d-flex overflow-auto flex-nowrap '>
                                            {
                                                Graphitems?.map(el => {
                                                    return (
                                                        <div data-aos="fade-up" className='d-flex justify-content-center align-items-center'>
                                                            <div className='seq-Percept-graph-extra' style={{ gap: '10px' }}>
                                                                <div className='d-flex justify-content-center align-items-center'>
                                                                    <img src={el.image} alt='' className='' style={{ width: '24px', height: '24px', marginBottom: '12px' }} loading="lazy" />
                                                                </div>
                                                                <h4>{el.title}</h4>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* section 4 TESTIMONIAL SLIDER */}
            <div className="seq-quotes">
                <div className='gredient'> </div>
                <TestimonialSlider
                    page={'product-compliance-manager'}
                />
            </div>
            {/* section 5 SCHEDULE DEMO FORM */}
            <div className='seq-forms'>
                <div className='container'>
                    <div className="insurance-partseven" data-aos="fade-up">
                        {window.innerWidth > 767 ? <h2 className='insurance-partseven-heading'>Enhance your organization's security posture with our compliance management SaaS.</h2> : null}
                        <ScheduleForm
                            bg={'/images/bg-img22.svg'}
                            mobilebg={'/images/light-umbrella.svg'}
                            page='percept-manager'
                            text={`Navigate complex regulatory environments with tailored risk management strategies, enhance your organization's security posture, and ensure compliance with industry standards with Percept Compliance Manager.`}
                        />
                    </div>
                </div>
            </div>
            {/* section 6 RELATED RESOURCES */}
            <div className='resources'>
                <div className='container ps-0'>
                    <div className='row'>
                        <h4>Related resources</h4>
                        <div className='col-md-12'>
                            <div className={window.innerWidth > 767 ? 'row' : 'row flex-nowrap overflow-auto ms-2'}>
                                <RelatedResources
                                    page='perceptcompliancemanager'
                                    type=''
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {window.innerWidth > 767 ? <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Visit the resource center</button></div> : <div className='d-flex align-items-center justify-content-center' style={{ background: '#040c30' }}><button className='seq-resource-btn' onClick={() => setActive('resources')}>Resource Center</button></div>}
        </div>
    )
}

export default PerceptManager
