import axios from "axios";
// let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYwODk3ZTE1ZWRjM2I0MDRkZDFkNjMwZiIsImVtYWlsIjoiam9obndvcmsxQGdtYWlsLmNvbSIsImNvbXBhbnlfaWQiOjIsImlhdCI6MTYxOTYyMzgyNH0.elko4XReeezEnnD8_IZp5xTITXM6hotttUh2_TqIg6g'
const AuthStr = "Bearer " + localStorage.getItem("adminAuthToken");
function getApiCall(url) {
    return axios
        .get(process.env.REACT_APP_API_KEY + url, {
            headers: { Authorization: AuthStr },
        })
        .then((data) => {
            return data;
        });
}

function postApiCall(url, body) {
    return axios
        .post(process.env.REACT_APP_API_KEY + url, body, {
            headers: { Authorization: AuthStr },
        })
        .then((data) => {
            return data;
        });
}

function putApiCall(url, body) {
    return axios
        .put(process.env.REACT_APP_API_KEY + url, body, {
            headers: { Authorization: AuthStr },
        })
        .then((data) => {
            return data;
        });
}

function deleteApiCall(url, body) {
    return axios.delete(process.env.REACT_APP_API_KEY + url, {
        headers: { Authorization: AuthStr },
        data: body
    }).then((data) => {
        return data;
    });
}

function previewCall(url, body) {
    return axios
        .post(url, body, {
            dataType: "jsonp",
            contentType: "application/json",
            //headers: { Authorization: AuthStr },
        })
        .then((data) => {
            return data;
        });
}

export { getApiCall, postApiCall, previewCall, putApiCall, deleteApiCall };
